import { useSettings } from "../../toolympus/api/useSettings";

export interface TagSettings {
  color?: string;
}

export interface CrmSettings {
  tags: Record<string, TagSettings>;
}

const DefaultSettings = {
  tags: {}
}

export const useCrmSettings = () => useSettings("/api/crm/settings", DefaultSettings);
