import React from 'react';
import styled from '@emotion/styled';
import { ContentEditorProps } from './types';
import { FormGrid } from '../../../toolympus/components/primitives';
import { PowerEditorBase } from '../../../toolympus/components/PowerDoc';
import { TextField, Typography } from '@mui/material';
import { MediaLibPicker, useMediaLibContext } from '../../../toolympus/components/medialib';

const OuterGrid = styled(FormGrid)`
  align-items: start;

  & .medialib-picker {
    aspect-ratio: 1;
  }
`;
OuterGrid.defaultProps = { columns: "1fr 200px" };

export const TitleEditor = (props: ContentEditorProps) => {
  const medialib = useMediaLibContext();

  return (
    <OuterGrid>
      <FormGrid columns="1fr" noMargin gap="dense">
        <TextField
          value={props.block.content?.title || ""}
          onChange={e => props.update({ content: { ...props.block.content, title: e.target.value }})}
          label="Title"
          autoFocus
          />

        <Typography variant="caption" color="textSecondary">Tagline</Typography>
        <PowerEditorBase
          content={props.block.content?.tagline}
          update={v => props.update({ content: { ...props.block.content, tagline: v }})}
          />
      </FormGrid>

      <MediaLibPicker
        selectedUrl={props.block.content?.photo_url}
        selectFile={mf => props.update({ content: { ...props.block.content, photo_url: mf ? medialib.getFilepath(mf) : null }})}
        closeOnSelect
        allowFileActions
        placeholder="Photo / Logo"
        />
    </OuterGrid>
  );
}
