import React, { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { PageHeaderWrapper, PageHeaderTitle } from './PageHarness';
import { LoadingIndicator, OccupyFreeSpace } from '../../toolympus/components/primitives';

const HeaderActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 1rem;
  padding-left: 1rem;

  font-size: initial;
  font-weight: initial;
`;

interface Props {
  title?: ReactNode;
  isLoading?: boolean;
  actions?: ReactNode;
  actionsStart?: ReactNode;
}

export const PageHeader = (props: PropsWithChildren<Props>) => {
  return (
    <PageHeaderWrapper className="page-header">
        <PageHeaderTitle style={{ display: "flex" }}>
          {props.title}
      
          <HeaderActionsWrapper>
            {props.actionsStart}
            <OccupyFreeSpace />
            {props.isLoading && <LoadingIndicator />}
            {props.actions}
          </HeaderActionsWrapper>
        </PageHeaderTitle>

      {props.children}
    </PageHeaderWrapper>
  );
}
