import React from 'react';
import { ScoreRoundData } from "../../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode";
import { Arbiter, Round } from "../../../toolympus/components/Contests/Grid/types";
import { Tooltip } from "../../../toolympus/components/primitives";
import { RolesKeys } from "../../Auth";
import { RoundInfo } from "../useRoundInfoEdit";
import { StatusChip } from "./ArbitratorDisplay";
import { RoundScoringStatusData, StageScoringStatusData } from "./useScoringStatus";


export const RoundStatus = {
  "checkins": { status: "warning", label: "Checking in", description: "Awaiting participants to check in" },
  "in_progress": { status: "warning", label: "In progress", description: "Round is in progress" },
  
  "team_in_progress": { status: "good", label: "In progress", description: "Round is in progress" },
  "arbitrator_scoring": { status: "warning", label: "In progress", description: "Round is in progress" },
  "scoring": { status: "warning", label: "Scoring", description: "Judges are setting scores" },
  
  "finished": { status: "good", label: "Finished", description: "Round is finished" },
}

export type RoundStatusT = keyof typeof RoundStatus;


interface ParticipantRoundStatusProps {
  roundInfo: RoundInfo;
  arbitrators: (Arbiter | null)[];
  role: keyof typeof RolesKeys;
  everyoneCheckedIn: boolean;
  scoringStatus: RoundScoringStatusData | undefined;
  scoreRound: ScoreRoundData | undefined;
}

export const getParticipantRoundStatus = (p: ParticipantRoundStatusProps): RoundStatusT  => {
  const { roundInfo, arbitrators, role, everyoneCheckedIn, scoringStatus, scoreRound } = p;

  if (roundInfo.is_finished) {
    return "finished";
  }

  if(!everyoneCheckedIn) {
    return "checkins";
  }

  if(role === "team") {
    return "team_in_progress";
  }

  if(role === "arbitrator") {
    if (scoreRound && scoreRound.scoresGrouped.every(sg => sg.left?.is_scored && sg.right?.is_scored)) {
      return "finished";
    } else {
      return "arbitrator_scoring";
    }
  }

  if (scoringStatus) {
    const scoringStatuses = arbitrators.filter(a => !!a).map(a => scoringStatus?.getStatus(a?._id || ""));
    if (scoringStatuses.every(s => s?.is_fully_scored)) {
      return "finished";
    } else if (scoringStatuses.some(s => (s?.scored_cnt || 0) > 0)) {
      return "scoring";
    }
  }

  return "in_progress";
}


interface AdminRoundStatusProps {
  round: Round;
  roundInfo: RoundInfo | undefined;
  scoringStatus: StageScoringStatusData | undefined;
}

export const getAdminRoundStatus = (p: AdminRoundStatusProps): RoundStatusT  => {
  const { round, roundInfo, scoringStatus } = p;

  
  const arbitrators = [1,2,3].map(position => round?.arbiters ? (round.arbiters[position] || null) : null);
  const arbsCheckedIn = arbitrators.every(a => (roundInfo?.checkins || {})[a?._id || ""]);
  const teamsCheckedIn = Object.values(roundInfo?.teams_timing || {}).filter(x => !!x).length === 2;

  if (roundInfo?.is_finished) {
    return "finished";
  }

  if(!teamsCheckedIn || !arbsCheckedIn) {
    return "checkins";
  }

  if (scoringStatus) {
    const scoringStatuses = arbitrators.filter(a => !!a).map(a => scoringStatus?.getStatus(round._id, a?._id || ""));
    if (scoringStatuses.every(s => s?.is_fully_scored)) {
      return "finished";
    } else if (scoringStatuses.some(s => (s?.scored_cnt || 0) > 0)) {
      return "scoring";
    }
  }

  return "in_progress";
}


export const RoundStatusChip = (props: { status: RoundStatusT }) => {
  const statusInfo = RoundStatus[props.status];
  return <Tooltip text={statusInfo.description}><StatusChip status={statusInfo.status as any} label={statusInfo.label} /></Tooltip>
}
