export interface Person {
  _id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  whatsapp_available?: boolean;
  telegram_available?: boolean;
  avatar_url?: string;
}

export interface UniversityContact {
  _id?: string;
  full_name?: string;
  office?: string;
  email?: string;
  phone?: string;
  comment?: string;
}

export const emptyPerson = (): Person => ({
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  whatsapp_available: false,
  telegram_available: false,
});

export interface Team {
  _id: string;
  email: string;
  teamnbr: string;
  contact: Person;
  pd_consent?: boolean;
  is_fee_paid?: string;

  university?: string;
  university_address?: string;
  university_position?: string;
  university_support_ru?: boolean;
  university_support_intl?: boolean;
  university_contacts?: UniversityContact[];

  members?: Person[];
  advisors?: Person[];
  
  assistance?: any;

  status_info?: Record<string, any>;
  is_registration_complete?: boolean;
  is_email_confirmed?: boolean;
  is_excluded?: boolean;

  memorial_1_id?: string;
  memorial_2_id?: string;
}

export interface TeamRegistration extends Pick<Team, "contact" | "teamnbr" | "pd_consent"> {
  password: string;
  passwordRepeat?: string;
}