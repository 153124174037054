import React from 'react';
import { ContentEditorProps, LegacyBlockTypes } from './Content/types';
import { CommentEditor } from './Content/CommentEditor';
import { PeopleEditor } from './Content/PeopleEditor';
import { DocumentsEditor } from './Content/DocumentsEditor';
import { TitleEditor } from './Content/TitleEditor';

export const LegacyInfoContentEditor = (props: ContentEditorProps) => {
  switch(props.block.block_type) {
    case LegacyBlockTypes.title:
      return <TitleEditor {...props} />
    case LegacyBlockTypes.comment:
      return <CommentEditor {...props} />
    case LegacyBlockTypes.members:
    case LegacyBlockTypes.advisors:
      return <PeopleEditor {...props} />
    case LegacyBlockTypes.documents:
      return <DocumentsEditor {...props} />
    default:
      return <></>;
  }
}
