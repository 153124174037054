import { useState } from 'react';
import { useLoadedData } from '../../../../toolympus/hooks/useLoadedData';


export const useCourtRoomLobby = (stage: string | undefined, roundId: string | undefined) => {
  const apiPath = `/api/rounds-info/participant/${stage}/${roundId}/court-room`;
  const [isStarted, setIsStarted] = useState<"round" | "deliberations" | null>(null);
  const state = useLoadedData<{ is_available: boolean, domain: string }>(`${apiPath}`, { is_available: false, domain: "" }, !!stage && !!roundId);

  return {
    ...state,
    isStarted,
    join: (kind?: "round" | "deliberations") => state.data.is_available ? setIsStarted(kind || "round") : null,
    leave: () => setIsStarted(null),
  }
}


export interface CourtRoomTokens {
  token_round?: string;
  token_deliberations?: string;
  room_name_round?: string;
  room_display_name_round?: string;
  room_name_deliberations?: string;
  room_display_name_deliberations?: string;
}


export const useCourtRoom = (stage: string | undefined, roundId: string | undefined, dontJoin?: boolean) => {
  const apiPath = `/api/rounds-info/participant/${stage}/${roundId}/court-room`;
  const data = useLoadedData<CourtRoomTokens>(`${apiPath}/tokens`, {}, !dontJoin && !!stage && !!roundId);

  const [isClosed, setIsClosed] = useState<boolean>(false);


  return {
    ...data,

    isClosed,
    close: () => setIsClosed(true),
    rejoin: () => setIsClosed(false),
  }
}

export type CourtRoomJoinData = ReturnType<typeof useCourtRoom>;
