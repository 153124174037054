import React from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Tint } from './ImageControl';
import { Tooltip } from '../primitives';
import { DeleteOutlined } from '@mui/icons-material';

export const ImageWrapper = styled.div`
    position: relative;
    padding: 0;
    box-shadow: 0 0 5px 0 #00000020;
    flex: 1;


    & > .img-placeholder {
        z-index: 0;
    }

    & > img {
        width: 100%;
        height: 100%;
        z-index: +100;
        object-fit: cover;
    }

    & > .img-tint {
        z-index: +200;
        display: none;
    }
    &:hover > .img-tint {
        display: flex;
    }

`;

interface Props {
  src?: string;
  remove?: () => void;
}

export const ImageDisplay = (props: Props) => {
  const { src, remove } = props;
  return (
    <ImageWrapper className="img-wrapper">
      {src && <img src={src} alt="" />}
      <Tint className="img-tint">
          {src && remove && (
              <Tooltip text_id="images.delete">
                  <IconButton style={{ color: "#ffffff" }} onClick={e => { e.stopPropagation(); remove(); }}>
                      <DeleteOutlined />
                  </IconButton>
              </Tooltip>
          )}
      </Tint>
  </ImageWrapper>
  );
}
