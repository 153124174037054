import React from 'react';
import { IconButton } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { Form, LoadingIndicator, OccupyFreeSpace, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { useSiteSettingsEdit } from './useSiteSettingsEdit';
import { LanguageSwitch } from '../Localization';
import { BasicMenuEditor } from '../../toolympus/components/primitives/BasicMenuEditor';

export const SiteSettingsPage = () => {
    const data = useSiteSettingsEdit();

    useSaveable(data);

    const tabs = useTabsState([
        ["menu", "Main menu"],
    ], "menu", "tab");

    return (
        <Form title="Site Settings"
            headerItems={<>
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator />}
                <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
                {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
            </>}>
            <TabsHeader state={tabs} noMargin />

            <TabPanel state={tabs.forTab("menu")}>
              <BasicMenuEditor
                menu={(data.item?.menu || [])}
                update={v => data.update(data.item, { menu: v })}
                />
            </TabPanel>
        </Form>
    );
}
