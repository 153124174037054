import React from 'react';
import { PagePanel } from '../../Common/PageHarness';
import { PageHeader } from '../../Common/PageHeader';
import { FieldSettingsPopupButton, TableForFields, useFields } from '../../../toolympus/components/schemed';
import { ActionRow, Buttons, Link, PseudoLink2, SearchField } from '../../../toolympus/components/primitives';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';
import { CallMadeOutlined, LocalOfferOutlined } from '@mui/icons-material';
import { TabButtons } from '../../Common/TabButtons';
import { useArbitratorsList } from './useArbitratorsList';
import { ArbitratorReviewDialog } from './ArbitratorReviewDialog';
import { RankStars } from './RankStars';
import { Button, Checkbox, Typography } from '@mui/material';
import { RecordTagsEditPopup } from '../../../toolympus/components/Tags/RecordTagsEditPopup';

export const AdminArbitratorsList = () => {
  const data = useArbitratorsList();
  
  const fields = useFields({
    schema: data.schema,
    defaultFields: [
      "title",
      "first_name",
      "last_name",
      "email",
      "rank",

      "phone",
      "organization",
      "job_title",
      "timezone",

      "judging_format",
      "rounds_number_accepted",
      "short_notice_availability",
      "advanced_rounds_only_preferred",
                
      "created_at",
    ],
    extraSettings: {
      registration_complete_at: { utcToLocal: true },
      created_at: { utcToLocal: true },
      tags: { label: "Conflict tags" },
      rank: { align: "center" }, 
      is_excluded: { label: "Unapproved "},
      selected: { label: "Select" },
    },
    storageKey: "__j_arbitrators_list_fields",
    whitelist: [
      "selected",
      "_id",
      "title",
      "first_name",
      "last_name",
      "email",
      "rank",
      "pd_consent",

      "email_backup",
      "phone",
      "job_title",
      "organization",
      "timezone",
      "degrees",

      "judging_format",
      "rounds_number_accepted",
      "short_notice_availability",
      "advanced_rounds_only_preferred",
        
      "timeslots_preference",
      "timeslots_comment",
      "advanced_rounds_timeslots_preference",
      "advanced_rounds_timeslots_comment",
      "advanced_rounds_short_notice_availability",
      "advanced_rounds_offline_availability",

      "participation_jessup_national",
      "participation_jessup_international",
      "participation_other",
      "conflict_comment",
        
      "is_registration_complete",
      "registration_complete_at",
      "confirmation_code",
      "is_email_confirmed",

      "admin_notes",
      "is_excluded",
      "created_at",
      "tags",
    ],

    outOfSchemaFields: [
      "tags",
      "selected",
    ]
  });


  return (
    <>
      <PageHeader
        title="Judges"
        isLoading={data.isLoading}
        actions={<>
          <Typography variant="caption">{data.data.length} judge{data.data.length === 1 ? "" : "s"}</Typography>
          <SearchField
            autoFocus
            small
            noButton
            queryParam="filter"
            syncQuery
            {...data.filter}
            />
          <ActionTriggerEditButton
            apiPath="/api/actions/trigger"
            triggers={[
              "jessup.core.arbitrators.arbitrators-entities/arbitrator-signed-up",
              "jessup.core.arbitrators.arbitrators-entities/arbitrator-resend-email-confirmation-request",
              "jessup.core.arbitrators.arbitrators-entities/arbitrator-registration-complete",
            ]}
            />
          <FieldSettingsPopupButton fieldsSettings={fields} />
        </>}
      >
        <TabButtons
          currentTab={data.tabs.current}
          selectTab={data.tabs.setCurrent}
          tabs={data.tabs.tabs}
          />
        {!!data.selection.selectedItems.length && 
          <ActionRow>
            <Button color="primary" startIcon={<LocalOfferOutlined />} onClick={() => data.massAssignTags.state.open()}>assign tags</Button>
          </ActionRow>}
      </PageHeader>

      <PagePanel fillPage noTableStyle noPad fixMaxHeight>
        <TableForFields
          fields={fields.activeFields}
          sorting={data.sorting}
          data={data.data}
          schema={data.schema}
          fieldHeader={f => {
            switch(f) {
              case "selected":
                return <Checkbox size="small" checked={data.selection.isAllSelected} onChange={e => data.selection.selectAll(e.target.checked)} />
            }
          }}
          fieldElement={f => {
            switch(f) {
              case "selected":
                return (row) => <Checkbox size="small" checked={data.selection.isSelected(row)} onChange={e => data.selection.select(row, e.target.checked)} />
              case "email":
                return (row,s,orig) => (
                  <Buttons>
                    <PseudoLink2 border onClick={() => data.review.startEditing(row)}>{orig}</PseudoLink2>
                    <Link style={{ border: "none" }} to={`/system/users?filter=${row.email}`}><CallMadeOutlined fontSize="small" /></Link>
                  </Buttons>);

              case "degrees":
              case "timeslots_preference":
              case "advanced_rounds_timeslots_preference":
              case "conflict_comment":
              case "participation_jessup_national":
              case "participation_jessup_international":
              case "participation_other":
                return (row,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing(row)}>{(row[f] || []).length}</PseudoLink2>;
              case "tags":
                return (row,s,orig) => <>{data.conflicts.displayForRecord(row._id)}</>;
              case "rank":
                return (row,s,orig) => <RankStars value={row.rank} update={r => data.updateRank(row._id, r)} />
            }
          }}
          />
      </PagePanel>

      <ArbitratorReviewDialog data={data} />

      <RecordTagsEditPopup
        {...data.massAssignTags.state}
        {...data.massAssignTags.tags}
        submit={() => data.massAssignTags.run()}
        isSubmitDisabled={!data.massAssignTags.canRun}
        isSubmitting={data.massAssignTags.isRunning}
        />

      {data.conflicts.popup}
    </>
  );
}
