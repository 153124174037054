import React, { useMemo } from 'react';
import { FrontPageFormTitle, FrontPagePrimaryButton, FrontPageFormWrapperForm, FormGradientDecoration, FrontPageFormExtraLink, FrontPageFormHelpText } from './FrontPages';
import { Buttons, FormGrid } from '../../toolympus/components/primitives';
import { TextField } from '@mui/material';
import { Checkbox } from '../../toolympus/components/schemed/Checkbox';
import { useEditItem2 } from '../../toolympus/api/useNewItem';
import { Person, TeamRegistration, emptyPerson } from '../Teams/types';
import { apiFetch } from '../../toolympus/api/core';
import { useHistory } from 'react-router-dom';
import { login, useUser } from '../../toolympus/userContext/UserContext';
import { Spinner } from '../Common/Spinner';
import { FrontPageHarness } from './FrontPageHarness';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { getCustomBlocksData, processBasicExtraLinks, processExtraLinks } from './Helpers';
import { Telegram, WhatsApp } from '@mui/icons-material';
import { RadioCheckbox } from '../Common/RadioCheckbox';
import { EmptyPlatformPageData, PlatformPageData } from '../Common/data/usePlatformPage';

const usePageConfig = () => {
  const pageInfo = useLoadedData<PlatformPageData>("/api/public/cms/platform_page/team-sign-up", EmptyPlatformPageData);
  const config = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);
    const formConfigFields = pageData["team-sign-up-form"]|| {};
    const paneFields = pageData["pane-config"]|| {};

    return {
      formConfig: {
        links: processExtraLinks(formConfigFields.extra_links),
        guide: formConfigFields.guide,
        blocked_message: formConfigFields.blocked_message,
        blocked_button: processBasicExtraLinks(formConfigFields.blocked_button).slice(0,1),
        is_blocked: !(pageInfo.data as any).contest_state?.team_registration_allowed,
      },
      paneConfig: { links: processBasicExtraLinks(paneFields.extra_links) },
    };
  }, [pageInfo.data]);

  return { config, pageInfo, isLoading: pageInfo.isLoading };
}

const useTeamSignup = () => {
  const user = useUser();
  const history = useHistory();

  const pageConfig = usePageConfig();
  

  const data = useEditItem2<TeamRegistration>({
    getApiPath: () => "",
    save: (team) => {
      const signupTeam = { ...team };
      delete signupTeam.passwordRepeat;
      return apiFetch<{ token: string }>("/api/team/signup", "POST", signupTeam)
        .then(withToken => {
          login(user, withToken.token);
          history.push("/team/profile");
          return team;
        })
    },
    startWith: {
      contact: emptyPerson(),
      password: "",
      passwordRepeat: "",
      teamnbr: "",
    },
    dontResetOnSave: true,
  });

  const updateContact = (changes: Partial<Person>) => data.update({ contact: { ...(data.item?.contact || {}), ...changes } as Person });

  const passwordsMatch = (!data.item?.password && !data.item?.passwordRepeat) || data.item?.password === data.item?.passwordRepeat;

  const canSubmit = !!data.item?.password && passwordsMatch;

  const blockerMessage = useMemo(() => {
    if(!data.item?.password || !data.item?.passwordRepeat || !data.item.pd_consent
      || !data.item?.contact?.email || !data.item?.contact?.phone || !data.item?.contact?.first_name || !data.item?.contact?.last_name) {
        return "please fill out the form to proceed";
    } else if(data.item?.password !== data.item?.passwordRepeat) {
      return "passwords don't match";
    } else {
      return null;
    }
  }, [data.item])

  return {
    ...data,
    isLoading: data.isLoading || pageConfig.isLoading,
    updateContact,
    passwordsMatch,
    canSubmit,
    blockerMessage,
    config: pageConfig.config,
    pageInfo: pageConfig.pageInfo,
  }
}


export const TeamSignupPage = () => {
  const { item: data, save: submit, canSubmit, errors, update, updateContact, isLoading, config, blockerMessage, pageInfo } = useTeamSignup();
  const { formConfig, paneConfig } = config;

  return (
    <FrontPageHarness menuLinks={paneConfig.links} wideContent>

      <FrontPageFormWrapperForm
        dim={isLoading}
        onSubmit={e => {
          e.preventDefault();
          if(canSubmit) {
            submit();
          }
        }}>

        <FormGradientDecoration top={-25} left={-37} />
        <FormGradientDecoration top={82} left={67} />

        {isLoading && <Spinner size={60} />}

        <FrontPageFormTitle><span>Team Registration</span></FrontPageFormTitle>

        {formConfig.is_blocked && !isLoading && <>
          <FrontPageFormHelpText>{formConfig.blocked_message}</FrontPageFormHelpText>
          {formConfig.blocked_button.map(([text,link]) => (
            <FrontPagePrimaryButton key={link} href={link}>
              {text}
            </FrontPagePrimaryButton>
          ))}
        </>}

        {(!formConfig.is_blocked || isLoading) && <>

          {formConfig.guide && <FrontPageFormHelpText>{formConfig.guide}</FrontPageFormHelpText>}

          <FormGrid gap="dense" columns="1fr 1fr" noMargin style={{ alignItems: "end" }} suppressContentColumnsOnSm>
            <TextField
              label="First name"
              value={data?.contact?.first_name || ""}
              onChange={e => updateContact({ first_name: e.target.value })}
              error={errors.getInnerErrors(["contact"]).fieldHasErrors("first_name")}
              required
              />
            
            <TextField
              label="Last name"
              value={data?.contact?.last_name || ""}
              onChange={e => updateContact({ last_name: e.target.value })}
              error={errors.getInnerErrors(["contact"]).fieldHasErrors("last_name")}
              required
              />

            <TextField
              label="Phone"
              value={data?.contact?.phone || ""}
              onChange={e => updateContact({ phone: e.target.value })}
              error={errors.getInnerErrors(["contact"]).fieldHasErrors("phone")}
              required
              />

            <Buttons>
              <Checkbox
                field="telegram_available"
                row={data?.contact}
                onChange={(o,c) => updateContact(c)}
                schema={{ label: <span style={{ whiteSpace: "nowrap" }}>Telegram <Telegram fontSize="small" color={data?.contact.telegram_available ? "primary" : "disabled"} /></span>}}
                extraProps={{ controlProps: { color: "primary", margin: "dense" } }}
                />
              <Checkbox
                field="whatsapp_available"
                row={data?.contact}
                onChange={(o,c) => updateContact(c)}
                schema={{ label: <span style={{ whiteSpace: "nowrap" }}>WhatsApp <WhatsApp fontSize="small" color={data?.contact.whatsapp_available ? "primary" : "disabled"} /></span>}}
                extraProps={{ controlProps: { color: "primary"} }}
                />
            </Buttons>


            <TextField
              label="Team number"
              style={{ gridColumn: "1 / span 2" }}
              helperText="you will have received that after registering your team at www.ilsa.org"
              value={data?.teamnbr || ""}
              onChange={e => update({ teamnbr: e.target.value })}
              error={errors.fieldHasErrors("teamnbr")}
              />
          </FormGrid>


          <FormGrid columns="1fr 1fr 1fr" forceEvenColumns gap="dense">
            <TextField
              label="Email"
              type="email"
              required
              value={data?.contact?.email || ""}
              onChange={e => updateContact({ email: e.target.value })}
              error={errors.getInnerErrors(["contact"]).fieldHasErrors("email")}
              />

            <TextField
              label="Password"
              type="password"
              required
              value={data?.password || ""}
              onChange={e => update({ password: e.target.value })}
              error={errors.fieldHasErrors("password")}
              />

            <TextField
              label="Repeat password"
              type="password"
              helperText="at least 6 characters"
              required
              value={data?.passwordRepeat || ""}
              onChange={e => update({ passwordRepeat: e.target.value })}
              error={errors.fieldHasErrors("password")}
              />

          </FormGrid>

          <FormGrid columns="1fr" noMargin>
            <RadioCheckbox
              label={pageInfo.data?.messages?.teams?.registration?.pdc}
              value={data?.pd_consent}
              update={v => update({ pd_consent: v })}
              trueOnly
              error={errors.fieldHasErrors("pd_consent")}
              />


            <FrontPagePrimaryButton type="submit" disabled={!!blockerMessage}>
              {blockerMessage ? blockerMessage : "sign up"}
            </FrontPagePrimaryButton>

            {formConfig.links.map((l,idx) => <FrontPageFormExtraLink key={idx}>{l}</FrontPageFormExtraLink>)}
          </FormGrid>
        </>}
      </FrontPageFormWrapperForm>
      
    </FrontPageHarness>
  );
}
