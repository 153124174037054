import React from 'react';
import styled from '@emotion/styled';
import { ArbitratorProfileData } from '../useArbitratorProfile';
import { ListFormItem, ProfileSectionTitle } from '../../Common/styles';
import { Button, Typography } from '@mui/material';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Buttons, InputIconButton, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { Add, DeleteOutlined } from '@mui/icons-material';

interface Props {
  data: ArbitratorProfileData;
  canEditProfile?: boolean;  
}

const ParticipationItem = styled(ListFormItem)`
`;
ParticipationItem.defaultProps = { columns: "100px 1fr 1fr", gap: "dense" };

export const ArbitratorExperienceForm = (props: Props) => {
  return (
    <>
      <ProfileSectionTitle>Participation in the Jessup</ProfileSectionTitle>
      <Typography>National Rounds:</Typography>

      {(props.data.participationJessupNational || []).items.map((item,idx) => (
        <ParticipationItem key={item._id}>
          <FormControlsForFields
            data={item}
            onChange={(o,c) => props.data.participationJessupNational.update(idx, c)}
            schema={{}}
            readOnly={!props.canEditProfile}
            errors={props.data.errors?.getInnerErrors(["participation_jessup_national", idx])}
            fields={[
              ["year", { label: "Year", controlProps: { required: true }, }],
              ["country", {
                label: "Country",
                controlProps: {
                  required: true,
                },
              }],
              ["role", {
                label: "Role (Team Member, Advisor, Judge)",
                controlProps: {
                  required: true,
                  endAdornment: <InputIconButton icon={<DeleteOutlined />} action={props.canEditProfile ? () => props.data.participationJessupNational.remove(idx) : undefined} />,
                },
              }],
            ]}
            />          
        </ParticipationItem>
      ))}

      {props.canEditProfile && 
        <Buttons>
          {props.data.participationJessupNational.items.length > 0 && <OccupyFreeSpace />}
          <Button color="primary" onClick={() => props.data.participationJessupNational.add()} startIcon={<Add />}>add</Button>
        </Buttons>}
      
      <Typography>International Rounds:</Typography>

      {(props.data.participationJessupInternational || []).items.map((item,idx) => (
        <ParticipationItem key={item._id}>
          <FormControlsForFields
            data={item}
            onChange={(o,c) => props.data.participationJessupInternational.update(idx, c)}
            schema={{}}
            readOnly={!props.canEditProfile}
            errors={props.data.errors?.getInnerErrors(["participation_jessup_international", idx])}
            fields={[
              ["year", { label: "Year", controlProps: { required: true }, }],
              ["role", {
                label: "Role (Team Member, Advisor, Judge)",
                controlProps: {
                  required: true,
                  endAdornment: <InputIconButton icon={<DeleteOutlined />} action={props.canEditProfile ? () => props.data.participationJessupInternational.remove(idx) : undefined} />,
                },
              }],
            ]}
            />          
        </ParticipationItem>
      ))}

      {props.canEditProfile && 
        <Buttons>
          {props.data.participationJessupInternational.items.length > 0 && <OccupyFreeSpace />}
          <Button color="primary" onClick={() => props.data.participationJessupInternational.add()} startIcon={<Add />}>add</Button>
        </Buttons>}


      <ProfileSectionTitle>Participation in other moot courts</ProfileSectionTitle>
      
      {(props.data.participationOther || []).items.map((item,idx) => (
        <ParticipationItem key={item._id}>
          <FormControlsForFields
            data={item}
            onChange={(o,c) => props.data.participationOther.update(idx, c)}
            schema={{}}
            readOnly={!props.canEditProfile}
            errors={props.data.errors?.getInnerErrors(["participation_other", idx])}
            fields={[
              ["year", { label: "Year", controlProps: { required: true }, }],
              ["competition", {
                label: "Moot court competition",
                controlProps: {
                  required: true,
                },
              }],
              ["role", {
                label: "Role (Team Member, Advisor, Judge)",
                controlProps: {
                  required: true,
                  endAdornment: <InputIconButton icon={<DeleteOutlined />} action={props.canEditProfile ? () => props.data.participationOther.remove(idx) : undefined} />,
                },
              }],
            ]}
            />          
        </ParticipationItem>
      ))}

      {props.canEditProfile && 
        <Buttons>
          {props.data.participationOther.items.length > 0 && <OccupyFreeSpace />}
          <Button color="primary" onClick={() => props.data.participationOther.add()} startIcon={<Add />}>add</Button>
        </Buttons>}
    </>
  );
}
