import React from 'react';
import { PagePanel } from '../../Common/PageHarness';
import { PageHeader } from '../../Common/PageHeader';
import { FieldSettingsPopupButton, TableForFields, useFields } from '../../../toolympus/components/schemed';
import { Buttons, Link, PseudoLink2, SearchField } from '../../../toolympus/components/primitives';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';
import { CallMadeOutlined } from '@mui/icons-material';
import { TabButtons } from '../../Common/TabButtons';
import { useBailiffsList } from './useBailiffsList';
import { RankStars } from '../../Arbitrators/AdminList/RankStars';
import { BailiffReviewDialog } from './BailiffReviewDialog';
import { Typography } from '@mui/material';

export const AdminBailiffsList = () => {
  const data = useBailiffsList();
  
  const fields = useFields({
    schema: data.schema,
    defaultFields: [
      "first_name",
      "last_name",
      "email",
      "phone",
      "rank",
      "is_approved",
    ],
    extraSettings: {
      created_at: { utcToLocal: true },
      rank: { align: "center" }, 
    },
    storageKey: "__j_bailiffs_list_fields",
    whitelist: [
      "_id",
      "first_name",
      "last_name",
      "phone",
      "email",
      "rank",
      "pd_consent",
      "is_approved",
      "admin_notes",
    ],
  });

  return (
    <>
      <PageHeader
        title="Bailiffs"
        isLoading={data.isLoading}
        actions={<>
          <Typography variant="caption">{data.data.length} bailiff{data.data.length === 1 ? "" : "s"}</Typography>
          <SearchField
            autoFocus
            small
            noButton
            queryParam="filter"
            syncQuery
            {...data.filter}
            />
          <ActionTriggerEditButton
            apiPath="/api/actions/trigger"
            triggers={[
              "jessup.core.bailiffs.bailiffs-entities/bailiff-signed-up",
              "jessup.core.bailiffs.bailiffs-entities/bailiff-resend-email-confirmation-request",
            ]}
            />
          <FieldSettingsPopupButton fieldsSettings={fields} />
        </>}
      >
        <TabButtons
          currentTab={data.tabs.current}
          selectTab={data.tabs.setCurrent}
          tabs={data.tabs.tabs}
          />
      </PageHeader>

      <PagePanel fillPage noTableStyle noPad fixMaxHeight>
        <TableForFields
          fields={fields.activeFields}
          sorting={data.sorting}
          data={data.data}
          schema={data.schema}
          fieldElement={f => {
            switch(f) {
              case "email":
                return (row,s,orig) => (
                  <Buttons>
                    <PseudoLink2 border onClick={() => data.review.startEditing(row)}>{orig}</PseudoLink2>
                    <Link style={{ border: "none" }} to={`/system/users?filter=${row.email}`}><CallMadeOutlined fontSize="small" /></Link>
                  </Buttons>);
              case "rank":
                return (row,s,orig) => <RankStars justify="center" value={row.rank} update={r => data.updateRank(row._id, r)} />
            }
          }}
          />
      </PagePanel>

      <BailiffReviewDialog data={data} />
    </>
  );
}
