import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { TextField, Typography, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { FormGrid, Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { Team } from '../types';
import { ValidationErrors } from '../../../toolympus/components/schemed';
import { TeamProfileData } from './useTeamProfile';
import { ThemeSettings } from '../../../theme';
import { RadioCheckbox } from '../../Common/RadioCheckbox';


const UniversityContactItem = styled(FormGrid)`
  padding-left: 20px;
  border-left: 2px solid ${ThemeSettings.colors.tableBorder};
  .comment {
    grid-column: 1 / span 2;
  }
`;
UniversityContactItem.defaultProps = { columns: "1fr 1fr", gap: "dense", suppressContentColumnsOnSm: true };



interface Props {
  data: Team;
  canEditProfile?: boolean;
  errors?: ValidationErrors;
  update: (c: Partial<Team>) => void;
  universityContacts: TeamProfileData["universityContacts"];

  contactsMessage?: ReactNode;
  universityPositionMessage?: ReactNode;
}

export const TeamUniversityForm = (props: Props) => {
  const profile = props;
  return (
    <>
      <FormGrid columns="1fr" noMargin>
        <TextField
          label="University"
          required
          value={profile.data.university || ""}
          InputProps={{ readOnly: !profile.canEditProfile }}
          onChange={e => profile.update({ university: e.target.value })}
          error={profile.errors?.fieldHasErrors("university")}
          />

        <TextField
          label="University address"
          required
          multiline
          value={profile.data.university_address || ""}
          InputProps={{ readOnly: !profile.canEditProfile }}
          onChange={e => profile.update({ university_address: e.target.value })}
          error={profile.errors?.fieldHasErrors("university_address")}
          />

      </FormGrid>

      <Typography variant="h4" style={{ marginTop: "1rem" }}>
        University contact(s)
      </Typography>

      <Typography>{profile.contactsMessage}</Typography>

      {profile.universityContacts.items.map((contact,idx) => (
        <UniversityContactItem key={contact._id}>
          <TextField
            label="Name"
            required
            value={contact.full_name || ""}
            InputProps={{ readOnly: !profile.canEditProfile }}
            onChange={e => profile.universityContacts.update(idx, { full_name: e.target.value })}
            error={profile.errors?.getInnerErrors(["university_contacts", idx]).fieldHasErrors("full_name")}
            />
          <TextField
            label="Office"
            value={contact.office || ""}
            InputProps={{ readOnly: !profile.canEditProfile }}
            onChange={e => profile.universityContacts.update(idx, { office: e.target.value })}
            error={profile.errors?.getInnerErrors(["university_contacts", idx]).fieldHasErrors("office")}
            />

          <TextField
            label="Email"
            type="email"
            value={contact.email || ""}
            InputProps={{ readOnly: !profile.canEditProfile }}
            onChange={e => profile.universityContacts.update(idx, { email: e.target.value })}
            error={profile.errors?.getInnerErrors(["university_contacts", idx]).fieldHasErrors("email")}
            />

          <TextField
            label="Phone"
            value={contact.phone || ""}
            InputProps={{ readOnly: !profile.canEditProfile }}
            onChange={e => profile.universityContacts.update(idx, { phone: e.target.value })}
            error={profile.errors?.getInnerErrors(["university_contacts", idx]).fieldHasErrors("phone")}
            />

          <TextField
            label="Comments"
            className="comment"
            multiline
            value={contact.comment || ""}
            InputProps={{ readOnly: !profile.canEditProfile }}
            onChange={e => profile.universityContacts.update(idx, { comment: e.target.value })}
            />
        </UniversityContactItem>
      ))}

      {profile.canEditProfile && 
        <Buttons>
          {profile.universityContacts.items.length > 0 && <OccupyFreeSpace />}
          <Button color="primary" onClick={() => profile.universityContacts.add()} startIcon={<Add />}>add</Button>
        </Buttons>}


      <Typography variant="h4" style={{ marginTop: "1rem" }}>
        University position
      </Typography>

      <FormGrid columns="1fr">

        <Typography>{profile.universityPositionMessage}</Typography>


        <RadioCheckbox
          label="Does your University support your participating in the Russian Rounds of the Jessup?"
          value={profile.data.university_support_ru}
          update={v => profile.update({ university_support_ru: v })}
          readOnly={!profile.canEditProfile}
          />

        <RadioCheckbox
          label="Is your university likely to support your participating in the International Rounds of the Jessup?"
          value={profile.data.university_support_intl}
          update={v => profile.update({ university_support_intl: v })}
          readOnly={!profile.canEditProfile}
          />

        <TextField
          label="Comments"
          helperText=""
          required
          multiline
          value={profile.data.university_position || ""}
          InputProps={{ readOnly: !profile.canEditProfile }}
          onChange={e => profile.update({ university_position: e.target.value })}
          error={profile.errors?.fieldHasErrors("university_position")}
          />
      </FormGrid> 
    </>
  );
}
