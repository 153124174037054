export const JessupMessages = {
  teams: { 
    error: {
      profile_edit_blocked: "Team profile updates are blocked",
    },
  },


  // OVERRIDES
  contests: {
    rounds: {
      config: {
        labels: {
          arbiter_access_url: "Judge link format",
        },

        stages: {
          launch_confirmation: "The stage will be launched, teams and judges will gain access to the rounds",
          assign_arbiters: "Assign judges",
          assigning_to_n_rounds: "Will assign judges to {n} round(s).",
          arbiters_per_round: "judges per round",
          max_assignments_per_arbiter: "Max assignments per judge",
          clear_arbiters: "Clear judges",
          clear_arbiters_confirmation: "All judge assignments in this stage will be removed. Continue?",

          validation: {
              arbiters_unassigned: "Judges are not assigned",
              arbiters_time_clash: "Judges are assigned to multiple rounds at the same time: {arbiters}. Rounds: {rounds}",
              arbiters_time: "Judges unavailable at that time: {arbiters}", 
          },
        },

        validation: {
          arbiter_not_found: "Judge not found",
          cant_assign_the_same_arbiter_to_a_round_twice: "Judge can't be assigned to the same round twice",

        }
      },

      round: {
        labels: {
            arbiters: "Judges",
            arbiter: "Judge {position}",
        },
      },

      arbiter: {
        select: "Select a judge",
      },
    }
  }
}