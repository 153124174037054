import React, { useCallback } from 'react';
import { AutoexpanderDefault, PseudoLink2, useWindowHotkey } from '../../toolympus/components/primitives';
import { FieldElementRenderer, FormControlsForFields, TableForFields } from '../../toolympus/components/schemed';
import { Button, IconButton } from '@mui/material';
import { PageHeader } from '../Common/PageHeader';
import { PagePanel } from '../Common/PageHarness';
import { LegacyInfo, useAdminLegacyInfoList } from './useAdminLegacyInfoList';
import { Add } from '@mui/icons-material';
import { EditLegacyInfoDialog } from './EditLegacyInfoDialog';
import { MediaLibProvider } from '../../toolympus/components/medialib';
import { LegacyTransferDialog } from './LegacyTransferDialog';

export const AdminLegacyInfoList = () => {
  const data = useAdminLegacyInfoList();

  useWindowHotkey("alt+n", () => data.create.startEditing({ ...data.filters.item }));

  const fieldElement = useCallback((f: string): FieldElementRenderer<LegacyInfo> => {
    switch(f) {
      case "year":
      case "university":
      case "block_type":
        return (row,s,orig) => <PseudoLink2 onClick={() => { data.edit.startEditing({...row}) }}>{orig}</PseudoLink2>
      case "content":
        return (row,s,orig) => {
          let text = "";
          switch(row.block_type) {
            case "members":
            case "advisors":
            case "documents":
              text = row.content?.items?.length || "0";
              break
            case "comment":
            case "title":
              text = "...";
              break;
            }
          return <PseudoLink2 onClick={() => { data.edit.startEditing({...row}) }}>{text}</PseudoLink2>
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    <MediaLibProvider apiPath="/api/media" spaceId="1" urlPrefix="/media">
      <PageHeader
        title="Legacy"
        isLoading={data.isLoading}
        actionsStart={<>
          <IconButton color='primary' size="small" onClick={() => data.create.startEditing({ ...data.filters.item })}><Add /></IconButton>
          <Button onClick={() => data.transfer.startEditing() }>transfer current year</Button>
        </>}
        actions={<>
          <FormControlsForFields
            data={data.filters.item}
            schema={data.filtersSchema}
            onChange={(o,c) => data.filters.update(c)}
            fields={[
              ["year", { autoComplete: true, wrapperStyle: { width: 100 }}],
              ["university", { autoComplete: true, wrapperStyle: { width: 300 }}],
            ]}
            />
        </>}
        >
      </PageHeader>

      <PagePanel fillPage noTableStyle noPad fixMaxHeight>
        <TableForFields
          data={data.data}
          fields={[
            ["year"],
            ["university"],
            ["block_type"],
            ["content"],
          ]}
          schema={data.schema}
          autoexpander={AutoexpanderDefault}
          fieldElement={fieldElement}
          />
      </PagePanel>

      <EditLegacyInfoDialog
        data={data.create}
        mode="create"
        schema={data.schema}
        title="Add item"
        />

      <EditLegacyInfoDialog
        data={data.edit}
        mode="edit"
        schema={data.schema}
        title="Edit item"
        remove={data.remove}
        />

      <LegacyTransferDialog
        data={data.transfer}
        />

    </MediaLibProvider>
  </>);
}


