import React from 'react';
import { LegacyContentProps } from './types';
import { CommentDisplay } from './CommentDisplay';
import { LegacyBlockTypes } from '../../../LegacyInfo';
import { PeopleDisplay } from './PeopleDisplay';
import { DocumentsDisplay } from './DocumentsDisplay';
import { FormGrid } from '../../../../toolympus/components/primitives';
import { TitleDisplay } from './TitleDisplay';

interface Props extends LegacyContentProps {
  messages: Record<string,string>;
}

export const LegacyContentDisplay = (props: Props) => {
  switch(props.block.block_type) {
    case LegacyBlockTypes.title:
      return <TitleDisplay {...props} />
    case LegacyBlockTypes.comment:
      return <CommentDisplay {...props} />
    case LegacyBlockTypes.members:
      return <PeopleDisplay {...props} title={props.messages.members_title} />
    case LegacyBlockTypes.advisors:
      return <PeopleDisplay {...props} title={props.messages.advisors_title} />
    case LegacyBlockTypes.membersadvisors:
      return (
        <FormGrid columns="1fr 1fr" forceEvenColumns>
          <div>
            <PeopleDisplay block={props.block.content.members} title={props.messages.members_title} />
          </div>
          <div>
            <PeopleDisplay block={props.block.content.advisors} title={props.messages.advisors_title} />
          </div>
        </FormGrid>
      )
    case LegacyBlockTypes.documents:
      return <DocumentsDisplay {...props} title={props.messages.documents_title} />
    default:
      return <></>;
  }
}
