import React from 'react';
import { useTeamProfile } from './useTeamProfile';
import { FloatingButton, PageHeaderMessage, PagePanel, PagePanelMessage } from '../../Common/PageHarness';
import { Buttons, FormGrid, LoadingIndicator, OccupyFreeSpace, TabPanel, useTabsState } from '../../../toolympus/components/primitives';
import { Button, TextField, Typography } from '@mui/material';
import { Add, Check, ErrorOutline, Refresh, Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { TabButtons } from '../../Common/TabButtons';
import { PageViewer } from '../../Common/PageViewer';
import { PersonEditor } from './PersonEditor';
import { PageHeader } from '../../Common/PageHeader';
import { TeamPrimaryContactForm } from './TeamPrimaryContactForm';
import { TeamUniversityForm } from './TeamUniversityForm';
import { TeamAssitanceForm } from './TeamAssitanceForm';
import { RadioCheckbox } from '../../Common/RadioCheckbox';



interface Props {
  
}

export const TeamProfilePage = (props: Props) => {
  const profile = useTeamProfile();

  const tabs = useTabsState(profile.pageConfig.config.tabs, "contact", "tab", "_j_team_profile_tab");

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      {profile.hasChanges &&
        <FloatingButton
          color="primary"
          variant="contained"
          startIcon={profile.isLoading ? <LoadingIndicator color="inherit" sizeVariant="s" /> : <Save />}
          onClick={() => profile.save()}
          >
          <FormattedMessage id="common.save" />
        </FloatingButton>
        }

      <PageHeader title={"Team profile"} isLoading={profile.isLoading || profile.pageConfig.isLoading}>
        {profile.pageConfig.config.messages?.header_message &&
          <PageHeaderMessage>{profile.pageConfig.config.messages?.header_message}</PageHeaderMessage>}

        <TabButtons
          tabs={tabs.tabs}
          currentTab={tabs.current}
          selectTab={tabs.setCurrent}
          tabStatuses={profile.profileStatus}
          />
      </PageHeader>
      
      {profile.isProfileLoaded && !profile.data.is_email_confirmed &&
        <PagePanelMessage actions={(
          <Button
            startIcon={<Refresh />}
            color="primary"
            endIcon={profile.resendConfirmationEmail.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
            onClick={() => profile.resendConfirmationEmail.run().then(() => enqueueSnackbar("Email sent", { variant: "success", autoHideDuration: 5000 }))}>
            Re-send confirmation email
          </Button>)}>

          <Typography variant="h4">{profile.pageConfig.config.messages?.confirm_email_title}</Typography>
          <Typography>{profile.pageConfig.config.messages?.confirm_email_message}</Typography>
        </PagePanelMessage>}


      {profile.isProfileLoaded && !profile.data.is_registration_complete &&
        <PagePanelMessage animateAppearence invert actions={<ErrorOutline fontSize="large" />}>
          <Typography variant="h4">{profile.pageConfig.config.messages?.registration_incomplete_title}</Typography>
          <Typography>{profile.pageConfig.config.messages?.registration_incomplete_message}</Typography>
        </PagePanelMessage>}

      {profile.isProfileLoaded && profile.pageConfig.state.team_profile_edit_allowed && profile.data.is_registration_complete &&
        <PagePanelMessage animateAppearence actions={<Check color="primary" fontSize="large" />}>
          <Typography variant="h4">{profile.pageConfig.config.messages?.registration_complete_title}</Typography>
          <Typography>{profile.pageConfig.config.messages?.registration_complete_message}</Typography>
        </PagePanelMessage>}

      <PagePanel fillPage={`${profile.isProfileLoaded}_${profile.data.is_registration_complete}`}>
        <TabPanel state={tabs.forTab("contact")}>

          <FormGrid noMargin>
            <TextField
              label="Team number"
              required
              value={profile.data.teamnbr}
              helperText="you will have received that after registering your team at www.ilsa.org"
              onChange={(e) => profile.update({ teamnbr: e.target.value })}
              InputProps={{ readOnly: !profile.canEditProfile }}
              error={profile.errors?.fieldHasErrors("teamnbr")}
              />

            <div/>
            <div />

            {/* <RadioOptions
              label="Our team has paid the Jessup registration fee"
              value={profile.data.is_fee_paid}
              options={[
                ["yes", "Yes"],
                ["no", "No"],
              ]}
              update={v => profile.update({ is_fee_paid: v })}
              readOnly={!profile.canEditProfile}
              /> */}
          </FormGrid>


          <TeamPrimaryContactForm
            data={profile.data}
            updatePrimaryContact={c => profile.updatePrimaryContact(c)}
            canEditProfile={profile.canEditProfile}
            errors={profile.errors?.getInnerErrors(["contact"])}
            />


          
        </TabPanel>

        <TabPanel state={tabs.forTab("university")}>
          <TeamUniversityForm
            data={profile.data}
            universityContacts={profile.universityContacts}
            update={profile.update}
            errors={profile.errors}
            canEditProfile={profile.canEditProfile}
            contactsMessage={profile.pageConfig.config.messages?.university_contacts_message}
            universityPositionMessage={profile.pageConfig.config.messages?.university_position_message}
            />

        </TabPanel>




        <TabPanel state={tabs.forTab("roster")}>
          <RadioCheckbox
            label={profile.pageConfig.pageInfo.data?.messages?.teams?.registration?.pdc}
            value={profile.data.pd_consent}
            update={v => {}}
            trueOnly
            />

          <Typography variant="h4">
            Team roster
          </Typography>

          <Typography>{profile.pageConfig.config.messages?.members_message}</Typography>


          {profile.members.items.map((member,idx) => (
            <PersonEditor
              key={member._id}
              item={member}
              update={c => profile.members.update(idx, c)}
              readOnly={!profile.canEditProfile}
              remove={() => profile.members.remove(idx)}
              errors={profile.errors?.getInnerErrors(["members", idx])}
              avatars={profile.avatars}
              />
          ))}

          {profile.canEditProfile && 
            <Buttons style={{ marginTop: "0.5rem"}}>
              {profile.members.items.length > 0 && <OccupyFreeSpace />}
              <Button color="primary" onClick={() => profile.members.add()} startIcon={<Add />}>add a member</Button>
            </Buttons>}


          <Typography variant="h4">
            Advisors
          </Typography>

          <Typography>{profile.pageConfig.config.messages?.advisors_message}</Typography>

          {profile.advisors.items.map((advisor,idx) => (
            <PersonEditor
              key={advisor._id}
              item={advisor}
              update={c => profile.advisors.update(idx, c)}
              readOnly={!profile.canEditProfile}
              remove={() => profile.advisors.remove(idx)}
              errors={profile.errors?.getInnerErrors(["advisors", idx])}
              avatars={profile.avatars}
              />
          ))}

          {profile.canEditProfile && 
            <Buttons style={{ marginTop: "0.5rem"}}>
              {profile.advisors.items.length > 0 && <OccupyFreeSpace />}
              <Button color="primary" onClick={() => profile.advisors.add()} startIcon={<Add />}>add an advisor</Button>
            </Buttons>}
        </TabPanel>



        <TabPanel state={tabs.forTab("assistance")}>
          <Typography>{profile.pageConfig.config.messages?.assistance_message}</Typography>

          <TeamAssitanceForm
            assistance={profile.assistance}
            canEdit
            />
        </TabPanel>



        {profile.pageConfig.config.guideContent && <TabPanel state={tabs.forTab("guide")}>
          <PageViewer content={profile.pageConfig.config.guideContent} />
        </TabPanel>}
      </PagePanel>
    </>
  );
}
