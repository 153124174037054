import React, { useEffect, useState } from 'react';
import { CourtRoomJoinData, useCourtRoom } from './useCourtRoom';
import { useInjectScriptTag } from '../../../../toolympus/components/primitives/useInjectScriptTag';
import { FormGrid, LoadingIndicator } from '../../../../toolympus/components/primitives';
import { Jutsu } from 'react-jutsu';
import { Button, Typography } from '@mui/material';
import { useEditItem2 } from '../../../../toolympus/api/useNewItem';
import { FormControlsForFields } from '../../../../toolympus/components/schemed';
import { PagePanel } from '../../../Common/PageHarness';


const TB_FOR_MODERATOR = [
  'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
  'fodeviceselection', 'hangup', 'profile', 'chat', 
  // 'recording', 'livestreaming',
  'sharedvideo', 'settings', 'raisehand',
  'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
  'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone'
];


const TB_FOR_NO_MODERATOR = [
  'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
  'fodeviceselection', 'hangup', 'chat',
  'sharedvideo', 'settings', 'raisehand',
  'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
  'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone'
];



interface Props {
  stage?: string;
  roundId?: string;
  domain?: string;
  isElevated?: boolean;
  kind: "round" | "deliberations";
  userDisplayName?: string;
  displayNameSuffix?: string;
  editName?: boolean;
  onDisconnect?: () => void;
  wrapEditorWithPanel?: boolean;
}

export const CourtRoomUnbound = (props: Props & { data: CourtRoomJoinData }) => {
  const room = props.data;

  const TOOLBAR_BUTTONS = props.isElevated ? TB_FOR_MODERATOR : TB_FOR_NO_MODERATOR;

  useInjectScriptTag(
    props.domain ? `https://${props.domain}/external_api.js` : undefined,
  );

  const [isShowRoom, setIsShowRoom] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setIsShowRoom(true), 1000);
  }, []);

  const reshow = () => {
    setIsShowRoom(false);
    setTimeout(() => setIsShowRoom(true), 2000);
  }


  const name = useEditItem2<{ display_name: string }>({
    save: item => Promise.resolve(item),
    startWith: { display_name: props.userDisplayName || "" },
  });

  const [isNameSubmitted, setIsNameSubmitted] = useState<boolean>(false);

  if(props.editName && !isNameSubmitted) {
    const editor = <FormGrid style={{ alignItems: "flex-end" }} columns="1fr max-content 2fr">
      <FormControlsForFields
        data={name.item}
        onChange={(o,c) => name.update(c)}
        fields={[
          ["display_name", { label: "Please confirm your name"}]
        ]}
        schema={{}}
        />
      <Button color="primary" onClick={() => name.item?.display_name && setIsNameSubmitted(true)}>confirm and join</Button>
    </FormGrid>
    console.log("XF", props.wrapEditorWithPanel);
    return props.wrapEditorWithPanel
        ? <PagePanel>{editor}</PagePanel>
        : editor;
  }


  if(!isShowRoom) {
    return <FormGrid columns="1fr" style={{ textAlign: "center" }}>
      <LoadingIndicator />
    </FormGrid>
  }


  if(room.isClosed) {
    return <FormGrid columns="1fr" style={{ textAlign: "center" }}>
      <Typography>Thank you for participation!</Typography>
      <Typography>You may close this browser tab now.</Typography>
      <Button color="primary" onClick={() => room.rejoin()}>rejoin</Button>
    </FormGrid>
  }

  const token = props.kind === "deliberations" ? room.data.token_deliberations : room.data.token_round;

  return token
    ? (
      <Jutsu
        loadingComponent={<>...</>}
        errorComponent={<FormGrid columns="1fr" style={{ textAlign: "center" }}>
          <Typography>Some issue happenned while connecting to the room. Try reloading.</Typography>
          <Typography>If the issue persists, please, contact the organizer.</Typography>
          <Button color="primary" variant="contained" onClick={reshow}>reload</Button>
        </FormGrid>}
        containerStyles={{
            width: '100%',
            height: 'calc(100svh - 300px)',
            aspectRatio: 1.34,
        }}
        roomName={props.kind === "deliberations" ? room.data.room_name_deliberations : room.data.room_name_round}
        displayName={`${name.item?.display_name}${props.displayNameSuffix ? " " + props.displayNameSuffix : ""}`}
        domain={props.domain}
        jwt={token}
        onMeetingEnd={() => {
          if(props.onDisconnect) {
            props.onDisconnect();
          }
          room.close();
        }}
        onJitsi={(api: any) => {
          setTimeout(() => {
            api.executeCommand('setTileView', true);
          }, 5000);
          setTimeout(() => {
            api.executeCommand('setTileView', true);
          }, 15000);
          const wrapper = document.getElementById("appcontentwrapper");
          if(wrapper) {
            wrapper.scroll({ top: wrapper.scrollHeight, behavior: "smooth" });
          }
          // setTimeout(() => console.log("XFS", api.getParticipantsInfo()), 5000);
        }}
        configOverwrite={{
          prejoinPageEnabled: false,
          disableSelfView: true,
        }}
        interfaceConfigOverwrite={{
            HIDE_INVITE_MORE_HEADER: true,
            TOOLBAR_BUTTONS,
        }}
        />
    )
    : <LoadingIndicator />;
}

export const CourtRoom = (props: Props) => {
  const room = useCourtRoom(props.stage, props.roundId);

  return <CourtRoomUnbound
    {...props}
    isElevated={!!room.data.token_deliberations}
    data={room}
    />
  
}
