import React from 'react';
import { Dialog, FormGrid, InputIconButton, SimpleDialog } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { EditOutlined, SaveOutlined } from '@mui/icons-material';
import { BailiffListData } from './useBailiffsList';
import { RankStars } from '../../Arbitrators/AdminList/RankStars';
import { Bailiff } from '../types';

interface Props {
  data: BailiffListData;
}

export const BailiffReviewDialog = (props: Props) => {
  const { data } = props;
  
  return (
    <Dialog
      fixedMaxHeight
      isOpen={data.review.isEditing}
      close={() => data.review.cancel()}
      dialogTitle={<>
        <span style={{ whiteSpace: "nowrap" }}>Baliff {data.review.item?.first_name || ""} {data.review.item?.last_name || ""}</span>
        
      </>}
      titleActions={data.review.item ? <>
        <RankStars
          value={data.review.item.rank}
          update={r => {data.updateRank(data.review.item?._id || "", r); data.review.cancel(); }}
          />
        <Button
          color="primary"
          variant={data.review.item.is_approved ? "contained" : undefined}
          onClick={() => data.approveBailiff(data.review.item?._id || "", !data.review.item?.is_approved)}>
          {data.review.item.is_approved ? "un-approve" : "approve"}
        </Button>
        {data.review.hasChanges &&
          <IconButton size="small" color="primary" onClick={() => data.review.save()}><SaveOutlined /></IconButton>}
      </> : undefined}
      fullWidth
      maxWidth="md">

      {data.review.item && <>
        <FormGrid columns="1fr" gap="dense" noMargin>
          <FormControlsForFields
            data={data.review.item}
            schema={{ admin_notes: { type: FieldType.textlong }}}
            onChange={(o,c) => data.review.update(c)}
            fields={[
              ["admin_notes", { label: "Notes (not visible to the bailiff)", autoRows: true }],
            ]}
            />

        </FormGrid>

        <FormGrid columns="1fr 1fr">
          <FormControlsForFields
            data={data.review.item}
            schema={data.schema}
            onChange={(o,c) => data.review.update(c)}
            fields={[
              ["first_name"],
              ["last_name"],
              ["email", {
                readOnly: true,
                controlProps: {
                  endAdornment: (
                    <InputIconButton
                      icon={<EditOutlined />}
                      position="end"
                      action={() => data.changeEmail.startEditing(data.review.item as Bailiff)}
                      />)
                }
              }],
              ["phone"],
            ]}
            />
        </FormGrid>


        <SimpleDialog
          dialogTitle="Change bailiff email"
          isOpen={data.changeEmail.isEditing}
          close={() => data.changeEmail.cancel()}
          save={() => data.changeEmail.save()}>
            {data.changeEmail.item && <FormGrid columns="1fr">
              <FormControlsForFields
                data={data.changeEmail.item}
                schema={data.schema}
                fields={[["email"]]}
                onChange={(o,c) => data.changeEmail.update(c)}
                />
            </FormGrid>}
        </SimpleDialog>
      </>}
    </Dialog>
  );
}
