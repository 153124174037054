import React from 'react';
import styled from '@emotion/styled';
import { TeamTiming } from '../useRoundInfoEdit';
import { padTimeDigits, remainingTimeInMinSec } from './Timer';
import { ThemeSettings } from '../../../theme';

interface Props {
  remainingTime?: number;
  timing: TeamTiming | undefined;
  align?: "left" | "right";
}

const TeamCountdownWrapper = styled.div<{ align?: "left" | "right" }>`
  display: grid;
  grid-template-columns: 50px 100px;
  column-gap: 0.5rem;
  row-gap: 0.25rem;

  justify-self: ${props => props.align === "right" ? "end" : "start"};

  & strong {
    text-align: right;
  }
`;

const CurrentTimeStyle = { color: ThemeSettings.colors.primary };

export const TeamTimingCountdown = (props: Props) => {
  if(props.remainingTime === undefined || !props.timing) {
    return null;
  }
  const remainingTime = props.remainingTime || 0;
  // const t1 = (props.timing.speaker_1.time || 0)*60;
  const t2 = (props.timing?.speaker_2.time || 0)*60;
  const tR = (props.timing?.rebuttal.time || 0)*60;

  const isInT1 = remainingTime > t2+tR;
  const isInT2 = remainingTime <= t2+tR && remainingTime > tR;
  const isInTR = remainingTime <= tR;

  const rem1 = remainingTime > (t2+tR)
    ? Math.max(0, remainingTime - (t2+tR))
    : 0;
  const rem2 = remainingTime >= (t2+tR)
    ? t2
    : remainingTime >= tR
      ? Math.max(0, remainingTime - tR)
      : 0;

  const remR = remainingTime >= tR
    ? tR
    : Math.max(0, remainingTime);

  const [rem1m,rem1s] = remainingTimeInMinSec(rem1);
  const [rem2m,rem2s] = remainingTimeInMinSec(rem2);
  const [remRm,remRs] = remainingTimeInMinSec(remR);

  return (
    <TeamCountdownWrapper align={props.align}>
      <span>1st:</span> <strong style={isInT1 ? CurrentTimeStyle : undefined}>{padTimeDigits(rem1m)}:{padTimeDigits(rem1s)}</strong>
      <span>2nd:</span> <strong style={isInT2 ? CurrentTimeStyle : undefined}>{padTimeDigits(rem2m)}:{padTimeDigits(rem2s)}</strong>
      <span>R:</span> <strong style={isInTR ? CurrentTimeStyle : undefined}>{padTimeDigits(remRm)}:{padTimeDigits(remRs)}</strong>
    </TeamCountdownWrapper>
  );
}
