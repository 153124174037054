import React, { useEffect, useState, ReactNode } from 'react';
import styled from '@emotion/styled';
import { IconButton, Typography } from '@mui/material';
import { apiUploadFile, FetchTypes, apiFetchFile, apiFetch } from '../../api/core';
import { openFileUploader, readBlobAsDataURL } from '../../api/files';
import { DeleteOutlined, PublishOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '../primitives/Tooltip';

interface Props {
    apiUrl: string;
    placeholder?: ReactNode;
    wrapper?: React.ComponentType<any>;
}

const FullAndCenter = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

`;

const Placeholder = styled(FullAndCenter)`
    border: 1px dashed ${props => props.theme.palette.grey[300]};
`;

export const Tint = styled(FullAndCenter)`
    background: #00000080;
    color: #ffffff;
    flex-flow: row;
`;

export const ImageWrapper = styled.div`
    position: relative;
    padding: 0;
    box-shadow: 0 0 5px 0 #00000020;
    flex: 1;


    & > .img-placeholder {
        z-index: 0;
    }

    & > img {
        width: 100%;
        z-index: +100;
    }

    & > .img-tint {
        z-index: +200;
        display: none;
    }
    &:hover > .img-tint {
        display: flex;
    }

`;

export const ImageControl = (props: Props) => {
    const { apiUrl, placeholder, wrapper } = props;

    const [imgSrc, setImgSrc] = useState<string | null>(null);
    const [reloader, setReloader] = useState<number>(0);

    const uploadFile = () => {
        openFileUploader(f => {
            apiUploadFile(apiUrl, FetchTypes.PUT, 'file', f)
                .then(() => setReloader(x => x+1));
        });
    }

    const deleteFile = () => {
        apiFetch(apiUrl, FetchTypes.DELETE)
            .then(() => setImgSrc(null));
    }

    useEffect(() => {
        if(apiUrl) {
            apiFetchFile(apiUrl)
                .then(readBlobAsDataURL)
                .then(url => {
                    if(url) {
                        setImgSrc(url)
                    }})
                .catch(() => {});
        }
    }, [apiUrl, reloader]);

    const Wrapper = wrapper || ImageWrapper;

    return (
        <Wrapper onClick={uploadFile}>
            {imgSrc && <img src={imgSrc} alt="" />}
            <Tint className="img-tint">
                <Tooltip text_id="images.upload">
                    <IconButton style={{ color: "#ffffff" }}>
                        <PublishOutlined />
                    </IconButton>
                </Tooltip>
                {imgSrc && (
                    <Tooltip text_id="images.delete">
                        <IconButton style={{ color: "#ffffff" }} onClick={e => { e.stopPropagation(); deleteFile(); }}>
                            <DeleteOutlined />
                        </IconButton>
                    </Tooltip>
                )}
            </Tint>
            {!imgSrc && <Placeholder className="img-placeholder">
                {placeholder || <Typography variant="caption"><FormattedMessage id="images.upload_image_hint" /></Typography>}
            </Placeholder>}
        </Wrapper>
    );
}
