import React from 'react';
import { Button, IconButton } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { ActionRow, DevModeSwitch, FormGrid, LoadingIndicator, OccupyFreeSpace } from '../../toolympus/components/primitives';
import { ContestStateData } from './useContestStateEdit';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { FileEditUnbound } from '../../toolympus/components/files/FileEdit';

export const ContestStateForm = ({ data }: { data: ContestStateData }) => {
    return (<>
      <ActionRow firstItemNoMargin>
        <DevModeSwitch />

        <OccupyFreeSpace />
        
        <IconButton size="small" style={{ visibility: "hidden" }}><SaveOutlined /></IconButton>
        {data.isLoading && <LoadingIndicator />}
        {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
      </ActionRow>

      <FormGrid columns="1fr 1fr">
        <FormGrid noMargin columns="1fr">
          <FormControlsForFields
            data={data.settings}
            onChange={(o,c) => data.update(c)}
            schema={data.schema}
            fields={[
              ["team_registration_allowed"],
              ["team_profile_edit_allowed"],
              ["team_can_upload_documents"],
              ["arbitrator_registration_allowed"],
              ["arbitrator_profile_edit_allowed"],
              ["rounds_available_to_participants"],
            ]}
            />
        </FormGrid>

        <FormGrid noMargin columns="1fr">
          <FileEditUnbound
            data={data.benchMemo}
            label="Bench memo"
            />

          <FormControlsForFields
            data={data.settings}
            onChange={(o,c) => data.update(c)}
            schema={data.schema}
            fields={[
              ["arbitrator_bench_memo_available"],
            ]}
            />

          <Button
            startIcon={data.prepareFeedback.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
            onClick={() => data.prepareFeedback.run()}
            disabled={data.prepareFeedback.isRunning}
            color="primary">
            prepare rounds feedback
          </Button>
        </FormGrid>
      </FormGrid>
      </>);
}
