import React from 'react';
import { ItemEditorProps, ListEditor } from '../../../toolympus/components/primitives/ListEditor';
import { ContentEditorProps } from './types';
import { generateCode } from '../../../toolympus/components/PowerDoc/plugins/common';
import { FormGrid } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../../theme';
import { MediaLibPicker, MediaLibProvider, useMediaLibContext } from '../../../toolympus/components/medialib';
import { LegacyDocument } from '../useAdminLegacyInfoList';

const DocumentSchema: Schema = {
  title: { label: "Title" },
}

const DocumentWrapper = styled(FormGrid)`
  align-items: end;
  border-left: 2px solid ${ThemeSettings.colors.tableBorder};
  padding-left: 20px;
`;
DocumentWrapper.defaultProps = { columns: "1fr 1fr", gap: "dense", noMargin: true }

const ListWrapper = styled.div`
`;

const DocumentEditor = (props: ItemEditorProps<LegacyDocument>) => {
  const medialib = useMediaLibContext();
  return <DocumentWrapper>
    <FormControlsForFields
      data={props.item}
      onChange={(o,c) => props.update(c)}
      schema={DocumentSchema}
      fields={[
        ["title"],
      ]}
      />
      <MediaLibPicker
        showFilenameInsteadOfImage
        size="small"
        selectedUrl={props.item.url}
        selectFile={mf => props.update({ url: mf ? medialib.getFilepath(mf) : null })}
        allowFileActions
        />
  </DocumentWrapper>
}

export const DocumentsEditor = (props: ContentEditorProps ) => {
  return (
    <MediaLibProvider apiPath="/api/media" spaceId="legacy" urlPrefix="/media" loadOnDemand>
      <ListEditor
        idField="_id"
        items={props.block.content?.items}
        createDflt={() => ({ _id: generateCode() })}
        update={items => props.update({ content: { ...props.block?.content, items }})}
        itemEditor={DocumentEditor}
        wrapperComponent={ListWrapper}        
        />
    </MediaLibProvider>
  );
}
