import React from "react";
import { Global, css } from "@emotion/react";
import { ThemeSettings } from "../../theme";

const globalStyle = css`
  html, body {
    margin: 0;
    @media ${ThemeSettings.sizes.breakpoints.sm.down} {
      font-size: 14px;
    }
  }
  


  * {
    font-family: ${ThemeSettings.typography.fontFamily};
  }

  h1, h2, h3, h4 {
    font-family: ${ThemeSettings.typography.fontFamilyHeaders};

    span {
      font-family: ${ThemeSettings.typography.fontFamilyHeaders};
    }
  }

  h2 {
    font-size: ${ThemeSettings.typography.fontSizes.h2};
  }
  h3 {
    font-size: ${ThemeSettings.typography.fontSizes.h3};
  }
  h4 {
    font-size: ${ThemeSettings.typography.fontSizes.h4};
  }

  * ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: ${ThemeSettings.colors.backgroundAlt};
  }
  * ::-webkit-scrollbar-thumb {
      background-color: ${ThemeSettings.colors.primary};
  }
`;

export const GlobalStyles = () => <Global styles={globalStyle} />
