import React from 'react';
import styled from '@emotion/styled';
import { ParticipantRoundsListData, useParticipantRoundsList } from '../useParticipantRoundList';
import { PageHeader } from '../../Common/PageHeader';
import { PagePanel, PagePanelMessage } from '../../Common/PageHarness';
import { RoundPanelMessage } from './RoundPagesComponents';
import { PageViewer } from '../../Common/PageViewer';
import { FormGrid, PseudoLink2 } from '../../../toolympus/components/primitives';
import { Typography } from '@mui/material';
import { useFormats } from '../../../toolympus/components/schemed';
import { RolesKeys } from '../../Auth';
import { Link } from 'react-router-dom';
import { Round } from '../../../toolympus/components/Contests/Grid/types';
import { BaseBiIconProps, baseBiIconProps } from '../../Common/Icons';
import { BiRightArrowAlt } from 'react-icons/bi';
import { ThemeSettings } from '../../../theme';

interface Props {
  
}



const RoundWrapper = styled(Link)`
  text-decoration: none;
  background: ${ThemeSettings.colors.background};
  &:nth-child(n) {
    color: initial;
    border: none;
  }

  & h6 {
    color: ${ThemeSettings.colors.shadeLight};
  }
  & .time {
    color: ${ThemeSettings.colors.textLight};
  }

  box-shadow: ${ThemeSettings.sizes.shadow.panel} ${ThemeSettings.colors.shadow.panel};
  border-radius: ${ThemeSettings.sizes.borderRadius.panel}px;
  padding: 12px 16px;

  & .see-more {
    position: relative;

    & .${BaseBiIconProps.className} {
      transition: left 0.5s ease-in;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:hover {
    & .see-more {
      & .${BaseBiIconProps.className} {
        transition: left 0.5s ease;
        left: calc(100% + 6px);
      }
    }

  }
`;

const StageWrapper = styled(FormGrid)`
`;
StageWrapper.defaultProps = { columns: "repeat(4,1fr)" };

const RoundsListWrapper = styled.div`
`;

const RoleToPath = {
  [RolesKeys.arbitrator]: "judge",
  [RolesKeys.team]: "team",
  [RolesKeys.bailiff]: "bailiff",
}

const RoundItem = (props: { data: ParticipantRoundsListData, round: Round }) => {
  const { round, data } = props;
  const { formatDatetimeShort } = useFormats();

  const [team1, team2] = [
    (round.players || {})[1],
    (round.players || {})[2],
  ];

  return (
    <RoundWrapper to={`/${RoleToPath[data.role]}/round/${round._id}`}>
      <Typography variant="h6">{round.title}. {team1 ? `${team1.display_name}A` : "..."} vs {team2 ? `${team2.display_name}R` : "..."}</Typography>
      {round.time && <Typography className="time" variant="caption" component="p">{formatDatetimeShort(round.time)}</Typography>}
      <PseudoLink2 className="see-more">{data.config.messages.general?.see_details} <BiRightArrowAlt {...baseBiIconProps(16)} /></PseudoLink2>
    </RoundWrapper>
  )
}

const RoundsList = (props: { data: ParticipantRoundsListData }) => {
  return <RoundsListWrapper>
    {props.data.roundsByStage.map(stage => (
      <StageWrapper key={stage.stage}>
        {stage.rounds.map(round => <RoundItem key={round._id} data={props.data} round={round} />)}
      </StageWrapper>
    ))}
  </RoundsListWrapper>
}

export const ParticipantRoundsList = (props: Props) => {
  const data = useParticipantRoundsList();

  return (
    <>
      <PageHeader
        title={data.config.isLoading
          ? "..."
          : data.config.messages.general?.title}
        isLoading={data.isLoading}>
      </PageHeader>

      {!data.config.isLoading && !data.config.state.rounds_available_to_participants &&
        <PagePanelMessage>
          <RoundPanelMessage
            title={data.config.messages.general?.not_available_message || ""}
            />
        </PagePanelMessage>}

      {data.config.state.rounds_available_to_participants &&
        <PagePanel animateAppearence>
          <PageViewer content={data.config.pageInfo.page.content} key={data.config.pageInfo.page._id} />
        </PagePanel>}
        
      {data.config.state.rounds_available_to_participants &&
        <RoundsList data={data} />}
    </>
  );
}
