import React from 'react';
import { MediaLibProvider } from '../toolympus/components/medialib/MediaLibContext';
import { DocumentationRoutes } from '../toolympus/components/Documentation';

interface Props {
    
}

export const Documentation = (props: Props) => {
    return (
        <MediaLibProvider apiPath="/api/media" spaceId="docs" urlPrefix="/media">
            <DocumentationRoutes apiPath="/api/documentation" rootSlug="documentation" />
        </MediaLibProvider>
    );
}
