import React from 'react';
import styled from '@emotion/styled';
import { RoundsFeedbackConfig, TeamRoundFeedback, TeamRoundsFeedbackData, TeamSpeakerScores } from './useTeamRoundsFeedback';
import { Typography } from '@mui/material';
import { useFormats } from '../../../toolympus/components/schemed';
import { ThemeSettings } from '../../../theme';

const RoundWrapper = styled.div`
  & .round-title {
    margin: 2rem 0 1rem;

    & .time {
      font-weight: 400;
      font-size: 0.8rem;
      color: ${ThemeSettings.colors.textLight};
    }

  }

  @media print {
    break-inside: avoid;
  }
`;

const ScoresTable = styled.table`
  table-layout: fixed;
  width: 100%;
  
  & tr {
    &:nth-child(2n) {
      background: ${ThemeSettings.colors.backgroundAltLighter};
    }
  }

  & th {
    font-weight: 500;
    color: ${ThemeSettings.colors.shadeLight};
  }

  & th, & td {
    text-align: center;
    padding: 8px 8px;

    &:first-child {
      text-align: left;
      border-left: 2px solid ${ThemeSettings.colors.primary}40;
      width: 300px;

      @media print {
        width: 150px;
      }
    }
    &:not(:first-child) {
      width: calc((100% - 300px) / 6);

      @media print {
        width: calc((100% - 150px) / 6);
      }
    }
    &:last-child {
      border-right: 2px solid ${ThemeSettings.colors.primary}40;
    }
    
    &:nth-child(4) {
      border-right: 2px solid ${ThemeSettings.colors.primary}40;
    }
  }

  & thead tr:nth-child(1) th:nth-child(2), & td:nth-child(1), & th:nth-child(1) {
    border-right: 2px solid ${ThemeSettings.colors.primary}40;
  }

  & thead tr, & tbody tr:last-child {
    & th, & td {
      border-bottom: 2px solid ${ThemeSettings.colors.primary}40;
      font-weight: 500;
      color: ${ThemeSettings.colors.shadeLight};
    }
  }

  @media print {
    & thead tr {
      font-size: 0.7rem;
    }
  }

  & thead tr:first-child {
    & th, & td {
      border-top: 2px solid ${ThemeSettings.colors.primary}40;
    }
  }

  @media print {
    & thead tr {
      font-size: 0.7rem;
    }
  }
  
`;


const TextFeedbackTable = styled.table`
  table-layout: fixed;
  width: 100%;

  & .judge-name {
    font-weight: 500;
    color: ${ThemeSettings.colors.shadeLight};
  }

  & td:first-child {
    width: 300px;
  }

  & td:not(:first-child) {
    width: calc((100% - 300px) / 2);
    min-width: calc((100% - 300px) / 2);
  }

  @media print {
    & td:first-child {
      display: none;
    }

    & td:not(:first-child) {
      width: 50%;
      min-width: 50%;
    }
  }
`;


interface Props {
  data: TeamRoundsFeedbackData;
  messages?: Record<string, string>;
}

const getSpeakerAndScores = (fb: TeamRoundFeedback, config: RoundsFeedbackConfig, idx: number) => {
  const source = fb.scores.scores[idx];
  const scores = source ? source[1] : [];

  const speakerCriteria = config.speaker_criteria;

  const speaker = scores.length && speakerCriteria._id ? scores[0].scores[speakerCriteria._id] : undefined;

  return {
    speaker,
    scores,
  }
}

const getJudges = (fb: TeamRoundFeedback) => {
  const allScores = fb.scores.scores.reduce<TeamSpeakerScores[]>((r,s) => {
    s[1].forEach(x => { r.push(x); });
    return r;
  }, []);

  const judges = [1,2,3].map(position => allScores.find(s => s.judge_position === position)?.judge);

  return judges;
}

const sanitizeSpeakerName = (speaker: any) => {
  const comma = (speaker || "").indexOf(",");
  if(comma >= 0) {
    return speaker.substring(0,comma);
  } else {
    return speaker;
  }
}

const prepareShapedScores = (fb: TeamRoundFeedback, config: RoundsFeedbackConfig) => {
  const s1 = getSpeakerAndScores(fb, config, 0);
  const s2 = getSpeakerAndScores(fb, config, 1);

  const judges = getJudges(fb);
  
  const scoresBySpeakerByJudge: Record<number, Record<number, TeamSpeakerScores | undefined>> = {};
  [s1,s2].forEach((sx,speakerIdx) => {
    judges.forEach((j,judgeIdx) => {
      if(!scoresBySpeakerByJudge[speakerIdx]) {
        scoresBySpeakerByJudge[speakerIdx] = {};
      }
      scoresBySpeakerByJudge[speakerIdx][judgeIdx] = sx.scores.find(sxx => sxx.judge_position === judgeIdx + 1) || undefined;
    });
  })


  return {
    s1,
    s2,
    s1Name: sanitizeSpeakerName(s1.speaker),
    s2Name: sanitizeSpeakerName(s2.speaker),
    judges,
    scoresBySpeakerByJudge,
  }
}


const RoundScoresTable = (props: { scores: TeamRoundFeedback, config: RoundsFeedbackConfig }) => {
  const { scores, config } = props;

  const { s1Name, s2Name, judges, scoresBySpeakerByJudge } = prepareShapedScores(scores, config);

  return (<>
    <ScoresTable>
      <thead>
        <tr>
          <th></th>
          <th className="speaker-title" colSpan={3}>{s1Name || ""}</th>
          <th className="speaker-title" colSpan={3}>{s2Name || ""}</th>
        </tr>
        <tr>
          <th></th>
          {judges.map((j,i) => (
            <th key={10+i}>
              {j?.first_name || ""} {j?.last_name || ""}
            </th>
          ))}
          {judges.map((j,i) => (
            <th key={20+i}>
              {j?.first_name || ""} {j?.last_name || ""}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {(config.numeric_criteria || []).map(c => (
          <tr key={c._id}>
            <th>{c.name}</th>
            {[0,1,2].map(judgePosition => (
              <td key={`s0_j${judgePosition}`}>
                {(scoresBySpeakerByJudge[0][judgePosition]?.scores || {})[c._id || ""] || ""}
              </td>
            ))}
            {[0,1,2].map(judgePosition => (
              <td key={`s1_j${judgePosition}`}>
                {(scoresBySpeakerByJudge[1][judgePosition]?.scores || {})[c._id || ""] || ""}
              </td>
            ))}
          </tr>

        ))}
        <tr>
          <th></th>
          {[0,1,2].map(judgePosition => (
              <td key={`s0_j${judgePosition}`}>
                {scoresBySpeakerByJudge[0][judgePosition]?.result}
              </td>
            ))}
          {[0,1,2].map(judgePosition => (
              <td key={`s1_j${judgePosition}`}>
                {scoresBySpeakerByJudge[1][judgePosition]?.result}
              </td>
            ))}
        </tr>
      </tbody>
    </ScoresTable>

    <TextFeedbackTable>
      <td></td>
      <td>
        {[0,1,2].map(judgePosition => {
          return config.text_criteria
            .filter(c => !!(scoresBySpeakerByJudge[0][judgePosition]?.scores || {})[c._id || ""])
            .map(c => (
              <p key={`s0_j${judgePosition}_${c._id}`}>
                <span className="judge-name">{judges[judgePosition]?.first_name || ""} {judges[judgePosition]?.last_name || ""}</span>: {(scoresBySpeakerByJudge[0][judgePosition]?.scores || {})[c._id || ""] || ""}
              </p>
            ))})}
      </td>

      <td>
        {[0,1,2].map(judgePosition => {
          return config.text_criteria
          .filter(c => !!(scoresBySpeakerByJudge[1][judgePosition]?.scores || {})[c._id || ""])
            .map(c => (
              <p key={`s1_j${judgePosition}_${c._id}`}>
                <span className="judge-name">{judges[judgePosition]?.first_name || ""} {judges[judgePosition]?.last_name || ""}</span>: {(scoresBySpeakerByJudge[1][judgePosition]?.scores || {})[c._id || ""] || ""}
              </p>
            ))})}
      </td>
    </TextFeedbackTable>
  </>)
}


export const TeamRoundsFeedbackForm = (props: Props) => {
  const { data } = props;

  const { formatDatetime } = useFormats();

  if(data.isEmpty) {
    return null;
  }

  return (
    <>
      <Typography variant="h3">{props.messages?.feedback_label || "Scoring sheets"}</Typography>
      {props.messages?.feedback_guide &&
        <Typography style={{ whiteSpace: "pre-line" }}>{props.messages?.feedback_guide}</Typography>}
      {data.data.feedbacks.map(roundFeedback => (
        <RoundWrapper key={roundFeedback.round_id}>
          <Typography variant="h4" className="round-title">
            {roundFeedback.round_title}{roundFeedback.round_subtitle ? ". " : ""}{roundFeedback.round_subtitle || ""} <span className="time">{roundFeedback.round_time ? formatDatetime(roundFeedback.round_time) : ""}</span>
          </Typography>
          
          <RoundScoresTable scores={roundFeedback} config={data.data.config.sheet} />
        </RoundWrapper>
      ))}
    </>
  );
}
