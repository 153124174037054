import { useMemo, useState } from "react";
import { usePlatformPage } from "../Common/data/usePlatformPage";
import { getCustomBlocksData } from "../../toolympus/components/CMS/Usage";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { apiFetch, apiUploadFile } from "../../toolympus/api/core";
import { useAction } from "../../toolympus/api/useAction";
import { FileInfo } from "../../toolympus/components/files";
import { Bailiff } from "./types";



const usePageConfig = () => {
  const pageInfo = usePlatformPage("bailiff-profile-page");
  const config = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);

    const messages = (pageData["page-messages"] || {});

    return {
      messages,
    };
  }, [pageInfo.data]);

  return {
    config,
    isLoading: pageInfo.isLoading,
    state: pageInfo.data.contest_state,
    pageInfo,
  };
}


const ApiPath = "/api/bailiff/profile";

export const useBailiffProfile = () => {
  const pageConfig = usePageConfig();
  const data = useCrudItem<Bailiff>(ApiPath, {
    defaultValue: {
      _id: "",
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
    } as Bailiff,
  });

  const resendConfirmationEmail = useAction(() => apiFetch<{}>("/api/bailiff/resend-email-confirmation", "POST"));

  const isProfileLoaded = !!data.data._id;

  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false);
  const uploadAvatar = (file: File): Promise<FileInfo> => {
    setIsAvatarUploading(true);
    return apiUploadFile("/api/bailiff/avatar", "post", "file", file)
      .then(data => {
        setIsAvatarUploading(false);
        return data.data;
      })
      .catch(e => {
        setIsAvatarUploading(false);
        throw e;
      })
  }
  const avatar = {
    isAvatarUploading,
    uploadAvatar,
  }

  return {
    pageConfig,

    ...data,

    isProfileLoaded,
    canEditProfile: true,
    resendConfirmationEmail,

    avatar,
  }
}

export type BailiffProfileData = ReturnType<typeof useBailiffProfile>;
