import React from 'react';
import { PageHeader } from '../../../Common/PageHeader';
import { PagePanel } from '../../../Common/PageHarness';
import { useCourtRoom } from './useCourtRoom';
import { CourtRoomUnbound } from './CourtRoom';
import { useUser } from '../../../../toolympus/userContext/UserContext';
import { RolesKeys } from '../../../Auth';
import { getUserPrimaryRole } from '../RoundPagesComponents';

interface Props {
  
}

export const DisplayNameSuffix = {
  [RolesKeys.bailiff]: "(Bailiff)",
  [RolesKeys.arbitrator]: "(Judge)",
  [RolesKeys.admin]: "(Organizer)",
}

export const CourtRoomPage = (props: Props) => {
  const params = new URLSearchParams(window.location.search);

  const stage = params.get("s") || "";
  const round = params.get("r") || "";
  const kind = params.get("k") || "";
  const domain = params.get("d") || "";
  const name = params.get("n") || "";

  const data = useCourtRoom(stage, round);

  const { user } = useUser();
  const primaryRole = getUserPrimaryRole(user);
  
  
  return (
    <>
      <PageHeader title={kind === "deliberations" ? "Deliberations Room" : "Court Room"} isLoading={data.isLoading} />
      
      <PagePanel fillPage>
        <CourtRoomUnbound
          kind={(kind as "round" | "deliberations") || "round"}
          domain={domain}
          stage={stage}
          roundId={round}
          data={data}
          userDisplayName={name}
          displayNameSuffix={DisplayNameSuffix[primaryRole]}
          editName={primaryRole === RolesKeys.team || primaryRole === RolesKeys.admin}
          />
      </PagePanel>
    </>
  );
}
