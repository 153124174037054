import { useMemo } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";

export interface ScoringStatusRecord {
  position: number;
  score_key: string;
  score_type: string;
  sort_order: string;
  is_scored?: boolean;
  value?: number;
}

export interface ArbitratorRoundScoringStatus {
  records: ScoringStatusRecord[];
  scored_cnt: number;
  is_fully_scored?: boolean;
}

export type RoundScoringStatus = Record<string, ArbitratorRoundScoringStatus>;
export type StageScoringStatus = Record<string, RoundScoringStatus>;

export const ScoresPerArbPerRound = 4;

export const useStageScoringStatus = (stage: string) => {
  const data = useLoadedData<StageScoringStatus>(`/api/rounds-info/${stage}/scoring-status`, {}, !!stage);

  const getStatus = useMemo(() => {

    return (roundId: string, arbitratorId: string) => {
      const round = data.data[roundId] || {};
      const arb = round[arbitratorId] || { scored_cnt: 0, records: [] };
      arb.is_fully_scored = arb.scored_cnt === ScoresPerArbPerRound;
      return arb;
    }
  }, [data.data]);

  return {
    ...data,
    getStatus,
    scoresPerArbPerRound: ScoresPerArbPerRound,
  }
}

export type StageScoringStatusData = ReturnType<typeof useStageScoringStatus>;



export const useRoundScoringStatus = (stage: string, roundId: string) => {
  const data = useLoadedData<RoundScoringStatus>(`/api/rounds-info/participant/${stage}/${roundId}/scoring-status`, {}, !!stage && !!roundId);

  const getStatus = useMemo(() => {

    return (arbitratorId: string) => {
      const arb = data.data[arbitratorId] || { scored_cnt: 0, records: [] };
      arb.is_fully_scored = arb.scored_cnt === ScoresPerArbPerRound;
      return arb;
    }
  }, [data.data]);

  return {
    ...data,
    getStatus,
    scoresPerArbPerRound: ScoresPerArbPerRound,
  }
}

export type RoundScoringStatusData = ReturnType<typeof useRoundScoringStatus>;

