import React, { ReactNode } from 'react';
import { FileInfo } from '../../../toolympus/components/files';
import { ImageDisplay } from '../../../toolympus/components/files/ImageDisplay';
import { FileEditUnbound } from '../../../toolympus/components/files/FileEdit';

interface Props {
  avatar_url?: string;
  update: (v: string) => void;
  isUploading?: boolean;
  upload?: (fi: File) => Promise<FileInfo>;
  readOnly?: boolean;
  placeholder?: ReactNode;
}

export const AvatarEditor = (props: Props) => {
  return (
    props.avatar_url
      ? <ImageDisplay src={props.avatar_url} remove={() => props.update("")} />
      : props.readOnly
        ? <div className="avatar-placeholder" />
        : <FileEditUnbound
            placeholder={props.placeholder || "Drop photo here or click to select"}
            data={{
              download: () => {},
              file: null,
              isLoading: props.isUploading || false,
              remove: () => Promise.resolve(),
              upload: f => !props.readOnly && props.upload ? props.upload(f).then(fi => { props.update(`/avatar/${fi._id}/${fi.filename}`); return fi; }) : Promise.resolve({ _id: "", contenttype: "", filename: "", kind: "", owner_id: "", url: "" }),
            }}
          />
  );
}
