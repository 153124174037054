import { Button } from '@mui/material';
import { Check } from '@mui/icons-material';
import React, { useState } from 'react';

interface Props {
  
}

export const DevMode_LSKey = "_tl_dev_mode";

export const isDevMode = () => localStorage.getItem(DevMode_LSKey) === "t";

export const DevModeSwitch = (props: Props) => {
  const isDev = isDevMode();

  const [bumper,setBumper] = useState<number>(0);

  return (
    <Button
      key={bumper}
      startIcon={isDev ? <Check /> : undefined}
      variant={isDev ? "contained" : "text"}
      color="primary"
      size="small"
      onClick={() => { localStorage.setItem(DevMode_LSKey, isDev ? "f" : "t"); setTimeout(() => setBumper(x => x+1), 500) }}>
      dev mode
    </Button>
  );
}
