import React from 'react';
import { Buttons, Dialog, FormGrid, OccupyFreeSpace, SimpleDialog, TabPanel, useTabsState } from '../../../toolympus/components/primitives';
import { Button, IconButton, Typography } from '@mui/material';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { TabButtons } from '../../Common/TabButtons';
import { ArbitratorListData } from './useArbitratorsList';
import { ArbitratorPersonalInfoForm } from '../Profile/ArbitratorPersonalInfoForm';
import { ArbitratorExperienceForm } from '../Profile/ArbitratorExperienceForm';
import { ArbitratorConflictsForm } from '../Profile/ArbitratorConflictsForm';
import { ArbitratorAvailabilityForm } from '../Profile/ArbitratorAvailabilityForm';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { SaveOutlined } from '@mui/icons-material';
import { RankStars } from './RankStars';

interface Props {
  data: ArbitratorListData;
}

export const ArbitratorReviewDialog = (props: Props) => {
  const { data } = props;

  const reviewTabs = useTabsState([
    ["personal_info", "Personal info"],
    ["experience", "Experience"],
    ["conflicts", "Conflicts"],
    ["availability", "Availability"],
  ], "personal_info");

  const arbData = data.review.editData; // useArbitratorProfileFake(data.review.item);
  
  return (
    <Dialog
      fixedMaxHeight
      isOpen={data.review.isEditing}
      close={() => data.review.cancel()}
      dialogTitle={<>
        <span style={{ whiteSpace: "nowrap" }}>Judge {data.review.item?.title || ""} {data.review.item?.first_name || ""} {data.review.item?.last_name || ""}</span>
        
      </>}
      titleActions={<>
        <TabButtons
          currentTab={reviewTabs.current}
          selectTab={reviewTabs.setCurrent}
          tabs={reviewTabs.tabs}
          />
      </>}
      fullWidth
      maxWidth="lg">

      {data.review.item && arbData && <>

        <Buttons>
          <OccupyFreeSpace />
          {data.review.item && <RankStars
            value={data.review.item.rank}
            update={r => {data.updateRank(data.review.item?._id || "", r); data.review.cancel(); }}
            />}
          <Button
            color="primary"
            variant={data.review.item.is_excluded ? "contained" : undefined}
            onClick={() => data.excludeArbitrator(data.review.item?._id || "", !data.review.item?.is_excluded)}>
            {data.review.item.is_excluded ? "approve" : "un-approve"}
          </Button>
          {data.review.hasChanges &&
            <IconButton size="small" color="primary" onClick={() => data.review.save()}><SaveOutlined /></IconButton>}
        </Buttons>

        <TabPanel state={reviewTabs.forTab("personal_info")}>
          <FormGrid columns="1fr" gap="dense" noMargin>
            <FormControlsForFields
              data={data.review.item}
              schema={{ admin_notes: { type: FieldType.textlong }}}
              onChange={(o,c) => data.review.update(c)}
              fields={[
                ["admin_notes", { label: "Notes (not visible to the judge)", autoRows: true }],
              ]}
              />

            <Typography variant="caption" color="textSecondary">Conflict tags</Typography>
            {data.conflicts.displayForRecord(data.review.item._id)}
          </FormGrid>

          {arbData.data &&
            <ArbitratorPersonalInfoForm
              data={arbData}
              startChangeEmail={() => data.review.item && data.changeEmail.startEditing(data.review.item)}
              canEditProfile
              />}
          
        </TabPanel>

        <TabPanel state={reviewTabs.forTab("experience")}>
          {arbData.data &&
            <ArbitratorExperienceForm data={arbData} canEditProfile />}
        </TabPanel>

        <TabPanel state={reviewTabs.forTab("conflicts")}>
          {arbData.data &&
            <ArbitratorConflictsForm data={arbData} canEditProfile />}
        </TabPanel>

        <TabPanel state={reviewTabs.forTab("availability")}>
          {arbData.data &&
            <ArbitratorAvailabilityForm data={arbData} canEditProfile />}
        </TabPanel>


        <SimpleDialog
          dialogTitle="Change arbitrator email"
          isOpen={data.changeEmail.isEditing}
          close={() => data.changeEmail.cancel()}
          save={() => data.changeEmail.save()}>
            {data.changeEmail.item && <FormGrid columns="1fr">
              <FormControlsForFields
                data={data.changeEmail.item}
                schema={data.schema}
                fields={[["email"]]}
                onChange={(o,c) => data.changeEmail.update(c)}
                />
            </FormGrid>}
        </SimpleDialog>
      </>}
    </Dialog>
  );
}
