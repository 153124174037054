import { useSettings } from "../../toolympus/api/useSettings"

export interface CourtRoomSettings {
  is_available?: boolean;
  domain?: string;
  token_secret?: string;
  token_app_id?: string;
  room_name_round?: string;
  room_name_deliberations?: string;
}

const DefaultCourtRoom: CourtRoomSettings = {
}


export const useCourtRoomSettingsEdit = () => {
    const data = useSettings<CourtRoomSettings>("/api/court-room", DefaultCourtRoom);
    
    return {
      ...data,
    }
}

export type CourtRoomData = ReturnType<typeof useCourtRoomSettingsEdit>;
