import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { LegacyContentProps } from './types';
import { Typography } from '@mui/material';
import { LegacyDocument } from '../../../LegacyInfo';
import { FormGrid } from '../../../../toolympus/components/primitives';
import { BiFile } from 'react-icons/bi';
import { BaseBiIconProps } from '../../../Common/Icons';
import { ThemeSettings } from '../../../../theme';

const DocumentsList = styled(FormGrid)`
  & a:nth-child(n) {
    color: inherit;
    border-bottom: none;

    display: flex;
    align-items: center;
    gap: 0.5rem;  
    text-decoration: none;

    & .gr-icon {
      color: ${ThemeSettings.colors.shadeLightHalfTransp};
    }

    & span {
      color: ${props => props.theme.palette.primary.main};
      border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
      padding-bottom: 2px;
    }
  }
`;
DocumentsList.defaultProps = { columns: "1fr 1fr" };

export const DocumentsDisplay = (props: LegacyContentProps & { title: ReactNode }) => {
  const documents: LegacyDocument[] = (props.block.content.items || []).filter((p: LegacyDocument) => !!p.url && !!p.title);

  if(!documents.length) {
    return null;
  }

  return (<>
    <Typography variant="h4">{props.title}</Typography>
    <DocumentsList>
      {documents.map(doc => (
        <a key={doc._id} href={doc.url || ""} target="_blank" rel="noopener noreferrer"><BiFile {...BaseBiIconProps} /><span>{doc.title}</span></a>
      ))}
    </DocumentsList>
  </>);
}
