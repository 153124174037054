import React from 'react';
import { FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { LegacyInfoTransferData } from './useAdminLegacyInfoList';
import { FieldType, Schema } from '../../toolympus/hooks/useSchema';
import { Typography } from '@mui/material';
import { useItemWithControls } from '../../toolympus/api/useWithControls';

interface Props {
  data: LegacyInfoTransferData;
}

const LegacyTransferParamsSchema: Schema = {
  year: { label: "Year" },
  members: { type: FieldType.bool, label: "Members" },
  advisors: { type: FieldType.bool, label: "Advisors" },
  documents: { type: FieldType.bool, label: "Documents" },
}

export const LegacyTransferDialog = (props: Props) => {
  const { controls } = useItemWithControls(
    { data: props.data.item || { year: "" }, update: props.data.update, errors: props.data.errors },
    { schema: LegacyTransferParamsSchema });

  return (
    <SimpleDialog
      isOpen={props.data.isEditing}
      close={() => props.data.cancel()}
      save={() => props.data.save()}
      noFullscreen
      dialogTitle="Populate legacy from current iteration"
      isLoading={props.data.isLoading}
      >
      <Typography>Specify the year and select what to transfer. If corresponding block exists for a university-year pair, it won't be created.</Typography>

      <FormGrid columns="1fr">
        {controls([
          ["year"],
        ])}
      </FormGrid>
      <FormGrid columns="1fr 1fr 1fr" forceEvenColumns>
        {controls([
          ["members"],
          ["advisors"],
          ["documents"],
        ])}
      </FormGrid>
    </SimpleDialog>
  );
}
