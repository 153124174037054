import React from 'react';
import { SubjectOutlined } from "@mui/icons-material";
import { CMSMenu, CMSRecord } from "../../toolympus/components/CMS";
import { forAny, forBlock, setting, suggest } from '../../toolympus/components/PowerDoc/PowerEditorSettingsSuggestions';
import { PowerEditorSettingsProvider } from '../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { PowerEditorBase, PowerEditorBaseProps } from '../../toolympus/components/PowerDoc';
import { ColumnsPlugin } from '../../toolympus/components/PowerDoc/plugins/Columns';
import { CustomFieldsBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from '../../toolympus/components/PowerDoc/plugins/Expander';
import { PanelPlugin } from '../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { ScriptPlugin } from '../../toolympus/components/PowerDoc/plugins/Script';
import { TablesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tiles';
import { PanelBlockElementType } from '../../toolympus/components/PowerDoc/plugins/Panel/PanelBlockElement';

const Icons = {
  cms_page: <SubjectOutlined />,
}

const SiteHost = "https://jessuprussia.ru";

const getSiteUrl = (record: Partial<CMSRecord> | null) => {
  const lang = record?.lang || "ru";

  switch(record?.record_type) {
      case "cms_page":
          const slug = (record?.slug === "index" ? "" : record?.slug || "").replace(/\./g, "/");
          return !slug && lang === "ru" ? SiteHost : `${SiteHost}/${lang}/${slug}`;
  }
}

const SettingsSuggestions = [
  suggest(forAny(),
    setting("_class")),
  suggest(forAny(),
    setting("display_type")),
  suggest(forBlock(PanelBlockElementType),
    setting("display_type", ["message"])),
  suggest(forAny(),
    setting("open", ["true", "false"])),
  suggest(forAny(),
    setting("icon", ["check", "error"])),
];

export const CMS = () => {
  return (
        <PowerEditorSettingsProvider
            EditorComponent={JessupEditorUnwrapped}
            settingsSuggestions={SettingsSuggestions}>

            <CMSMenu apiPath="/api/cms/" icons={Icons} configuration={{ getSiteUrl }} includeMediaLib />

        </PowerEditorSettingsProvider>      
  );
}


export const JessupEditorUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  // const linkedRecordsPlugin = useLinkedRecordsEditorPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              CustomFieldsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
              // linkedRecordsPlugin,
              // ConfigurableFormsEditorPlugin,
          ]}
          />
  );
}