import { useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { RoundScore, RoundToScore, ScoreRoundData, groupScores } from "../../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode";
import { LoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useScoring } from "../../../toolympus/components/Contests/ScoringSheets";

export const useScoreRound = (round: LoadedData<RoundToScore>): ScoreRoundData => {
  const roundPath = `/api/rounds/arbiter/round/${round.data._id}`;
  const setPlayerSelectScore = (score: RoundScore, position: 1 | 2) => {
    if(score && score._id) {
        apiFetch(`${roundPath}/score/${score._id}/value`, "put", { value: position })
          .then(() => round.reload());
    }
  }

  const [activeScore, setActiveScore] = useState<RoundScore | null>(null);

  const startScoring = (s: RoundScore) => setActiveScore(s);
  const cancelScoring = () => setActiveScore(null);

  const scoring = useScoring(
      `${roundPath}/score/${activeScore?.sheet}/${activeScore?._id}`,
      activeScore?.sheet,
      activeScore?._id,
      { pathAsIs: true });

  const scoresGrouped = round.data && round.data.scores ? groupScores(round.data.scores) : [];

  return {
      round,
      getPlayer: (p: number) => (round.data && round.data.players && round.data.players[p]) || { party_id: "", display_name: "-" },
      scoresGrouped,

      setPlayerSelectScore,

      activeScore,
      startScoring,
      cancelScoring,
      scoring: {
          ...scoring,
          submit: () => scoring.submit().then(x => { round.reload(); cancelScoring(); return x; }),
      },
  };
}

