import React from 'react';
import { usePlatformPageWithPanels } from '../Common/data/usePlatformPage';
import { PagePanel, PagePanelMessage } from '../Common/PageHarness';
import { PageViewer } from '../Common/PageViewer';
import { CustomElement } from '../../slate';
import { Check, ErrorOutline } from '@mui/icons-material';
import { PageHeader } from '../Common/PageHeader';

interface Props {
  slug: string;
}

const MessageIconComponents: Record<string, typeof Check> = {
  check: Check,
  error: ErrorOutline,
}

export const ContentMessagePanel = (props: { block: CustomElement }) => {
  const invert = !!(props.block as any).invert;
  const IconComp = MessageIconComponents[(props.block as any).icon];
  return (
    <PagePanelMessage
      invert={invert}
      actions={<>
        {IconComp && <IconComp color={invert ? "inherit" : "primary"} fontSize="large" />}
      </>}>
      <PageViewer content={props.block.content} />
    </PagePanelMessage>);
  }

export const TextPage = (props: Props) => {
  const data = usePlatformPageWithPanels(props.slug);
  return (
    <>
      <PageHeader title={data.page.title} isLoading={data.isLoading} />
      
      {data.messages.map((m,idx) => (
        <ContentMessagePanel key={`${data.page._id}__${idx}`} block={m} />
      ))}

      <PagePanel fillPage={data.page._id}>
        <PageViewer content={data.page.content} key={data.page._id} />
      </PagePanel>
    </>
  );
}
