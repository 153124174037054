import React from 'react';
import { useBailiffRoundData } from './useParticipantRoundData';
import { ParticipantRoundPage } from './ParticipantRoundPage';
import { useParams } from 'react-router-dom';

interface Props {
  
}

export const BailiffRoundPage = (props: Props) => {
  const { roundid } = useParams<{ roundid: string }>();
  const data = useBailiffRoundData(roundid);
  return (
    <ParticipantRoundPage data={data} isBailif />
  );
}
