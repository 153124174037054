import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const ThemeSettings = {
  colors: {
    text: "#222222",
    textLight: "#222222a0",
    shade: "#0d2228",
    shadeLight: "#163e46",
    shadeLightTransp: "#163e4680",
    shadeLightHalfTransp: "#163e46c0",
    background: "#FFFFFF",
    backgroundAlt: "#f1f3f7",
    backgroundAltLighter: "#f8f9fd",
    tableBorder: "#ccc7d1",
    tableBorderLight: "#ccc7d180",
    primary: "#ac1b4c",
    secondary: "#19A0D5",

    shadow: {
      button: "#00000080",
      panel: "#00000080",
    },

    status: {
      good: "#36F749a0",
      warning: "#F5C64Ea0",
      bad: "#f64c439f",
      unknown: "#ccc7d180",
    },
  },

  sizes: {
    spacing: {
      block: "1rem",
      contentBottom: { l: "30px", s: "15px" },
      contentSides: { l: "30px", s: "15px" },
    },
    shadow: {
      button: "0px 0.5px 3px -1px",
      panel: "0px 1px 6px -3px",
    },
    borderRadius: {
      panel: 24,
    },

    frontPage: {
      padding: {
        horizontal: {
          s: "20px",
          l: "100px",
        },
        vertical: {
          s: "10px",
          sExtra: "40px",
          l: "100px",
          llow: "50px",
        },
      }
    },

    breakpoints: {
      lowPage: "(min-width: 960px) and (max-height: 650px)",
      sm: { down: "(max-width: 660px)", up: "(min-width: 661px)" },
      md: { down: "(max-width: 960px)" },
    },
  },

  typography: {
    fontFamily: "OpenSans",
    fontFamilyHeaders: "Montserrat",
    fontSizes: {
      h2: "2rem",
      h3: "1.6rem",
      h4: "1.2rem",
    }
  },
}

export const theme = createTheme({
    typography: {
        fontFamily: ThemeSettings.typography.fontFamily,
        h2: { fontSize: ThemeSettings.typography.fontSizes.h2, fontFamily: ThemeSettings.typography.fontFamilyHeaders, fontWeight: 600 },
        h3: { fontSize: ThemeSettings.typography.fontSizes.h3, fontFamily: ThemeSettings.typography.fontFamilyHeaders, fontWeight: 600 },
        h4: { fontSize: ThemeSettings.typography.fontSizes.h4, fontFamily: ThemeSettings.typography.fontFamilyHeaders, fontWeight: 600 },
    },
    palette: {
        primary: {
            main: ThemeSettings.colors.primary,
        },
        secondary: {
            main: ThemeSettings.colors.secondary,
        },
    },
    shape: {
      borderRadius: 24,
    },
    components: {
      ...BaseMuiThemeComponentsSettings,
      MuiButton: {
        ...(BaseMuiThemeComponentsSettings?.MuiButton || {}),
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "primary",
        }
      }
    },
});
