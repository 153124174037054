import { useMemo, useState } from "react";
import { usePlatformPage } from "../Common/data/usePlatformPage";
import { getCustomBlocksData } from "../../toolympus/components/CMS/Usage";
import { PanelBlockElementType } from "../../toolympus/components/PowerDoc/plugins/Panel/PanelBlockElement";
import { CrudItemData, useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { Arbitrator, ConflictComment, Degree } from "./types";
import { apiFetch, apiUploadFile } from "../../toolympus/api/core";
import { useAction } from "../../toolympus/api/useAction";
import { FileInfo } from "../../toolympus/components/files";
import { ValidationErrors } from "../../toolympus/components/schemed";
import { TabStatus } from "../Common/TabButtons";
import { useEditArray } from "../../toolympus/hooks/useEditArray";
import { generateCode } from "../../toolympus/components/PowerDoc/plugins/common";


const getProfileTabsStatus = (profile: Arbitrator, errors?: ValidationErrors): Record<string, TabStatus> => {
  const result: Record<string, TabStatus> = {};
  const errorsStatus: Record<string, TabStatus> = {};
  
  if(errors?.hasErrors) {
    if(
      errors?.fieldHasErrors("title")
      || errors?.fieldHasErrors("first_name")
      || errors?.fieldHasErrors("last_name")
      || errors?.fieldHasErrors("email")
      || errors?.fieldHasErrors("email_backup")
      || errors?.fieldHasErrors("pd_consent")
      || errors?.fieldHasErrors("phone")
      || errors?.fieldHasErrors("organization")
      || errors?.fieldHasErrors("job_title")
      || errors?.getInnerErrors(["degrees"])?.hasErrors) {
      errorsStatus["personal_info"] = "error";
    }
    if(errors?.getInnerErrors(["participation_jessup_national"])?.hasErrors
      || errors?.getInnerErrors(["participation_jessup_international"])?.hasErrors
      || errors?.getInnerErrors(["participation_other"])?.hasErrors) {
      errorsStatus["experience"] = "error";
    }
    if(errors?.getInnerErrors(["conflict_comment"])?.hasErrors) {
      errorsStatus["conflicts"] = "error";
    }
    if(errors?.fieldHasErrors("timezone")
      || errors?.fieldHasErrors("judging_format")
      || errors?.fieldHasErrors("rounds_number_accepted")
      || errors?.fieldHasErrors("short_notice_availability")
      || errors?.fieldHasErrors("advanced_rounds_short_notice_availability")
      || errors?.fieldHasErrors("timeslots_preference")
      || errors?.fieldHasErrors("advanced_rounds_only_preferred")
      || errors?.fieldHasErrors("advanced_rounds_timeslots_preference")
      ) {
      errorsStatus["availability"] = "error";
    }
  }
  
  if(profile.is_registration_complete) {
    return {
      personal_info: "complete",
      availability: "complete",
      ...errorsStatus,
    };
  }

  const status = profile.status_info;

  if(!status) {
    return errorsStatus;
  }

  result["personal_info"] = (
    status.title
    || status.first_name
    || status.last_name
    || status.email
    || status.pd_consent
    || status.phone
    || status.organization
    || status.job_title
    || status.degrees) 
    ? "outstanding"
    : "complete";
  
  result["availability"] = (
    status.timezone
    || status.judging_format
    || status.rounds_number_accepted
    || status.short_notice_availability
    || status.advanced_rounds_short_notice_availability
    || status.timeslots_preference
    || status.advanced_rounds_only_preferred
    || status.advanced_rounds_timeslots_preference) 
    ? "outstanding"
    : "complete";
  
  
  return {
    ...result,
    ...errorsStatus,
  };
}

export const useArbitratorProfilePageConfig = () => {
  const pageInfo = usePlatformPage("arbitrator-profile-page");
  const config = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);

    const guidePanel = ((pageInfo.data.item.content as any)?.blocks || []).find((b: any) => b.type === PanelBlockElementType && b.display_type === "guide");
    const guideContent = guidePanel && guidePanel.content;

    const messages = (pageData["page-messages"] || {});
    const availabilityMessages = (pageData["availability-messages"] || {});

    const tabsOrder = (messages.tabs_order || "").split(",").map(s => s.trim());
    const tabs = (tabsOrder.length ? tabsOrder : ["personal_info", "experience", "conflicts", "availability"])
      .map(t => [t, messages[`tab_${t}`]] as [string,string]);

    return {
      messages,
      availabilityMessages,
      guideContent,
      tabs,
    };
  }, [pageInfo.data]);

  return {
    config,
    isLoading: pageInfo.isLoading,
    state: pageInfo.data.contest_state,
    pageInfo,
  };
}


const ApiPath = "/api/arbitrator/profile";


export const useArbitratorProfileDetailsEdit = (data: Pick<CrudItemData<Arbitrator>, "data" | "update">) => {
  const degrees = useEditArray<Degree>({
    dflt: {},
    createDflt: () => ({ _id: generateCode() }),
    items: data.data.degrees || [{ _id: generateCode() }],
    update: v => data.update({ degrees: v }),
  });

  const conflicts = useEditArray<ConflictComment>({
    dflt: {},
    createDflt: () => ({ _id: generateCode() }),
    items: data.data.conflict_comment || [{ _id: generateCode() }],
    update: v => data.update({ conflict_comment: v }),
  });

  const participationJessupInternational = useEditArray<Degree>({
    dflt: {},
    createDflt: () => ({ _id: generateCode() }),
    items: data.data.participation_jessup_international || [{ _id: generateCode() }],
    update: v => data.update({ participation_jessup_international: v }),
  });

  const participationJessupNational = useEditArray<Degree>({
    dflt: {},
    createDflt: () => ({ _id: generateCode() }),
    items: data.data.participation_jessup_national || [{ _id: generateCode() }],
    update: v => data.update({ participation_jessup_national: v }),
  });

  const participationOther = useEditArray<Degree>({
    dflt: {},
    createDflt: () => ({ _id: generateCode() }),
    items: data.data.participation_other || [{ _id: generateCode() }],
    update: v => data.update({ participation_other: v }),
  });

  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false);
  const uploadAvatar = (file: File): Promise<FileInfo> => {
    setIsAvatarUploading(true);
    return apiUploadFile("/api/arbitrator/avatar", "post", "file", file)
      .then(data => {
        setIsAvatarUploading(false);
        return data.data;
      })
      .catch(e => {
        setIsAvatarUploading(false);
        throw e;
      })
  }
  const avatar = {
    isAvatarUploading,
    uploadAvatar,
  }

  return {
    degrees,
    conflicts,

    participationJessupInternational,
    participationJessupNational,
    participationOther,
    avatar,
  }
}

export const useArbitratorProfile = () => {
  const pageConfig = useArbitratorProfilePageConfig();
  const data = useCrudItem<Arbitrator>(ApiPath, {
    defaultValue: {
      _id: "",
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      title: "",
    },
  });

  const detailsEdit = useArbitratorProfileDetailsEdit(data);



  const profileStatus = useMemo(
    () => getProfileTabsStatus(data.data, data.errors),
    [data.data, data.errors]);

  const resendConfirmationEmail = useAction(() => apiFetch<{}>("/api/arbitrator/resend-email-confirmation", "POST"));


  const isProfileLoaded = !!data.data._id;

  const canEditProfile = !!pageConfig.state.arbitrator_profile_edit_allowed;


  return {
    pageConfig,

    ...data,
    ...detailsEdit,
    isProfileLoaded,
    canEditProfile,
    profileStatus,
    resendConfirmationEmail,
  }
}

export type ArbitratorProfileData = ReturnType<typeof useArbitratorProfile>;

