import React from 'react';
import styled from '@emotion/styled';
import { ArbitratorProfileData } from '../useArbitratorProfile';
import { Buttons, FormGrid, InputIconButton, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { Arbitrator } from '../types';
import { Add, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { RadioCheckbox } from '../../Common/RadioCheckbox';
import { AvatarEditor } from '../../Teams/TeamProfile/AvatarEditor';
import { ThemeSettings } from '../../../theme';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { ProfileSectionTitle } from '../../Common/styles';

interface Props {
  data: ArbitratorProfileData;
  startChangeEmail?: (a: Arbitrator) => void;
  canEditProfile?: boolean;
}

const OuterGrid = styled(FormGrid)`
  & .img-wrapper, & .avatar-placeholder, & .file-editor {
    width: 150px;
    height: 150px;

    & .placeholder {
      height: 100%;
    }

    @media ${ThemeSettings.sizes.breakpoints.sm.down} {
      justify-self: center;
    }
  }

  & .img-wrapper {
    border-radius: 75px;
    overflow: hidden;
    box-shadow: ${ThemeSettings.sizes.shadow.button} ${ThemeSettings.colors.shadow.button};

    & img {
      object-position: center center;
    }
  }
`;

const DegreeItem = styled(FormGrid)`
  padding-left: 20px;
  border-left: 2px solid ${ThemeSettings.colors.tableBorder};

  ${ProfileSectionTitle} + & {
    margin-top: 0.5rem;
  }
`;
DegreeItem.defaultProps = { columns: "150px 1fr", gap: "dense" };

export const ArbitratorPersonalInfoForm = (props: Props) => {
  const { data, update, errors } = props.data;

  return (
    <>
      <OuterGrid columns="1fr 150px">
        <FormGrid noMargin suppressContentColumnsOnSm>
          <FormControlsForFields
            data={data}
            onChange={(o,c) => update(c)}
            schema={{}}
            errors={errors}
            readOnly={!props.canEditProfile}
            fields={[
              ["title", { label: "Title" }],
              ["first_name", { label: "First name", controlProps: { required: true } }],
              ["last_name", { label: "Last name", controlProps: { required: true } }],
              ["email", {
                label: "Email",
                readOnly: true,
                controlProps: {
                  required: true,
                  endAdornment: props.startChangeEmail
                  ? <InputAdornment position="end"><IconButton size="small" onClick={() => props.startChangeEmail && props.startChangeEmail(data)}><EditOutlined /></IconButton></InputAdornment>
                  : null
                }
              }],
              ["email_backup", { label: "Email backup" }],
              ["phone", { label: "Phone", controlProps: { required: true } }],
              ["organization", { label: "Organisation", controlProps: { required: true } }],
              ["job_title", { label: "Job title", controlProps: { required: true } }],
            ]}
            />

          <Typography variant="caption" style={{ gridColumn: "1 / span 3"}}>
            If you need to change your primary (login) email, please contact the organizer.
          </Typography>

          <RadioCheckbox
            label={props.data.pageConfig.pageInfo.data?.messages?.arbitrators?.registration?.pdc}
            value={data.pd_consent}
            update={v => {}}
            trueOnly
            wrapperStyle={{ gridColumn: "1 / span 3" }}
            />

        </FormGrid>

        <AvatarEditor
          avatar_url={data.avatar_url}
          readOnly={!props.canEditProfile}
          update={v => update({ avatar_url: v })}
          upload={props.data.avatar?.uploadAvatar}
          isUploading={props.data.avatar.isAvatarUploading}
          />

      </OuterGrid>

      <ProfileSectionTitle>Law degree(s)</ProfileSectionTitle>

      {(props.data.degrees || []).items.map((degree,idx) => (
        <DegreeItem key={degree._id}>
          <FormControlsForFields
            data={degree}
            onChange={(o,c) => props.data.degrees.update(idx, c)}
            schema={{}}
            readOnly={!props.canEditProfile}
            errors={errors?.getInnerErrors(["degrees", idx])}
            fields={[
              ["graduation_year", { label: "Graduation year", controlProps: { required: true }, }],
              ["university", {
                label: "University",
                controlProps: {
                  required: true,
                  endAdornment: <InputIconButton icon={<DeleteOutlined />} action={props.canEditProfile ? () => props.data.degrees.remove(idx) : undefined} />,
                },
              }],
            ]}
            />

          <FormControlsForFields
            data={degree}
            onChange={(o,c) => props.data.degrees.update(idx, c)}
            schema={{ specialization_pil: { type: FieldType.textlong }, specialization_other: { type: FieldType.textlong }}}
            readOnly={!props.canEditProfile}
            errors={errors?.getInnerErrors(["degrees", idx])}
            fields={[
              ["specialization_pil", {
                label: "Specialization: Public International Law or a related field",
                hint: "please indicate: e.g., Human Rights Law, Law of the Sea, etc.",
                autoRows: true,
                controlProps: { required: true },
                wrapperStyle: { gridColumn: "1 / span 2" },
              }],
              ["specialization_other", {
                label: "Specialization: other",
                hint: "please indicate",
                autoRows: true,
                controlProps: { required: true },
                wrapperStyle: { gridColumn: "1 / span 2" },
              }],
            ]}
            />
          
        </DegreeItem>
      ))}

      {props.canEditProfile && 
        <Buttons>
          {props.data.degrees.items.length > 0 && <OccupyFreeSpace />}
          <Button color="primary" onClick={() => props.data.degrees.add()} startIcon={<Add />}>add a degree</Button>
        </Buttons>}
      
    </>
  );
}
