import { useMemo } from "react";
import { CMSRecord } from "../../../toolympus/components/CMS";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { ContestState } from "../../ContestControl/useContestStateEdit";
import { CustomElement } from "../../../slate";
import { PanelBlockElementType } from "../../../toolympus/components/PowerDoc/plugins/Panel/PanelBlockElement";

export interface PlatformPageData {
  item: CMSRecord;
  contest_state: Partial<ContestState>;
  messages: Record<string, any>;
}

export const EmptyPlatformPageData: PlatformPageData = { item: { _id: -1, title: "", created_datetime: "", record_type: "", }, contest_state: {}, messages: {}};

export const usePlatformPage = (slug: string) => {
  const data = useLoadedData<PlatformPageData>(
    `/api/public/cms/platform_page/${slug}`,
    EmptyPlatformPageData);

  return {
    ...data,
    page: data.data.item,
    state: data.data.contest_state,
  }
}

export const usePlatformPageWithPanels = (slug: string) => {
  const data = usePlatformPage(slug);

  const [pageFiltered,messages] = useMemo(() => {
    const pageBlocks = (data.page.content as { blocks: CustomElement[] })?.blocks || [];
    const messages: CustomElement[] = pageBlocks.filter(b => b.type === PanelBlockElementType && (b as any).display_type === "message");

    if(!messages.length) {
      return [data.page, messages];
    }

    return [
      {
        ...data.page,
        content: { blocks: pageBlocks.filter(b => !messages.includes(b)) },
      },
      messages,
    ]

  }, [data.page]);

  return {
    ...data,
    page: pageFiltered,
    messages,
  };
}
