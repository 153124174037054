import React from 'react';
import { useTeamRoundData } from './useParticipantRoundData';
import { ParticipantRoundPage } from './ParticipantRoundPage';
import { useParams } from 'react-router-dom';

interface Props {
  
}

export const TeamRoundPage = (props: Props) => {
  const { roundid } = useParams<{ roundid: string }>();
  const data = useTeamRoundData(roundid);
  return (
    <ParticipantRoundPage data={data} isTeam />
  );
}
