import { useMemo } from "react";
import { Round } from "../../../toolympus/components/Contests/Grid/types";
import { LoadedData } from "../../../toolympus/hooks/useLoadedData"
import { RoundInfo, TeamTiming } from "../useRoundInfoEdit"
import { useUser } from "../../../toolympus/userContext/UserContext";
import { apiFetch } from "../../../toolympus/api/core";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { FieldType, Schema, mergeSchema } from "../../../toolympus/hooks/useSchema";
import { toMap } from "../../../toolympus/api/data";
import { Person } from "../../Teams/types";


const TimingSchema: Schema = {
  time: { label: "Time", type: FieldType.number },
  speaker_id: { label: "Speaker", type: FieldType.select },
}

const DefaultTiming: TeamTiming = {
  rebuttal: {  },
  speaker_1: {  },
  speaker_2: {  },
}

const EmptyO = {};
const EmptyA: Person[] = [];

export const useTeamsTiming = (roundInfo: LoadedData<RoundInfo>, round: Round) => {
  const { user } = useUser();

  const ownTiming = (roundInfo.data.teams_timing || {})[user?._id || ""];

  const [position] = Object.entries(round.players || {}).find(([pos,team]) => team._id === user?._id) || [];
  const members: Person[] = position ? (round.info?.members || EmptyO)[position] : EmptyA;

  const [membersNameMap,schema] = useMemo(() => {
    const membersNameMap = toMap(members, m => m._id || "", m => `${m.first_name} ${m.last_name}`);
    const membersVals = members.map(m => ({ value: m._id || "", label: membersNameMap[m._id || ""] }));

    return [
      membersNameMap,
      mergeSchema(TimingSchema, { speaker_id: { values: membersVals, valueDict: membersNameMap }}),
    ]
  }, [members]);

  const edit = useEditItem2<TeamTiming>({
    save: (item, changes) => {
      return apiFetch<TeamTiming>(`/api/rounds-info/participant/${round.stage_code}/${round._id}/timing`, "put", item);
    }
  });

  const update = (changes: Partial<TeamTiming>) => {
    const changesUpdated = { ...changes };
    if(changesUpdated.speaker_1) {
      changesUpdated.speaker_1.speaker_name = changesUpdated.speaker_1.speaker_id ? membersNameMap[changesUpdated.speaker_1.speaker_id] : "";
    }
    if(changesUpdated.speaker_2) {
      changesUpdated.speaker_2.speaker_name = changesUpdated.speaker_2.speaker_id ? membersNameMap[changesUpdated.speaker_2.speaker_id] : "";
    }
    edit.update(changesUpdated);
  }

  return {
    schema,
    ...edit,
    save: (extraChanges?: Partial<TeamTiming>) => edit.save(extraChanges).then(x => { roundInfo.reload(); return x; }),
    update,
    startEditingX: position ? () => edit.startEditing(ownTiming || DefaultTiming) : undefined,
    isNeedToProvideTiming: position && !ownTiming,
    position,
  }
}

export type TeamsTimingData = ReturnType<typeof useTeamsTiming>;



export const useBailiffTeamTiming = (roundInfo: LoadedData<RoundInfo>, round: Round) => {
  const edit = useEditItem2<TeamTiming & { teamId?: string }>({
    save: (item, changes) => {
      const data = { ...item };
      delete data.teamId;
      return apiFetch<TeamTiming>(`/api/rounds-info/participant/${round.stage_code}/${round._id}/timing/${item.teamId}`, "put", data);
    }
  });

  const [position] = Object.entries(round.players || {}).find(([pos,team]) => team._id === edit.item?.teamId) || [];
  const members: Person[] = position ? (round.info?.members || EmptyO)[position] : EmptyA;

  const [membersNameMap,schema] = useMemo(() => {
    const membersNameMap = toMap(members, m => m._id || "", m => `${m.first_name} ${m.last_name}`);
    const membersVals = members.map(m => ({ value: m._id || "", label: membersNameMap[m._id || ""] }));

    return [
      membersNameMap,
      mergeSchema(TimingSchema, { speaker_id: { values: membersVals, valueDict: membersNameMap }}),
    ]
  }, [members]);

  const update = (changes: Partial<TeamTiming>) => {
    const changesUpdated = { ...changes };
    if(changesUpdated.speaker_1) {
      changesUpdated.speaker_1.speaker_name = changesUpdated.speaker_1.speaker_id ? membersNameMap[changesUpdated.speaker_1.speaker_id] : "";
    }
    if(changesUpdated.speaker_2) {
      changesUpdated.speaker_2.speaker_name = changesUpdated.speaker_2.speaker_id ? membersNameMap[changesUpdated.speaker_2.speaker_id] : "";
    }
    edit.update(changesUpdated);
  }


  return {
    schema,
    ...edit,
    save: (extraChanges?: Partial<TeamTiming>) => edit.save(extraChanges).then(x => { roundInfo.reload(); return x; }),
    startEditingX: (teamId: string) => {
      const theirTiming = (roundInfo.data.teams_timing || {})[teamId || ""] || DefaultTiming;
      edit.startEditing({ ...theirTiming, teamId });
    },
    update,
    position,
  }
}

export type BailiffTeamTimingData = ReturnType<typeof useBailiffTeamTiming>;
