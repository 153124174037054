import React from 'react';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { CourtRoom } from './CourtRoom';
import { useCourtRoomLobby } from './useCourtRoom';
import { Buttons, FormGrid, LoadingIndicator } from '../../../../toolympus/components/primitives';
import { BiCrown, BiNetworkChart } from 'react-icons/bi';
import { BaseBiIconSmallProps } from '../../../Common/Icons';
import { Link } from 'react-router-dom';
import { CourtRoomPanel } from './CourtRoomPanel';
import { PagePanel } from '../../../Common/PageHarness';
import { ThemeSettings } from '../../../../theme';

interface Props {
  stage?: string;
  roundId?: string;
  isDeliberationsAvailable?: boolean;
  messages: Record<string, string>;
  userName?: string;
}

export interface RoomInfo {
  is_available?: boolean;
  domain?: string;
}

const RoomButtons = styled(Buttons)`
  justify-content: center;

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    flex-flow: column;
    align-items: center;
  }
`;

const UndecoratedLink = styled(Link)`
  text-decoration: none;
  border-bottom: none !important;
`;

export const CourtRoomLobby = (props: Props) => {
  const { data, isLoading, isStarted } = useCourtRoomLobby(props.stage, props.roundId);

  const roomLinkBase = `/court-room?s=${props.stage}&r=${props.roundId}&n=${props.userName}&d=${data.domain}`

  if(isLoading) {
    return <LoadingIndicator />
  }

  return <>
    {!data.is_available && <Typography style={{ whiteSpace: "pre-line"}}>{props.messages?.court_room_unavailable}</Typography>}
    {!!data.is_available && (
        isStarted
          ? <CourtRoom
              roundId={props.roundId}
              stage={props.stage}
              domain={data.domain}
              kind={isStarted}
              />
          : <>
              <RoomButtons>
                <UndecoratedLink to={`${roomLinkBase}&k=round`} target="_blank">
                  <Button
                    color="primary"
                    startIcon={<BiNetworkChart {...BaseBiIconSmallProps} />}>
                      {props.messages.court_room_enter_court_label || "enter court room"}
                  </Button>
                </UndecoratedLink>
                {props.isDeliberationsAvailable &&
                  <UndecoratedLink to={`${roomLinkBase}&k=deliberations`} target="_blank">
                    <Button
                      color="primary"
                      startIcon={<BiCrown {...BaseBiIconSmallProps} />}>
                        {props.messages.court_room_enter_deliberations_label || "enter deliberations room"}
                    </Button>
                  </UndecoratedLink>}
              </RoomButtons>
              {!!data.is_available && <Typography style={{ whiteSpace: "pre-line"}}>{props.messages?.court_room_guide}</Typography>}
              {!!data.is_available && props.isDeliberationsAvailable && <Typography style={{ whiteSpace: "pre-line"}}>{props.messages?.court_room_deliberations_guide}</Typography>}

          </>)}
  </>
}

const CourtRoomEntranceWrapper = styled(FormGrid)`
  text-align: center;
  align-items: center;

  & .watermark {
    opacity: 0;
  }
`;
CourtRoomEntranceWrapper.defaultProps = { columns: "1fr", noMargin: true };


export const CourtRoomLobbyInside = (props: Props) => {
  const { data, isLoading, isStarted, join, leave } = useCourtRoomLobby(props.stage, props.roundId);


  if(isLoading) {
    return <LoadingIndicator />
  }

  if(!!data.is_available && !!isStarted) {
    return (
      <CourtRoomPanel
        roundId={props.roundId}
        stage={props.stage}
        domain={data.domain}
        name={props.userName || ""}
        kind={isStarted}
        onDisconnect={() => leave()}
        wrapEditorWithPanel
        />
    )
  }

  return (
    <PagePanel>
      <CourtRoomEntranceWrapper>
        {!data.is_available &&
          <Typography style={{ whiteSpace: "pre-line"}}>{props.messages?.court_room_unavailable}</Typography>}
        {!!data.is_available &&
          <>
            <RoomButtons>
              <Button
                color="primary"
                onClick={() => join("round")}
                startIcon={<BiNetworkChart {...BaseBiIconSmallProps} />}>
                  {props.messages.court_room_enter_court_label || "enter court room"}
              </Button>
              {props.isDeliberationsAvailable &&
                <Button
                  color="primary"
                  onClick={() => join("deliberations")}
                  startIcon={<BiCrown {...BaseBiIconSmallProps} />}>
                    {props.messages.court_room_enter_deliberations_label || "enter deliberations room"}
                </Button>}
            </RoomButtons>
            
            <Typography style={{ whiteSpace: "pre-line"}}>{props.messages?.court_room_guide}</Typography>
            {props.isDeliberationsAvailable && <Typography style={{ whiteSpace: "pre-line"}}>{props.messages?.court_room_deliberations_guide}</Typography>}
          </>}
      </CourtRoomEntranceWrapper>
    </PagePanel>
  )
}
