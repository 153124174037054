import React, { ReactNode, useCallback, useState } from 'react';
import { AutoexpanderDefault, Buttons, DeleteButton, Dialog, FormGrid, InputIconButton, PseudoLink2, SearchField, SimpleDialog, useCopyText, useWindowHotkey } from '../../toolympus/components/primitives';
import { Contact, CrmContactListData, useCrmContactList } from './useCrmContactList';
import { FieldElementRenderer, FieldSettingsPopupButton, FormControlsForFields, TableForFields, useFields } from '../../toolympus/components/schemed';
import { EditItemProps, NewItemProps } from '../../toolympus/api/useNewItem';
import { Schema } from '../../toolympus/hooks/useSchema';
import { Button, IconButton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Add, Check, CloudUploadOutlined, FileCopyOutlined, FilterList } from '@mui/icons-material';
import { openFileUploader } from '../../toolympus/api/files';
import { Select } from '../../toolympus/components/schemed/Select';
import { TagDisplay, TagsPicker, TagsPickerWrapper } from './TagsPicker';
import { SettingsData } from '../../toolympus/api/useSettings';
import { CrmSettings } from './useCrmSettings';
import { PageHeader } from '../Common/PageHeader';
import { PagePanel } from '../Common/PageHarness';
import { RankStars } from '../Arbitrators/AdminList/RankStars';

export const CrmContactList = () => {
  const data = useCrmContactList();
  const [isTagsFilterVisible, setIsTagsFilterVisible] = useState<boolean>(false);

  useWindowHotkey("alt+n", () => data.create.startEditing());

  const fields = useFields({
    schema: data.schema,
    defaultFields: [
      "email",
      "name",
      "company",
      "position",
      "university",
      "created_at",
      "tags",
    ],
    extraSettings: {
      created_at: { utcToLocal: true },
    },
    storageKey: "jessup_crm_contacts_fields",
  });

  const fieldElement = useCallback((f: string): FieldElementRenderer<Contact> => {
    switch(f) {
      case "email":
      case "name":
        return (row,s,orig) => <PseudoLink2 onClick={() => data.edit.startEditing({...row})}>{orig}</PseudoLink2>
      case "tags":
        return (row) => <TagsPicker
          value={row.tags}
          availableTags={data.tags.available}
          update={() => {}}
          disabled
          onClick={() => data.edit.startEditing({...row})}
          settings={data.settings}
          />
      case "rank":
        return (row,s,orig) => <RankStars value={row.rank} update={r => data.updateRank(row._id, r)} />
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.tags.available, data.settings.settings]);

  return (<>
    <PageHeader
      title="Contacts"
      isLoading={data.isLoading}
      actionsStart={<>
        <IconButton color='primary' size="small" onClick={() => data.create.startEditing()}><Add /></IconButton>
        <Button
          startIcon={<CloudUploadOutlined />}
          color="primary"
          size="small"
          onClick={() => data.upload.startEditing({ items: [], existing: "ignore" })}>
          upload
        </Button>
      </>}
      actions={<>
        <SearchField
          autoFocus
          small
          noButton
          {...data.filter}
          />
        <IconButton size="small" color={data.tagsFilter.length > 0 ? "primary" : undefined} onClick={() => setIsTagsFilterVisible(x => !x)}><FilterList /></IconButton>
        <FieldSettingsPopupButton fieldsSettings={fields} />
      </>}
      >

      {isTagsFilterVisible &&
        <TagsPickerWrapper>
          {data.tags.available.map(t =>
            <TagDisplay
              tag={t}
              settings={data.settings.settings.tags}
              icon={data.tagsFilter.includes(t) ? <Check /> : undefined}
              editTag={t => data.setTagsFilter(active => active.includes(t) ? active.filter(tx => tx !== t) : [...active, t])}
              />)}
        </TagsPickerWrapper>}
    </PageHeader>

    <PagePanel fillPage noTableStyle noPad fixMaxHeight>
      <TableForFields
        data={data.data}
        fields={fields.activeFields}
        schema={data.schema}
        autoexpander={AutoexpanderDefault}
        fieldElement={fieldElement}
        />
    </PagePanel>

    <EditContactDialog
      data={data.create}
      mode="create"
      schema={data.schema}
      title="Add contact"
      tags={data.tags}
      settings={data.settings}
      />

    <EditContactDialog
      data={data.edit}
      mode="edit"
      schema={data.schema}
      title="Edit contact"
      remove={data.remove}
      tags={data.tags}
      settings={data.settings}
      />

    <SimpleDialog
      isOpen={data.upload.isEditing}
      close={data.upload.cancel}
      dialogTitle="Upload contacts"
      save={() => data.upload.save()}
      saveLabel="Create"
      noSubmitOnEnter
      noFullscreen
      >
      {data.upload.item && <FormGrid columns="1fr" noMargin>
        {!!data.upload.item?.items?.length &&
          <Typography>{data.upload.item?.items?.length} contacts will be added.</Typography>}

        <Buttons>
          <Button
            startIcon={<CloudUploadOutlined />}
            color="primary"
            size="small"
            onClick={() => openFileUploader(f => data.upload.readFromFile(f))}>
            upload
          </Button>
          <Button onClick={() => data.upload.downloadExample()} size="small">
            download example
          </Button>
        </Buttons>

        <Select
          field="existing"
          row={data.upload.item}
          schema={{
            label: "Existing records mode",
            values: [{ value: "ignore", label: "Ignore" }, { value: "update", label: "Update" }],
            valueDict: { ignore: "Ignore", update: "Update" }
          }}
          onChange={(o,c) => data.upload.update(c)}
          />
      </FormGrid>}
    </SimpleDialog>
    
  </>);
}


interface EditDialogProps {
  title: ReactNode;
  mode: "create" | "edit";
  data: EditItemProps<Contact> | NewItemProps<Partial<Contact>, Contact>;
  schema: Schema;
  remove?: (id: string) => Promise<any>;
  tags: CrmContactListData["tags"];
  settings: SettingsData<CrmSettings>
}

const EditContactDialog = (props: EditDialogProps) => {
  const { data, title, mode, schema, remove } = props;
  const copyText = useCopyText();
  return (
    <Dialog
      dialogTitle={title}
      isOpen={data.isEditing}
      close={() => data.cancel()}
      titleActions={<>
        <RankStars
          value={data.item?.rank}
          update={rank => data.update({ rank })}
          />
        {remove &&
          <DeleteButton
            remove={() => { data.cancel(); return remove(data.item?._id || "")}}
            title="Delete contact?"
            preventGoBack
            size="small"
          />}
      </>}
      actions={<>
        <Button onClick={() => data.cancel()}><FormattedMessage id="common.cancel" /></Button>
        <Button color="primary" variant="contained" onClick={() => data.save()}><FormattedMessage id="common.save" /></Button>
      </>}
      noSubmitOnEnter
      noFullscreen>
      {data.item && <>
        {mode === "edit" && <FormGrid columns="1fr 1fr" noMargin>
          <FormControlsForFields
            data={data.item}
            schema={schema}
            fields={[
              ["_id", { disabled: true }],
              ["created_at", { disabled: true, utcToLocal: true }],
            ]}
            onChange={(o,c) => data.update(c)}
            />
        </FormGrid>}
        <FormGrid columns="1fr" noMargin>
          <Typography variant="caption" color="textSecondary">Tags</Typography>
          <TagsPicker
            value={data.item.tags}
            availableTags={props.tags.available}
            update={tags => data.update({ tags })}
            settings={props.settings}
            />
          <FormControlsForFields
            data={data.item}
            schema={schema}
            fields={[
              ["email", { controlProps: { endAdornment: <InputIconButton icon={<FileCopyOutlined />} action={() => copyText(data.item?.email || "")} />}}],
              ["name"],
            ]}
            onChange={(o,c) => data.update(c)}
            />
        </FormGrid>
        <FormGrid columns="1fr 1fr" noMargin>
          <FormControlsForFields
            data={data.item}
            schema={schema}
            fields={[
              ["company"],
              ["position"],
            ]}
            onChange={(o,c) => data.update(c)}
            />
        </FormGrid>
        <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            data={data.item}
            schema={schema}
            fields={[
              ["university"],
              ["comment", { autoRows: true }],
            ]}
            onChange={(o,c) => data.update(c)}
            />
        </FormGrid>
      </>}
    </Dialog>
  )
}
