import React from 'react';
import styled from '@emotion/styled';
import { useTeamLegacy } from './useTeamLegacy';
import { PagePanel } from '../../Common/PageHarness';
import { PageHeader } from '../../Common/PageHeader';
import { PageViewer } from '../../Common/PageViewer';
import { LegacyContentDisplay } from './LegacyContent/LegacyContentDisplay';
import { Typography } from '@mui/material';
import { ContentMessagePanel } from '../../Common';

interface Props {
  
}


const YearPanel = styled(PagePanel)`
  & > h3:first-child {
    color: ${props => props.theme.palette.primary.main};
  }
`;

export const TeamLegacyPage = (props: Props) => {
  const data = useTeamLegacy();

  return (
    <>
      <PageHeader title={data.pageConfig.pageInfo.page.title} isLoading={data.isLoading} />
      
      {!data.isLoading && !data.data.length && data.pageConfig.config.noDataPanel &&
        <ContentMessagePanel key={`${data.pageConfig.pageInfo.page._id}`} block={data.pageConfig.config.noDataPanel} />}

      {!!data.data.length && <>
        
          <PagePanel>
            <PageViewer content={data.pageConfig.config.filteredContent} key={data.pageConfig.pageInfo.page._id} />
          
          </PagePanel>

          {data.data.map(yearLegacy => (
            <YearPanel key={yearLegacy.year}>
              {yearLegacy.year && yearLegacy.year.trim().toLowerCase() !== "general" && <Typography variant="h3">{yearLegacy.year}</Typography>}
              {yearLegacy.blocks.map(legacy => (
                <LegacyContentDisplay block={legacy} messages={data.pageConfig.config.messages} key={legacy._id} />
              ))}
            </YearPanel>
          ))}
        </>}
    </>
  );
}
