import React, { ReactNode } from 'react';
import { Arbiter, Player } from "../../../toolympus/components/Contests/Grid/types";
import { Typography } from '@mui/material';
import { RolesKeys } from '../../Auth';

export const whoAmI = (user: { _id: string | null | undefined } | null, team1: Player | null, team2: Player | null, arbitrators: (Arbiter | null)[], bailiffId: string | undefined) => {
  switch(user?._id) {
    case undefined:
      return "unknown";
    case team1?._id:
      return "team1";
    case team2?._id:
      return "team2";
    case arbitrators[0]?._id:
      return "arbitrator1";
    case arbitrators[1]?._id:
      return "arbitrator2";
    case arbitrators[2]?._id:
      return "arbitrator3";
    case bailiffId:
      return "bailiff";
    default:
      return "unknown";
  }
}

export type RoundRole = ReturnType<typeof whoAmI>;



export const getUserPrimaryRole = (user: { roles: string[] } | undefined | null) => {
  const roles = user?.roles || [];
  if(roles.includes(RolesKeys.team)) {
    return RolesKeys.team;
  } else if(roles.includes(RolesKeys.arbitrator)) {
    return RolesKeys.arbitrator;
  } else if(roles.includes(RolesKeys.bailiff)) {
    return RolesKeys.bailiff;
  } else if(roles.includes(RolesKeys.admin)) {
    return RolesKeys.admin;
  } else {
    return "unknown";
  }
}

export const RoundPanelMessage = (props: { title?: ReactNode, message?: ReactNode }) => {
  return <>
    {props.title && <Typography variant="h4">{props.title}</Typography>}
    {props.message && <Typography style={{ whiteSpace: "pre-line" }}>{props.message}</Typography>}
  </>
}
