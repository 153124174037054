import { useEditItem2, useNewItem } from "../../toolympus/api/useNewItem";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { apiFetch, downloadBuffer } from "../../toolympus/api/core";
import ExcelJS from 'exceljs';
import { useMemo, useState } from "react";
import { useCrmSettings } from "./useCrmSettings";

export interface Contact {
  _id: string;
  email: string;
  name?: string;
  company?: string;
  position?: string;
  university?: string;
  comment?: string;
  created_at: string;
  tags?: string[];
  rank?: number;
}

export type NewContact = Omit<Contact, "_id" | "created_at">;

export const readContactsFromXlsx = (file: File): Promise<NewContact[]> => {
  return new Promise<NewContact[]>((resolve,reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", e => {
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.load(e.target?.result as ArrayBuffer).then(wb => {
        try {
          if(e.target) {
            const sheet = wb.worksheets[0];
            const result: NewContact[] = [];
            sheet.eachRow((row, idx) => {
              if(idx > 1) {
                const item: NewContact = {
                  email: row.getCell(1).text,
                  name: row.getCell(2).text || "",
                  company: row.getCell(3).text || "",
                  position: row.getCell(4).text || "",
                  university: row.getCell(5).text || "",
                  tags: (row.getCell(6).text || "").split(",").map(t => t.trim()).filter(t => !!t),
                  comment: row.getCell(7).text || "",
                };

                result.push(item)
              }
            });
            resolve(result);
          }
        } catch(e) {
          reject(e);
        }});
    });
    reader.readAsArrayBuffer(file);
  });
}

export const getTargetsUploadXlsxExample = () => {
  const workbook = new ExcelJS.Workbook();
    const titleClean = "Example";
    const sheet = workbook.addWorksheet(titleClean);

    sheet.columns = [
        { header: "Email", key: "email" },
        { header: "Name", key: "name" },
        { header: "Company", key: "company" },
        { header: "Position", key: "position" },
        { header: "University", key: "university" },
        { header: "Tags", key: "tags" },
        { header: "Comment", key: "comment" },
    ];
  
    [
      ["example1@example.com", "Name", "ABC Ltd", "CLO", "MSU", "tags, comma, separated", "any private comment; only email is required"],
      ["example2@example.com", "", ""],
    ].forEach(example => {
        sheet.addRow(example)
    });
  
    workbook.xlsx
      .writeBuffer({ base64: true } as any)
      .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `contacts_example.xlsx`));
}

const ApiPath = "/api/crm/contact";

const contactSearchString = (t: Contact) => `${t.email} ${t.name} ${t.company} ${t.university} ${t._id}`

export const useCrmContactList = () => {
  const data = useLoadedData<Contact[]>(ApiPath, []);
  const settings = useCrmSettings();

  const create = useNewItem<NewContact, Contact>(ApiPath, {
    email: "",
  });

  const edit = useEditItem2<Contact>({
    getApiPath: item => `${ApiPath}/${item._id}`,
  });

  const upload = useEditItem2<{ items: NewContact[], existing: "ignore" | "update"}>({
    getApiPath: () => "",
    save: item => {
      return apiFetch(`${ApiPath}/batch?existing=${item.existing}`, "post", { items: item.items });
    }
  })

  const remove = (id: string) => {
    return apiFetch(`${ApiPath}/${id}`, "DELETE")
      .then(() => { data.reload(); return {}; });
  }

  const filter = useTextFilter<Contact>(contactSearchString);

  const [tagsFilter, setTagsFilter] = useState<string[]>([]);

  const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

  const tags = useMemo(() => ({
    available: data.data.reduce<string[]>((r,item) => { (item.tags || []).forEach(t => !r.includes(t) ? r.push(t) : r); return r; }, []),
  }), [data.data]);

  const filtered = useMemo(() => {
    const filteredByText = filter.filterData(data.data);
    if(!tagsFilter.length) {
      return filteredByText;
    }
    const tagsSet = new Set(tagsFilter);
    return filteredByText.filter(c => c.tags?.length && c.tags.find(ct => tagsSet.has(ct)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data, filter.filterData, tagsFilter]);


  const updateRank = (id: string, rank: number) => {
    apiFetch<Contact>(`${ApiPath}/${id}`, "put", { rank })
      .then(() => {
        data.reload();
      });
  }

  return {
    ...data,
    data: filtered,
    schema,
    filter,
    create: {
      ...create,
      save: () => create.save().then(x => { data.reload(); return x; })
    },
    edit: {
      ...edit,
      save: () => edit.save().then(x => { data.reload(); return x; })
    },
    upload: {
      ...upload,
      save: () => upload.save().then(x => { data.reload(); return x; }),
      readFromFile: (f: File) => readContactsFromXlsx(f).then(items => upload.update({ items })),
      downloadExample: getTargetsUploadXlsxExample,
    },
    remove,

    tags,
    settings,

    tagsFilter,
    setTagsFilter,
    updateRank,
  }
}

export type CrmContactListData = ReturnType<typeof useCrmContactList>;
