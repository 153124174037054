import React from 'react';
import styled from '@emotion/styled';
import { LegacyContentProps } from './types';
import { PowerEditorBase } from '../../../../toolympus/components/PowerDoc';
import { FormGrid } from '../../../../toolympus/components/primitives';
import { Typography } from '@mui/material';
import { ThemeSettings } from '../../../../theme';
import { DictionarySelectDisplay } from '../../../../toolympus/components/schemed/Select';

const Wrapper = styled(FormGrid)<{ hasPhoto?: boolean }>`
  align-items: center;
  & > img {
    aspect-ratio: 1;
    width: 100%;
    max-width: 150px;
    border-radius: 50%;
    border: 9px solid ${props => props.theme.palette.primary.main};
    box-shadow: ${ThemeSettings.sizes.shadow.button} ${ThemeSettings.colors.shadow.button};
  }

  & > div > div:last-child > :last-child {
    margin-bottom: 0;
  }

  @media ${ThemeSettings.sizes.breakpoints.sm.up} {
    grid-template-columns: ${props => props.hasPhoto ? "150px 1fr" : "1fr"};
  }
  
  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    grid-template-columns: 1fr;
    & > img {
      justify-self: center;
      margin-bottom: 0.5rem;
    }
  }
`;
Wrapper.defaultProps = { noMargin: true }

export const TitleDisplay = (props: LegacyContentProps) => {
  const hasPhoto = !!props.block.content?.photo_url;

  return (
    <Wrapper columns={hasPhoto ? "150px 1fr" : "1fr"} hasPhoto={hasPhoto}>
      {hasPhoto && <img src={props.block.content?.photo_url} alt={props.block.content?.title} />}
 
      <div>
      <Typography variant="h3">{props.block.content?.title || <DictionarySelectDisplay row={props.block} field="university" schema={{ dictionary: "Universities" }} />}</Typography>

      <PowerEditorBase
        content={props.block.content.tagline}
        update={() => {}}
        viewMode
        placeholder=" "
        />
      </div>
    </Wrapper>
  );
}
