import React, { PropsWithChildren, useState } from 'react';
import { FrontPageWrapper, FrontPageGlobalStyles, FrontPageBackdrop, FrontPageHeaderGradientDecoration, FrontPageTriangle, FrontPageLogoLink, FrontPageLogo, FrontPageMenu, FrontPageMenuItem, FrontPageMenuMobileToggle, FrontPageMenuMobileBackdrop } from './FrontPages';
import { BiMenuAltRight, BiX } from 'react-icons/bi';
import { BaseBiIconProps } from '../Common/Icons';

interface Props {
  menuLinks?: [string,string][];
  wideContent?: boolean;
}

export const FrontPageHarness = (props: PropsWithChildren<Props>) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState<boolean>(false);
  return (
    <FrontPageWrapper mobileVisible={isMobileMenuVisible} wideContent={props.wideContent}>
      <FrontPageGlobalStyles />
      <FrontPageBackdrop />
      <FrontPageHeaderGradientDecoration />
      <FrontPageTriangle />

      <FrontPageMenuMobileBackdrop />
      <FrontPageMenuMobileToggle onClick={() => setIsMobileMenuVisible(x => !x)}>
        {isMobileMenuVisible
          ? <BiX {...BaseBiIconProps} color="#ffffff" style={{ width: 30, height: 30 }} />
          : <BiMenuAltRight {...BaseBiIconProps} color="#ffffff" style={{ width: 30, height: 30 }} />}
      </FrontPageMenuMobileToggle>
      <FrontPageMenu>
        <FrontPageLogoLink>
          <FrontPageLogo />
        </FrontPageLogoLink>

        {(props.menuLinks || []).map(([text,link]) => <FrontPageMenuItem key={link} href={link}>{text}</FrontPageMenuItem>)}
      </FrontPageMenu>

      {props.children}
    </FrontPageWrapper>
  );
}
