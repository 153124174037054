import React from 'react';
import { ScoreRoundData } from '../../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { FormattedMessage } from 'react-intl';
import { ScoredRoundContext } from '../../../toolympus/components/Contests/Grid/Parties/ScoredRoundContext';
import { ScoringSheetForm } from '../../../toolympus/components/Contests/ScoringSheets';
import { SheetTitleRow } from '../../../toolympus/components/Contests/Grid/Parties/ScoreRoundForm';
import { SpeakerSelect, getScoreIdx } from './SpeakerSelect';

interface Props {
  scoreRound?: ScoreRoundData;
}

export const ScorePerformanceDialog = (props: Props) => {
  if(!props.scoreRound) {
    return null;
  }

  const { activeScore, cancelScoring, getPlayer, round, scoring } = props.scoreRound;

  return (
    <Dialog open={!!activeScore} onClose={cancelScoring} maxWidth="md" fullWidth>
      <DialogTitle>
        <SheetTitleRow>
          <span>{activeScore?.position === 1 ? "Applicant" : "Respondent"} {getScoreIdx(activeScore?.score_key)} - {getPlayer(activeScore?.position || 0).display_name}</span>
        
          <OccupyFreeSpace className="free-space" />
        
          <Typography  className="total-score-info">
            <FormattedMessage id="contests.scoring.sheet_numeric_total" values={{ total: scoring.totalNumeric }} />
          </Typography>
        </SheetTitleRow>
      </DialogTitle>
      <DialogContent>
          <ScoredRoundContext.Provider value={{ round: round.data, score: activeScore }}>
              <ScoringSheetForm data={scoring} hideTitle customComponents={{ speaker: SpeakerSelect }} />
          </ScoredRoundContext.Provider>
      </DialogContent>
      </Dialog>
  );

}
