import { useSettings } from "../../toolympus/api/useSettings"
import { useFileEdit } from "../../toolympus/components/files/useFileEdit";
import { usePrepareRoundsFeedback } from "./usePrepareRoundsFeedback";

export interface ContestState {
  team_registration_allowed: boolean;
  arbitrator_registration_allowed?: boolean;
  team_profile_edit_allowed?: boolean;
  team_can_upload_documents?: boolean;
  arbitrator_profile_edit_allowed?: boolean;
  rounds_available_to_participants?: boolean;
  timeslots?: string[];
  timeslots_advanced?: string[];

  arbitrator_bench_memo_available?: boolean;
  arbitrator_bench_memo_file_id?: string | null;
}

const DefaultContestState: ContestState = {
  team_registration_allowed: false,
  team_profile_edit_allowed: false,
}


export const useContestStateEdit = () => {
    const data = useSettings<ContestState>("/api/contest-state", DefaultContestState);

    const benchMemo = useFileEdit(data.settings.arbitrator_bench_memo_file_id, {
      apiPath: "/api/arbitrator/documents",
      onRemove: () => data.save({ arbitrator_bench_memo_file_id: null, arbitrator_bench_memo_available: false }),
      onUpload: fileInfo => data.save({ arbitrator_bench_memo_file_id: fileInfo._id }),

    })

    const prepareFeedback = usePrepareRoundsFeedback();

    return {
      ...data,
      benchMemo,
      prepareFeedback,
    }
}

export type ContestStateData = ReturnType<typeof useContestStateEdit>;
