import React from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../theme';
import { Check, ErrorOutlineOutlined, KeyboardArrowLeft, KeyboardArrowRight, RadioButtonUnchecked } from '@mui/icons-material';
import { OccupyFreeSpace, TabDefinition } from '../../toolympus/components/primitives';
import { IconButton } from '@mui/material';

const TabButton = styled.button<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;

  height: 40px;
  padding: 0 24px;
  border-radius: 20px;
  

  background: ${props => props.isSelected ? ThemeSettings.colors.background : "transparent"};
  color: inherit;
  opacity: ${props => props.isSelected ? 1 : 0.7};
  box-shadow: ${ThemeSettings.sizes.shadow.button} ${props => props.isSelected ? ThemeSettings.colors.shadow.button : "transparent"};
  
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;

  transition: all 0.6s ease;

  & .index {
    display: none;
  }

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    display: ${props => props.isSelected ? "flex" : "none"};
    & .index {
      display: inline;
    }
  }
`;

const TabButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  background: linear-gradient(90deg, ${ThemeSettings.colors.backgroundAltLighter} 0%, ${ThemeSettings.colors.backgroundAlt} 85%);
  border: 1px solid #cccccc;
  box-shadow: inset 0 1px 4px -1px #00000040;
  
  height: 48px;
  border-radius: 23px;
  padding: 4px 4px;

  @media ${ThemeSettings.sizes.breakpoints.sm.up} {
    & .arrow-button {
      display: none;
    }
  }
`;

export type TabStatus = "complete" | "outstanding" | "no_status" | "error";

interface Props {
  currentTab: string;
  tabs: TabDefinition[];
  selectTab: (t: string) => void;
  tabStatuses?: Record<string, TabStatus>;
}

export const TabButtons = (props: Props) => {
  if(!props.tabs.length) {
    return null;
  }

  const tabStatuses = props.tabStatuses || {};

  const moveTab = (delta: 1 | -1) => {
    const currentIndex = props.tabs.findIndex(([t]) => t === props.currentTab);
    if(currentIndex < 0) {
      props.selectTab(props.tabs[0][0]);
      return;
    }

    let newIndex = currentIndex + delta;
    if(newIndex < 0) {
      newIndex =  props.tabs.length - 1;
    } else if(newIndex > props.tabs.length - 1) {
      newIndex = 0;
    }
    props.selectTab(props.tabs[newIndex][0]);
  }

  return (
    <TabButtonsWrapper>
        {props.tabs.map(([t,label],idx) => {
          const status = tabStatuses[t] || "no_status";
          return (
            <TabButton key={t} onClick={() => props.selectTab(t)} isSelected={props.currentTab === t}>
              <span className="index">{idx+1}.</span>{label}
              {status === "complete"
                ? <Check color="primary" />
                : status === "outstanding"
                  ? <RadioButtonUnchecked color="primary" />
                  : status === "error"
                    ? <ErrorOutlineOutlined color="error" />
                    : null}
            </TabButton>
        )})}

        <OccupyFreeSpace />
        <IconButton onClick={() => moveTab(-1)} className="arrow-button"><KeyboardArrowLeft /></IconButton>
        <IconButton onClick={() => moveTab(1)} className="arrow-button"><KeyboardArrowRight /></IconButton>
    </TabButtonsWrapper>
  );
}
