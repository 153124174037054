import React from 'react';
import { apiFetch, FetchTypes } from '../../toolympus/api/core';
import { TagManagementForm, useTagManagement } from '../../toolympus/components/Tags';
import { Button } from '@mui/material';
import { useAction } from '../../toolympus/api/useAction';
import { LoadingIndicator } from '../../toolympus/components/primitives';

const useConflictTagManagement = () => {
    const data = useTagManagement('/api/conflict/tag');

    const prefillTeams = useAction(
      () => apiFetch('/api/conflict/prefill-teams', FetchTypes.POST)
            .then(() => data.reload())
    );

    const prefillArbitrators = useAction(
      () => apiFetch('/api/conflict/prefill-arbitrators', FetchTypes.POST)
            .then(() => data.reload())
    );

    return {
        ...data,
        prefillArbitrators,
        prefillTeams,
    }
}

export const ConflictTagManagement = () => {
    const data = useConflictTagManagement();

    return (
      <TagManagementForm
        data={data}
        extraActions={<>
          <Button
            size="small"
            color="primary"
            endIcon={data.prefillTeams.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
            onClick={() => data.prefillTeams.run()}>
              prefill teams
          </Button>
          <Button
            size="small"
            color="primary"
            endIcon={data.prefillArbitrators.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
            onClick={() => data.prefillArbitrators.run()}>
              prefill arbitrators
          </Button>
        </>} />
    );
}
