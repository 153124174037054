import { useMemo, useState } from "react";
import { usePlatformPage } from "../../Common/data/usePlatformPage";
import { getCustomBlocksData } from "../../Auth/Helpers";
import { useCrudItem } from "../../../toolympus/api/useSimpleCrud";
import { Team } from "../types";
import { TeamDefault, TeamProfileApiPath } from "./useTeamProfile";
import { apiFetch, apiUploadFile, downloadFile } from "../../../toolympus/api/core";
import { FileInfo } from "../../../toolympus/components/files";

const usePageConfig = () => {
  const pageInfo = usePlatformPage("team-documents-page");
  const messages = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);

    return (pageData["page-messages"] || {});

  }, [pageInfo.data]);

  return {
    messages,
    isLoading: pageInfo.isLoading,
    state: pageInfo.data.contest_state,
    pageInfo,
  };
}

export const useTeamDocumentsEdit = () => {
  const pageConfig = usePageConfig();
  const data = useCrudItem<Team>(TeamProfileApiPath, {
    defaultValue: TeamDefault,
  });

  const updateMemorialInProfile = (changes: Partial<Team>) => {
    return apiFetch<Team>(`${TeamProfileApiPath}-extra`, "put", changes)
      .then(x => { data.setData(x); return x; });
  }

  const [isFileLoading, setIsFileLoading] = useState({ memorial_1_id: false, memorial_2_id: false });

  const uploadMemorial = (memorial: "memorial_1_id" | "memorial_2_id", file: File) => {
    setIsFileLoading(x => ({ ...x, [memorial]: true }));

    return apiUploadFile(`/api/team/memorial`, "POST", "file", file)
      .then(response => response.data as FileInfo)
      .then(fileinfo => updateMemorialInProfile({ [memorial]: fileinfo._id })
        .then(() => {
          setIsFileLoading(x => ({ ...x, [memorial]: false }));
          return fileinfo;
        }))
        .catch(e => {
          setIsFileLoading(x => ({ ...x, [memorial]: false }));
          throw e;
        });
  }

  const removeMemorial = (memorial: "memorial_1_id" | "memorial_2_id") => {
    setIsFileLoading(x => ({ ...x, [memorial]: true }));
    return updateMemorialInProfile({ [memorial]: null })
      .then(x => {
        setIsFileLoading(x => ({ ...x, [memorial]: false }));
        return x;
      })
      .catch(e => {
        setIsFileLoading(x => ({ ...x, [memorial]: false }));
        throw e;
      });
  }

  const downloadMemorial = (memorial: "memorial_1_id" | "memorial_2_id") => {
    setIsFileLoading(x => ({ ...x, [memorial]: true }));
    return downloadFile(`/api/team/memorial/${data.data[memorial] || "x"}`)
      .then(() => {
        setIsFileLoading(x => ({ ...x, [memorial]: false }));
      })
      .catch(e => {
        setIsFileLoading(x => ({ ...x, [memorial]: false }));
        throw e;
      });
  }

  return {
    pageConfig,
    ...data,
    
    uploadMemorial,
    downloadMemorial,
    removeMemorial,
    isFileLoading,
  }
}