import React, { ReactNode } from 'react';
import { LocaleSwitch } from "../toolympus/components/LocalizedRecords";

export const DefaultLanguage = "en";
export const Languages = ["en", "ru"];

export const LanguagesWithLabels: [string, ReactNode][] = [
  ["en", "en"],
  ["ru", "ru"],
]

export const LanguageSwitch = (props: Omit<React.ComponentProps<typeof LocaleSwitch>, "availableLocales">) =>
    <LocaleSwitch availableLocales={LanguagesWithLabels} {...props} />;
