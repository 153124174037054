import React from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../../theme';
import { Global, css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { Spinner } from '../../Common/Spinner';



export const HeaderGradientsDecorationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const HeaderGradient1 = styled.div`
  position: absolute;
  top: -906px;
  left: -791px;
  width: 1500px;
  height: 1500px;
  transform: rotate(55.611deg);
  flex-shrink: 0;
  background: radial-gradient(73.87% 50% at 50% 50.08%, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 75%);
`;
export const HeaderGradient2 = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;

  width: 1600px;
  height: 1600px;
  transform: rotate(-136.045deg);
  flex-shrink: 0;
  background: radial-gradient(37.26% 39.86% at 50% 50.08%, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
`;

export const FrontPageHeaderGradientDecoration = () => (
  <HeaderGradientsDecorationWrapper>
    <HeaderGradient1 />
    <HeaderGradient2 />
  </HeaderGradientsDecorationWrapper>
)

interface Position {
  top: number;
  left: number;
}

export const FormGradient1 = styled.div<Position>`
  position: absolute;
  top: ${props => props.top}%;
  left: ${props => props.left}%;

  width: 300px;
  height: 300px;
  transform: rotate(-136.045deg);
  flex-shrink: 0;
  background: radial-gradient(37.26% 39.86% at 50% 50.08%, rgba(149, 7, 64, 0.50) 0%, rgba(149, 7, 64, 0.00) 100%);
`;

export const FormGradientWrapper = styled.div`
  border-radius: 42px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const FormGradientDecoration = (props: Position) => (
  <FormGradientWrapper>
    <FormGradient1 {...props} />
  </FormGradientWrapper>
)


export const FrontPageBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/img/header_l.png);
  background-size: 100%;
  background-position: center center;
  opacity: 20%;
`;

export const FrontPageTriangle = styled.div`
  background: url(/img/side_triangle_1.svg);
  background-size: 100% 100%;
  width: 250px;
  height: 500px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(50%);

  @keyframes slideInFromBottomTriangle {
    0% {
      transform: translateY(1000px);
    }
    60% {
      transform: translateY(1000px);
    }
    100% {
      transform: translateY(50%);
    }
  }

  animation: 1.5s ease 0s slideInFromBottomTriangle;

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    width: 150px;
    height: 300px;
  }
`;

export const FrontPageFormTitle = styled(Typography)`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  
  font-weight: 800;
  color: ${ThemeSettings.colors.shadeLight};
  
  & > span {
    padding-bottom: 5px;
    border-bottom: 4px solid ${ThemeSettings.colors.primary};
  }

  @media ${ThemeSettings.sizes.breakpoints.md.down} {
    line-height: 1.5;
    font-size: 1.6rem;
  }
`;
FrontPageFormTitle.defaultProps = { variant: "h2" };

export const FrontPageFormSectionTitle = styled(Typography)`
  font-size: 1.1rem;
  margin: 2rem 0 0.5rem;
`;
FrontPageFormSectionTitle.defaultProps = { variant: "h3" };

export const FrontPageFormWrapper = styled.div`
  padding: 25px 35px;
  border-radius: 25px;
  background: ${ThemeSettings.colors.background};
  color: ${ThemeSettings.colors.text};

  box-shadow: 4px 4px 30px -5px ${ThemeSettings.colors.primary};
`;

export const FrontPageFormWrapperForm = styled.form<{ dim?: boolean }>`
  padding: 25px 35px;
  border-radius: 42px;
  background: ${ThemeSettings.colors.background};
  color: ${ThemeSettings.colors.text};

  box-shadow: 2px 0px 35px 15px ${ThemeSettings.colors.text};

  position: relative;

  & > * {
    opacity: ${props => props.dim ? "0.5" : "unset"};
    z-index: 100;
  }

  & > ${Spinner} {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    opacity: 1;
  }

  & > ${FormGradientWrapper} {
    z-index: 10;
  }

  @media ${ThemeSettings.sizes.breakpoints.md.down} {
    overflow-y: auto;
    max-height: 90%;

    &::-webkit-scrollbar {
      width: 0;
    }

    ${FormGradientWrapper} {
      display: none;
    }
  }

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    padding: 15px 15px;
  }
  
`;

export const FrontPageLogo = styled.img`
  width: 200px;
  height: auto;
`;
FrontPageLogo.defaultProps = { src: "/img/jessup_logo.png" };

export const FrontPageLogoLink = styled.a`
`;
FrontPageLogoLink.defaultProps = { href: "https://jessuprussia.ru" }

export const FrontPageMenuItem = styled.a`
  text-decoration: none;
  color: inherit;

  opacity: 0.7;

  transition: all 0.3s ease;
  padding-right: 0;

  &:hover {
    padding-right: 10px;
    opacity: 1;
  }
`;

export const FrontPageMenu = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  text-align: right;
  gap: 24px;
  color: ${ThemeSettings.colors.background};
  font-size: 24px;
  font-weight: 800;

  ${[2,3,4,5,6,7].map(i => `& > :nth-child(${i}) { animation: hiddenOnTop ${(i-1)*0.125}s, 0.5s ease-out ${(i-1)*0.125}s 1 slideInFromTop; }`)}
`;

export const FrontPageMenuMobileToggle = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;

  display: none;
  opacity: 0.7;

  @media ${ThemeSettings.sizes.breakpoints.md.down} {
    display: block;
  }
`;

export const FrontPageMenuMobileBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: ${ThemeSettings.colors.shade};
`;

// @ts-ignore
export const FrontPageWrapper = styled.div<{ mobileVisible?: boolean, wideContent?: booleant }>`
  width: 100%;
  height: 100dvh;
  box-sizing: border-box;

  position: relative;

  background: ${ThemeSettings.colors.shade};
  color: ${ThemeSettings.colors.background};

  padding: ${ThemeSettings.sizes.frontPage.padding.vertical.l} ${ThemeSettings.sizes.frontPage.padding.horizontal.l};

  display: grid;
  grid-template-columns: ${props => props.wideContent ? "2fr 1fr" : "1fr 2fr"};
  align-items: start;
  overflow: hidden;
  
  @media ${ThemeSettings.sizes.breakpoints.lowPage} {
    padding-top: ${ThemeSettings.sizes.frontPage.padding.vertical.llow}
  }

  ${props => props.theme.breakpoints.down("sm")} {
    padding: ${ThemeSettings.sizes.frontPage.padding.vertical.sExtra} ${ThemeSettings.sizes.frontPage.padding.horizontal.s} ${ThemeSettings.sizes.frontPage.padding.vertical.s};
    grid-template-columns: 1fr;
  }
  

  & ${FrontPageBackdrop} {
    z-index: 0;
  }

  & ${HeaderGradientsDecorationWrapper} {
    z-index: 1;
  }

  & ${FrontPageTriangle} {
    z-index: 2;
  }

  & ${FrontPageMenuMobileToggle} {
    z-index: 2;
    ${props => props.theme.breakpoints.down("sm")} {
      top: ${ThemeSettings.sizes.frontPage.padding.vertical.s};
      right: ${ThemeSettings.sizes.frontPage.padding.horizontal.s};
      z-index: ${props => props.mobileVisible ? 600 : 2};
    }
  }

  & ${FrontPageMenu} {
    z-index: 2;
    position: absolute;
    top: ${ThemeSettings.sizes.frontPage.padding.vertical.l};
    right: ${ThemeSettings.sizes.frontPage.padding.horizontal.l};

    @media ${ThemeSettings.sizes.breakpoints.lowPage}  {
      top: ${ThemeSettings.sizes.frontPage.padding.vertical.llow}
    }

    @media ${ThemeSettings.sizes.breakpoints.md.down} {
      display: ${props => props.mobileVisible ? "flex" : "none"};
      padding-top: 50px;
      top: ${ThemeSettings.sizes.frontPage.padding.vertical.s};
      right: ${ThemeSettings.sizes.frontPage.padding.horizontal.s};
      z-index: ${props => props.mobileVisible ? 500 : 2};
    }
  }

  ${FrontPageMenuMobileBackdrop} {
    @media ${ThemeSettings.sizes.breakpoints.md.down} {
      z-index: ${props => props.mobileVisible ? 300 : 2};
      display: ${props => props.mobileVisible ? "block" : "none"};
      animation: 0.5s ${props => props.mobileVisible ? "opacitySlideIn050" : "none"};
    }
  }

  & * {
    z-index: 200;
  }
`;


export const FrontPageFormHelpText = styled(Typography)`
  display: block;
  margin: 0.5rem 0;
  text-align: center;
  white-space: pre-line;
`;
FrontPageFormHelpText.defaultProps = { variant: "caption" }

export const FrontPageFormErrorText = styled(Typography)<{ visible?: boolean }>`
  display: block;
  margin: 0.5rem 0;
  text-align: center;
  visibility: ${props => props.visible ? "visible" : "hidden"};
`;
FrontPageFormErrorText.defaultProps = { variant: "caption", color: "primary" }

export const FrontPagePrimaryButton = styled(Button)`
  margin-top: 1em;
`;
FrontPagePrimaryButton.defaultProps = { variant: "contained", color: "primary", fullWidth: true };

export const FrontPageFormExtraLink = styled(Typography)`
  font-size: 0.8em;
  text-align: center;
  margin-top: 0.5rem;

  & a {
    color: ${ThemeSettings.colors.primary};
    text-decoration: none;
    border-bottom: 1px dotted ${ThemeSettings.colors.primary};
  }
`;

const globalStyle = css`
  html, body {
    margin: 0;
  }

  @keyframes opacitySlideIn050 {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes hiddenOnTop {
    0% {
      transform: translateY(-1000px);
      visibility: hidden;
    }
    100% {
      transform: translateY(-1000px);
      visibility: hidden;
    }
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-1000px);
    }
    /* this extra step removes flicker to non-animated between animations */
    10% {
      transform: translateY(-1000px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const FrontPageGlobalStyles = () => <Global styles={globalStyle} />
