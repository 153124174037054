import React, { useEffect, useMemo } from 'react';
import { RoundInfoProps } from '../../toolympus/components/Contests/Grid';
import { useApprovedBailiffs } from './BailiffsProvider';
import { FormGrid, LoadingIndicator } from '../../toolympus/components/primitives';
import { SelectControl } from '../../toolympus/components/schemed/Select';
import { InputAdornment } from '@mui/material';
import { useRoundInfoEdit } from './useRoundInfoEdit';

export const RoundExtraInfo = (props: RoundInfoProps) => {
  const bailiffs = useApprovedBailiffs();

  const roundInfo = useRoundInfoEdit(props.round.stage_code, props.round._id, !!props.round._id && !!props.round.stage_code);

  useEffect(() => {
    bailiffs.ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bailiffsOptions = useMemo(() => {
    return bailiffs.data.map(b => ({ value: b._id, label: `${b.first_name} ${b.last_name}` }));
  }, [bailiffs.data]);


  return (
    <FormGrid columns="1fr">
      <SelectControl
        value={roundInfo.data.bailiff_id || null}
        onChange={v => roundInfo.save({ bailiff_id: v })}
        label="Bailiff"
        values={bailiffsOptions}
        controlProps={{
          startAdornment: (roundInfo.isLoading || bailiffs.isLoading) ? <InputAdornment position="start"><LoadingIndicator sizeVariant="s" /></InputAdornment> : undefined,
        }}
        />
    </FormGrid>
  );
}
