import React from 'react';
import { useParticipantRoundData } from './useParticipantRoundData';
import { ParticipantRoundPage } from './ParticipantRoundPage';

interface Props {
  
}

export const BailiffStubRoundPage = (props: Props) => {
  const data = useParticipantRoundData("rating", "018c6350-ea20-4249-a5f3-a10010e15dd0");
  return (
    <ParticipantRoundPage data={data} isBailif />
  );
}
