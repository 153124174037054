import React, { useEffect, useMemo } from 'react';
import { ScoringControlProps } from '../../../toolympus/components/Contests/ScoringSheets';
import { useScoredRound } from '../../../toolympus/components/Contests/Grid/Parties/ScoredRoundContext';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { Select } from '../../../toolympus/components/schemed/Select';
import { useRoundParticipantsInfo } from './RoundParticipantsInfoContext';

export const getScoreIdx = (scoreKey: string | undefined) => !scoreKey
  ? null
  : scoreKey.startsWith("perf1")
    ? 1
    : scoreKey.startsWith("perf2")
      ? 2
      : null;

export const SpeakerSelect = (props: ScoringControlProps) => {
    const { data: { scores, update }, criteria } = props;
    const { round, score } = useScoredRound();
    
    const { teamsTiming } = useRoundParticipantsInfo();

    const position = score?.position;
    const scoreKey = score?.score_key;

    const speakers = useMemo(() => {
      const team = round?._id && position ? round.players[position] : null;
      const timing = teamsTiming[team?._id || ""];
      const scoreIdx = getScoreIdx(scoreKey);
      if(!team || !timing || !scoreKey || !position || !scoreIdx) {
        return [];
      }
      const speaker = scoreIdx === 1 ? timing?.speaker_1 : timing?.speaker_2;

      return [{ label: speaker.speaker_name || "", value: `${speaker.speaker_name}, ${team?.display_name}`}];
  
    }, [teamsTiming, round, scoreKey,  position]);

    useEffect(() => {
      if(speakers.length && criteria?._id && !scores[criteria._id]) {
        update({ [criteria._id]: speakers[0].value });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [speakers, criteria, score?._id]);

    return (
      <Select
        field="speaker"
        row={{ speaker: scores[criteria?._id || ""]}}
        extraProps={{
          disabled: true,
        }}
        schema={{
          type: FieldType.select, 
          label: " ",
          values: speakers,
          valueDict: speakers.reduce((r,x) => ({ ...r, [x.value]: x.label }), {})
        }}
        onChange={(o,c) => {}}
        />);
}
