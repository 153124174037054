import { useCallback, useMemo, useState } from "react"

interface ListSelectionConfig<ItemType> {
  items?: ItemType[];
}

export const useListSelection = <ItemType,>(keyFn: (i: ItemType) => string, cfg?: ListSelectionConfig<ItemType>) => {
  const [selectedKeys, setSelectedKeys] = useState<Record<string, boolean>>({});

  const select = (item: ItemType, selected: boolean) => {
    const k = keyFn(item);
    setSelectedKeys(x => ({ ...x, [k]: selected }));
  }

  const selectMass = (items: ItemType[], selected: boolean, fromEmpty?: boolean) => {
    const selectedKeys = items.reduce<Record<string, boolean>>((r, item) => { r[keyFn(item)] = selected; return r; }, {});
    setSelectedKeys(x => fromEmpty ? selectedKeys : { ...x, ...selectedKeys });
  }

  const toggle = (item: ItemType) => {
    const k = keyFn(item);
    setSelectedKeys(x => ({ ...x, [k]: !x[k] }));
  }


  const isAllSelected = useMemo(() => {
    return !!cfg?.items?.length && cfg.items.every(i => selectedKeys[keyFn(i)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfg?.items, selectedKeys]);

  const selectAll = (selected: boolean) => {
    setSelectedKeys(selected
      ? (cfg?.items || []).reduce<Record<string, boolean>>((r,i) => { r[keyFn(i)] = true; return r; }, {})
      : {});
  }

  const toggleAll = () => {
    selectAll(!isAllSelected);
  }

  const isSelected = useCallback(
    (item: ItemType) => !!selectedKeys[keyFn(item)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedKeys]);

  const selectedItems = useMemo(() => {
    return (cfg?.items || []).filter(item => selectedKeys[keyFn(item)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys, cfg?.items]);

  return {
    select,
    toggle,
    selectAll,
    toggleAll,
    selectMass,

    isSelected,
    isAllSelected,

    selectedItems,
  }
}
