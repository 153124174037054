import styled from '@emotion/styled';
import { ThemeSettings } from '../../theme';
import { PropsWithChildren, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import React from 'react';
import { Button, Typography } from '@mui/material';
import { css } from '@emotion/react';

export const PageHeaderWrapper = styled.div`
  width: 100%;
  padding: 24px 30px;
  margin-bottom: ${ThemeSettings.sizes.spacing.block};
  
  background: ${ThemeSettings.colors.background};
  color: ${ThemeSettings.colors.text};
  box-shadow: ${ThemeSettings.sizes.shadow.panel} ${ThemeSettings.colors.shadow.panel};
  border-radius: 0 0 ${ThemeSettings.sizes.borderRadius.panel}px ${ThemeSettings.sizes.borderRadius.panel}px;

  h2 {
    font-size: 2rem;
    margin-bottom: 12px;
    font-weight: 600;
  }

  & h1, h2, h3, h4, h5, h6 {
    color: ${ThemeSettings.colors.shadeLight};
  }
`;

export const PageHeaderTitle = styled(Typography)`
  &:last-child {
    margin-bottom: 0;
  }
`;
PageHeaderTitle.defaultProps = { variant: "h2" };

export const PageHeaderMessage = styled(Typography)`
  margin: 1rem 0;
`;

const panelTableStyle = css`
  & table {
    & td {
      border: none;
      padding: 0.5rem 1rem;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    & tr {
      border-bottom: 1.5px solid ${ThemeSettings.colors.tableBorder};
    }
  }
`;

interface PagePanelProps {
  offsetToFillHeight?: number;
  invert?: boolean;
  animateAppearence?: boolean;
  noTableStyle?: boolean;
  noPad?: boolean;
  fixMaxHeight?: boolean;
}

export const PagePanelWrapper = styled.div<PagePanelProps>`
  width: 100%;
  padding: ${props => props.noPad ? 0 : "24px 30px"};
  margin-top: ${ThemeSettings.sizes.spacing.block};
  min-height: ${props => props.offsetToFillHeight ? `calc(100% - ${Math.ceil(props.offsetToFillHeight)}px)` : "unset"};
  max-height: ${props => props.offsetToFillHeight && props.fixMaxHeight ? `calc(100% - ${Math.ceil(props.offsetToFillHeight)}px)` : "unset"};
  overflow-y: auto;
  
  &:not(:last-child) {
    margin-bottom: ${ThemeSettings.sizes.spacing.block};
  }
  
  background: ${props => props.invert ? `url(/img/pattern07a.jpg)` : ThemeSettings.colors.background};
  background-size: cover;
  background-position: center left;
  color: ${props => props.invert ? ThemeSettings.colors.background : ThemeSettings.colors.text};
  box-shadow: ${ThemeSettings.sizes.shadow.panel} ${ThemeSettings.colors.shadow.panel};
  border-radius: ${ThemeSettings.sizes.borderRadius.panel}px;

  & p {
    white-space: pre-line;
  }

  & h1, h2, h3, h4, h5, h6 {
    color: ${props => props.invert ? ThemeSettings.colors.background : ThemeSettings.colors.shadeLight};

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  & a {
    color: ${props => props.invert ? ThemeSettings.colors.background : ThemeSettings.colors.primary};
    border-bottom: 1px dotted ${props => props.invert ? ThemeSettings.colors.background : ThemeSettings.colors.primary};
  }

  ${props => props.noTableStyle ? "" : panelTableStyle};

  & .highlight {
    color: ${ThemeSettings.colors.primary};
  }

  @keyframes panel_appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  animation: ${props => props.animateAppearence ? `panel_appear 0.5s ease 1` : "none"};
`;

export const PagePanelMessageWrapper = styled(PagePanelWrapper)`
  display: flex;
  align-items: center;
  max-width: 100%;

  & > :first-child {
    flex: 1 0 auto;
    @media ${ThemeSettings.sizes.breakpoints.sm.down} {
      max-width: calc(100% - 36px);
    }
  }

  & h2, & h3, & h4, & p {
    margin: 0;
  }
`;

export const PagePanelMessage = (props: PropsWithChildren<{ actions?: ReactNode, onClick?: () => void } & Omit<PagePanelProps, "offsetToFillHeight">>) => {
  const { children, actions, ...otherProps } = props;
  return <PagePanelMessageWrapper {...otherProps}>
    <div>
      {children}
    </div>
    {actions}
  </PagePanelMessageWrapper>
};

export const PagePanel = (props: PropsWithChildren<{ fillPage?: any, className?: string } & Omit<PagePanelProps, "offsetToFillHeight">>) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [offsetTop,setOffsetTop] = useState<number | null>(null);

  const { fillPage, children, ...otherProps } = props;

  useLayoutEffect(() => {
    const updOffset = () => {
      if(wrapperRef.current && fillPage) {
        // const fromBottom
        const offset = wrapperRef.current.offsetTop;
        setOffsetTop(offset);
      }
    }
    updOffset();
    const timeout = setTimeout(updOffset, 500);
    return () => clearTimeout(timeout);
  }, [fillPage]);

  return (
    <PagePanelWrapper ref={wrapperRef} className={props.className} offsetToFillHeight={fillPage && offsetTop ? offsetTop : undefined} {...otherProps}>
      {children}
    </PagePanelWrapper>
  )
}


export const FloatingButton = styled(Button)`
  position: fixed;
  bottom: ${ThemeSettings.sizes.spacing.block};
  right: ${ThemeSettings.sizes.spacing.block};
  z-index: 1000;
`;