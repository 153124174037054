import React from 'react';
import { ArbitratorProfileData } from '../useArbitratorProfile';
import { FormGrid } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { RadioCheckbox } from '../../Common/RadioCheckbox';
import { ProfileComments, ProfileSectionTitle } from '../../Common/styles';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { TimeslotsSelection } from '../../../toolympus/components/Contests/Timeslots';

interface Props {
  data: ArbitratorProfileData;
  canEditProfile?: boolean;
}

export const ArbitratorAvailabilityForm = (props: Props) => {
  const { data, update, errors } = props.data;
  return (
    <>
      

      <ProfileSectionTitle>{props.data.pageConfig.config.availabilityMessages?.preliminary_rounds_title}</ProfileSectionTitle>

      <ProfileComments>{props.data.pageConfig.config.availabilityMessages?.preliminary_rounds_intro}</ProfileComments>
      
      <FormGrid noMargin>
        <FormControlsForFields
          data={data}
          onChange={(o,c) => update(c)}
          schema={{
            timezone: { type: FieldType.dictionarySelect, dictionary: "Timezones" },
            judging_format: {
              type: FieldType.select,
              values: [
                { value: "online", label: "Online" },
                { value: "offline", label: "Offline" },
              ],
            },
            rounds_number_accepted: {
              type: FieldType.select,
              values: ["1","2","3","4","4+"].map(v => ({ value: v, label: v }))}
          }}
          errors={errors}
          readOnly={!props.canEditProfile}
          fields={[
            ["timezone", { label: "Your time zone", controlProps: { required: true } }],
            ["judging_format", { label: "Format", controlProps: { required: true } }],
            ["rounds_number_accepted", { label: "How many Preliminary Rounds would you like to judge", controlProps: { required: true } }],
          ]}
          />
      </FormGrid>
      
      <ProfileComments>{props.data.pageConfig.config.availabilityMessages?.preliminary_rounds_timeslots}</ProfileComments>

      <TimeslotsSelection
        wrapperStyle={{ margin: "2rem 0 1rem"}}
        available={props.data.pageConfig.pageInfo.data?.contest_state?.timeslots}
        selected={data.timeslots_preference}
        update={v => update({ timeslots_preference: v })}
        disabled={!props.canEditProfile}
        dateFormat="D MMMM, dddd"
        />

      <FormControlsForFields
        data={data}
        onChange={(o,c) => update(c)}
        schema={{ timeslots_comment: { type: FieldType.textlong } }}
        readOnly={!props.canEditProfile}
        errors={errors}
        fields={[
          ["timeslots_comment", {
            label: props.data.pageConfig.config.availabilityMessages?.timeslots_comment_label,
            autoRows: true,
          }],
        ]}
        />

      <RadioCheckbox
          label="Availability to join a round on short notice"
          value={data.short_notice_availability}
          update={v => update({ short_notice_availability: v })}
          />
      <ProfileComments>{props.data.pageConfig.config.availabilityMessages?.preliminary_rounds_outro}</ProfileComments>


      <ProfileSectionTitle>{props.data.pageConfig.config.availabilityMessages?.advanced_rounds_title}</ProfileSectionTitle>

      <ProfileComments>{props.data.pageConfig.config.availabilityMessages?.advanced_rounds_intro}</ProfileComments>
      <RadioCheckbox
        label="I only want to judge the Advanced Rounds"
        value={data.advanced_rounds_only_preferred}
        update={v => update({ advanced_rounds_only_preferred: v })}
        labelFalse={props.data.pageConfig.config.availabilityMessages?.advanced_only_no_label}
        />
      
      <ProfileComments>{props.data.pageConfig.config.availabilityMessages?.advanced_rounds_timeslots}</ProfileComments>

      <TimeslotsSelection
        wrapperStyle={{ margin: "2rem 0 1rem"}}
        available={props.data.pageConfig.pageInfo.data?.contest_state?.timeslots_advanced}
        selected={data.advanced_rounds_timeslots_preference}
        update={v => update({ advanced_rounds_timeslots_preference: v })}
        disabled={!props.canEditProfile}
        dateFormat="D MMMM, dddd"
        />

      <RadioCheckbox
        label={<strong>{props.data.pageConfig.config.availabilityMessages?.advanced_rounds_offline_availability_label}</strong>}
        value={data.advanced_rounds_offline_availability}
        update={v => update({ advanced_rounds_offline_availability: v })}
        />

      <FormControlsForFields
        data={data}
        onChange={(o,c) => update(c)}
        schema={{ advanced_rounds_timeslots_comment: { type: FieldType.textlong } }}
        readOnly={!props.canEditProfile}
        errors={errors}
        fields={[
          ["advanced_rounds_timeslots_comment", {
            label: props.data.pageConfig.config.availabilityMessages?.advanced_rounds_timeslots_comment_label,
            autoRows: true,
          }],
        ]}
        />

      <RadioCheckbox
          label="Availability to join a round on short notice"
          value={data.advanced_rounds_short_notice_availability}
          update={v => update({ advanced_rounds_short_notice_availability: v })}
          />
      <ProfileComments>{props.data.pageConfig.config.availabilityMessages?.advanced_rounds_outro}</ProfileComments>
    </>
  );
}
