import React from 'react';
import { IconButton } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { ActionRow, FormGrid, LoadingIndicator, OccupyFreeSpace, useSaveable } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useCourtRoomSettingsEdit } from './useCourtRoomSettingsEdit';

export const CourtRoomSettingsForm = () => {
  const data = useCourtRoomSettingsEdit();

  useSaveable(data);
  
  return (<>
    <ActionRow firstItemNoMargin>
      <OccupyFreeSpace />
      
      <IconButton size="small" style={{ visibility: "hidden" }}><SaveOutlined /></IconButton>
      {data.isLoading && <LoadingIndicator />}
      {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
    </ActionRow>

    <FormGrid columns="1fr 1fr">
      <FormControlsForFields
        data={data.settings}
        onChange={(o,c) => data.update(c)}
        schema={data.schema}
        fields={[
          ["is_available"],
          ["domain"],
          ["token_secret"],
          ["token_app_id"],
          ["room_name_round"],
          ["room_name_deliberations"],
        ]}
        />
    </FormGrid>
  </>);
}
