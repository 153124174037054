import React from 'react';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Buttons, Dialog, FormGrid, SimpleDialog, TabPanel, useTabsState } from '../../../toolympus/components/primitives';
import { TabButtons } from '../../Common/TabButtons';
import { TeamPrimaryContactForm } from '../TeamProfile/TeamPrimaryContactForm';
import { TeamUniversityForm } from '../TeamProfile/TeamUniversityForm';
import { Button, IconButton, Typography } from '@mui/material';
import { PersonEditor } from '../TeamProfile/PersonEditor';
import { TeamListData } from './useTeamsList';
import { SaveOutlined } from '@mui/icons-material';

interface Props {
  data: TeamListData; 
}

export const TeamReviewDialog = (props: Props) => {
  const { data } = props;

  const reviewTabs = useTabsState([
    ["contact", "Contact"],
    ["university", "University"],
    ["roster", "Roster"],
    // ["assistance", "Assistance"],
  ], "contact");

  return (
    <Dialog
      fixedMaxHeight
      isOpen={data.review.isEditing}
      close={() => data.review.cancel()}
      dialogTitle={<>
        <span style={{ whiteSpace: "nowrap" }}>Team {data.review.item?.teamnbr || data.review.item?.email || "..."}</span>
      </>}
      titleActions={<>
        <TabButtons
          currentTab={reviewTabs.current}
          selectTab={reviewTabs.setCurrent}
          tabs={reviewTabs.tabs}
          />
      </>}
      fullWidth
      maxWidth="lg">

      {data.review.item && <>
        <TabPanel state={reviewTabs.forTab("contact")}>
          <FormGrid noMargin style={{ alignItems: "end" }}>
            <FormControlsForFields
              data={data.review.item}
              schema={data.schema}
              fields={[
                ["teamnbr", { readOnly: true }],
                ["memorials_score"]
              ]}
              onChange={(o,c) => data.review.update(c)}
              />

            <Buttons style={{ justifyContent: "flex-end" }}>
              {data.review.hasChanges &&
                <IconButton size="small" color="primary" onClick={() => data.review.save()}><SaveOutlined /></IconButton>}
              <Button
                color="primary"
                variant={data.review.item.is_excluded ? "contained" : undefined}
                onClick={() => data.excludeTeam(data.review.item?._id || "", !data.review.item?.is_excluded)}>
                {data.review.item.is_excluded ? "un-exclude" : "exclude"}
              </Button>
            </Buttons>

            {/* <RadioOptions
              label="Our team has paid the Jessup registration fee"
              value={data.review.item?.is_fee_paid}
              options={[
                ["yes", "Yes"],
                ["no", "No"],
              ]}
              update={v => {}}
              readOnly
              wrapperStyle={{ marginTop: 0 }}
            /> */}
          </FormGrid>

          <FormGrid columns="1fr">
            <Typography variant="caption" color="textSecondary">Conflict tags</Typography>
            {data.conflicts.displayForRecord(data.review.item._id)}
          </FormGrid>
          
          <TeamPrimaryContactForm
            data={data.review.item}
            updatePrimaryContact={() => {}}
            startChangeEmail={t => data.changeEmail.startEditing({ ...t })}
            />
        </TabPanel>

        <TabPanel state={reviewTabs.forTab("university")}>
          <FormGrid columns="1fr" noMargin>
            <FormControlsForFields
              data={data.review.item}
              onChange={(o,c) => data.review.save(c)}
              schema={data.schema}
              fields={[
                ["university_confirmed", { autoComplete: true }],
              ]}
              />
          </FormGrid>

          <TeamUniversityForm
            data={data.review.item}
            update={() => {}}
            universityContacts={{
              items: data.review.item.university_contacts || [],
              add: () => ({ _id: "" }),
              moveUp: () => {},
              moveDown: () => {},
              remove: () => {},
              update: () => {},
              moveTo: () => {},
            }}
            />
        </TabPanel>

        <TabPanel state={reviewTabs.forTab("roster")}>
          <Typography variant="h4">
            Team roster
          </Typography>

          {(data.review.item.members || []).map((member,idx) => (
            <PersonEditor
              key={member._id}
              item={member}
              update={() => {}}
              readOnly
              avatarReadOnly
              />
          ))}

          {(data.review.item.members || []).length === 0 && <Typography>-</Typography>}

          <Typography variant="h4" style={{ marginTop: "2rem" }}>
            Advisors
          </Typography>

          {(data.review.item.advisors || []).map((advisor,idx) => (
            <PersonEditor
              key={advisor._id}
              item={advisor}
              update={() => {}}
              readOnly
              avatarReadOnly
              />
          ))}

          {(data.review.item.advisors || []).length === 0 && <Typography>-</Typography>}

        </TabPanel>


        {/* <TabPanel state={reviewTabs.forTab("assistance")}>
          <TeamAssitanceForm
            assistance={{
              assistance: data.review.item.assistance || {},
              updateItem: () => {},
              updateNoneRequired: () => {},
            }}
            />
        </TabPanel> */}


        <SimpleDialog
          dialogTitle="Change team email"
          isOpen={data.changeEmail.isEditing}
          close={() => data.changeEmail.cancel()}
          save={() => data.changeEmail.save()}>
            {data.changeEmail.item && <FormGrid columns="1fr">
              <FormControlsForFields
                data={data.changeEmail.item}
                schema={data.schema}
                fields={[["email"]]}
                onChange={(o,c) => data.changeEmail.update(c)}
                />
            </FormGrid>}
        </SimpleDialog>
      </>}
    </Dialog>
  );
}
