import { useIntl } from "react-intl";
import { GridConfiguration, Stage } from "../../../toolympus/components/Contests/Grid/useGridConfiguration"
import { useTabsState } from "../../../toolympus/components/primitives";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { Round } from "../../../toolympus/components/Contests/Grid/types";
import { RoundInfo } from "../useRoundInfoEdit";
import { useEffect, useMemo } from "react";
import { toMap } from "../../../toolympus/api/data";
import { apiFetch } from "../../../toolympus/api/core";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { useStageScoringStatus } from "./useScoringStatus";
import { socketByToken, useWebSocket } from "../../../toolympus/hooks/useWebsocket";

export interface RoundExt extends Round {
  roundInfo?: RoundInfo;
}


export const useAdminRoundsInfoList = () => {
  const config = useLoadedData<GridConfiguration>("/api/rounds", { stages: [] as Stage[] } as GridConfiguration);
  const { formatMessage } = useIntl();

  const tabs = useTabsState([
    ...((config.data.stages || []).map(s => [s.code, formatMessage({ id: `contests.rounds.config.stages.names.${s.code}` })] as [string, string])),
  ], undefined, "tab", "j_rounds_info_tab");

  const roundsRaw = useLoadedData<Round[]>(`/api/rounds/stage/${tabs.current}/round`, [], !!tabs.current);
  const roundsInfo = useLoadedData<RoundInfo[]>(`/api/rounds-info/${tabs.current}`, [], !!tabs.current);

  const rounds: RoundExt[] = useMemo(() => {
    const infoMap = toMap(roundsInfo.data, ri => ri._id);
    return roundsRaw.data.map(r => ({ ...r, roundInfo: infoMap[r._id] }));
  }, [roundsRaw.data, roundsInfo.data]);

  const scoringStatus = useStageScoringStatus(rounds.length ? tabs.current : "");


  const { socket, open } = useWebSocket(
    socketByToken(
      `/rounds-info/live-updates/token-admin`,
      token => 'rounds-info/live-updates?token=' + token),
    () => {},
    !roundsInfo.data.length);

  const onMessage = (env: MessageEvent) => {
    const data = JSON.parse(env.data);
    if(data._type === "round_updated" && data.changes) {
      roundsInfo.setData(current => current.map(ri => ri._id === data.round_id ? { ...ri, ...data.changes } : ri));
      const changed = data.changes as Partial<RoundInfo>;
      if(changed.checkins || changed.help_requests || changed.teams_timing) {
        new Audio("/notify.mp3").play();
      }
    }
  }

  useEffect(() => {
    if(socket) {
        socket.addEventListener("message", onMessage);
        return () => socket.removeEventListener("message", onMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  const clearHelpRequest = useItemActionWithConfirmation((x: { roundInfo: RoundInfo, idx: number}) => {
    return apiFetch<RoundInfo>(`/api/rounds-info/${x.roundInfo.stage}/${x.roundInfo._id}`, "put", {
      help_requests: (x.roundInfo?.help_requests || []).filter((_,i) => i !== x.idx),
    }).then(ri => {
      roundsInfo.setData(roundsInfo.data.map(ri2 => ri2._id === ri._id ? ri : ri2));
      return ri;
    });
  }, {
    skipConfirmation: true,
  });

  return {
    config: config.data,
    rounds: rounds,
    roundsInfo: roundsInfo.data,
    scoringStatus,
    tabs,
    isLoading: config.isLoading || roundsRaw.isLoading || roundsInfo.isLoading,
    clearHelpRequest,
  }
}
