import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Chip, FormControl, IconButton, Input, InputAdornment, MenuItem, TextField } from '@mui/material';
import Autosuggest, { InputProps } from 'react-autosuggest';
import { Buttons, Dialog, FormGrid } from '../../toolympus/components/primitives';
import { Add } from '@mui/icons-material';
import { CrmSettings } from './useCrmSettings';
import { SettingsData } from '../../toolympus/api/useSettings';
import { SuggestedColors } from '../../toolympus/components/Tags/TagEditPopup';

interface Props {
  value: string[] | undefined;
  update: (v: string[]) => void;
  availableTags: string[];
  disabled?: boolean;
  onClick?: () => void;
  settings: SettingsData<CrmSettings>;
}

const EmptySuggestions: string[] = [];

const useSuggestions = (data: Props) => {
  const value = data.value || EmptySuggestions;
  const valuesLower = useMemo(() => value.map(v => v.trim().toLowerCase()), [value]);

  const [filter, setFilter] = useState<string>("");

  const suggestions = useMemo(() => {
    return data.availableTags.filter(t => t.includes(filter) && !(data.value || []).includes(t))
  }, [filter, data.availableTags, data.value]);
  
  const canAdd = (tag: string): boolean => {
    const clean = (tag || "").trim();
    return !!clean && !valuesLower.includes(clean.toLowerCase());
  }

  const addTag = (tag: string) => {
    const clean = (tag || "").trim()
    if(canAdd(clean)) {
      const copy = [...value, clean];
      copy.sort();
      data.update(copy);
      setFilter("");
    }
  }

  const removeTag = (tag: string) => {
    const clean = (tag || "").trim().toLowerCase();
    data.update((value || []).filter(tx => tx.trim().toLowerCase() !== clean));
  }

  return {
    suggestions,
    filter,
    setFilter,
    value,
    addTag,
    canAdd,
    removeTag,
  }
}

type SuggestionsData = ReturnType<typeof useSuggestions>;


const TagChip = styled(Chip)`

  & .MuiChip-label {
    color: inherit;
  }
`;

interface TagDisplayProps {
  removeTag?: (t: string) => void;
  editTag?: (t: string) => void;
  settings?: CrmSettings["tags"];
  tag: string;
  icon?: ReactNode;
}

export const TagDisplay = (props: TagDisplayProps) => {
  const { tag, removeTag, editTag, settings } = props;
  return (
    <TagChip
      size="small"
      label={tag}
      avatar={props.icon as any}
      style={{ backgroundColor: (settings || {})[tag]?.color || undefined }}
      onDelete={removeTag ? () => removeTag && removeTag(tag) : undefined}
      onClick={editTag ? () => editTag && editTag(tag) : undefined}
      />)
}

export const TagsDisplay = (props: Pick<Props, "value"> & Omit<TagDisplayProps, "tag">) => {
  return (<>
    {(props.value || []).map(t =>
      <TagDisplay
        key={t}
        tag={t}
        removeTag={props.removeTag}
        editTag={props.editTag}
        settings={props.settings}
        />)}
  </>)
}

const SuggestInput = React.forwardRef((props: InputProps<string> & { suggestions: SuggestionsData }, ref) => {
  const { suggestions, ...other } = props;
  return (
      <FormControl fullWidth>
          {/* @ts-ignore */}
          <Input 
            autoComplete="off"
            fullWidth
            inputProps={{
              onKeyDown: e => {
                if(e.key === "Enter") {
                  if(suggestions.suggestions.length === 1) {
                    suggestions.addTag(suggestions.suggestions[0]);
                  } else if(suggestions.canAdd(suggestions.filter)) {
                    suggestions.addTag(suggestions.filter);
                  }
                }
              },
            }}
            inputRef={node => {
              if(ref) {
                // @ts-ignore
                ref(node)
              }
            }}
            endAdornment={(
              <InputAdornment
                position="end"
                style={{ visibility: suggestions.canAdd(suggestions.filter) ? "visible" : "hidden" }}>
                  <Button size="small" onClick={() => suggestions.addTag(suggestions.filter)}>
                    add
                  </Button>
              </InputAdornment>)}
            {...other}
          />
      </FormControl>
  )
});

export const TagsPickerWrapper = styled(Buttons)`
  flex-flow: row wrap;

  & > .react-autosuggest__container {
    min-width: 150px;
    position: relative;
  }

  & .react-autosuggest__suggestions-container--open {
    position: absolute;
    z-index: +999;
    margin-top: 3px;
    left: 0;
    right: 0;
  }

  & .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style: none;
    background: white;
    box-shadow: 0px 2px 5px -3px #00000080;
  }
`;

export const TagsPicker = (props: Props) => {
  const suggestions = useSuggestions(props);
  const [isFilterActive,setIsFilterActive] = useState<boolean>(false);
  const [editTag,setEditTag] = useState<string | undefined>(undefined);

  return (<>
    <TagsPickerWrapper onClick={() => props.onClick && props.onClick()}>
      <TagsDisplay
        value={suggestions.value}
        editTag={props.disabled ? undefined : t => setEditTag(t)}
        removeTag={props.disabled ? undefined : t => suggestions.removeTag(t)}
        settings={props.settings.settings.tags}
        />
      {!props.disabled && <>
        {!isFilterActive && <IconButton size="small" color="primary" onClick={() => setIsFilterActive(true)}><Add /></IconButton>}
        {isFilterActive && <Autosuggest
          getSuggestionValue={s => s}
          onSuggestionsFetchRequested={(req: {value: string}) => suggestions.setFilter(req.value)}
          renderSuggestion={s => <MenuItem>{s}</MenuItem>}
          suggestions={suggestions.suggestions}
          renderInputComponent={props => <SuggestInput suggestions={suggestions} {...props} />}
          inputProps={{
            onChange: (_,e) => suggestions.setFilter(e.newValue),
            onBlur: () => { suggestions.setFilter(""); setIsFilterActive(false); },
            value: suggestions.filter,
            autoFocus: true,
          }}
          onSuggestionSelected={(e,x) => { suggestions.addTag(x.suggestionValue) }}
          />}
        </>}
    </TagsPickerWrapper>
    {!props.disabled &&
      <TagSettingsPopup
        tag={editTag}
        close={() => setEditTag(undefined)}
        settings={props.settings}
        />}
  </>);
}

const TagSettingsPopup = (props: { tag: string | undefined, close: () => void, settings: SettingsData<CrmSettings> }) => {
  const { tag, settings, close } = props;
  const [color, setColor] = useState<string>("");

  useEffect(() => {
    setColor((tag && settings.settings.tags[tag]?.color) ? (settings.settings.tags[tag].color || "") : "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  const save = () => {
    if(tag) {
      const settingsColor = settings.settings.tags[tag]?.color;
      if(settingsColor !== color) {
        settings.save({
          tags: {
            ...(settings.settings.tags || {}),
            [tag]: { ...((settings.settings.tags || {})[tag] || {}), color },
          }
        })
      }
    }
    close();
  }

  return (
    <Dialog
      dialogTitle="Tag settings"
      isOpen={!!props.tag}
      close={close}
      actions={<Button color="primary" variant="contained" onClick={save}>save</Button>}
      noFullscreen>
      <FormGrid columns="1fr" noMargin>
        <TextField
          label="Tag"
          InputProps={{
            readOnly: true,
          }}
          value={props.tag}
          />

        <TextField
          label="Color"
          value={color}
          onChange={e => setColor(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              {SuggestedColors.map(color => (
                <TagChip label=" " onClick={() => setColor(color)} size="small" style={{ backgroundColor: color, aspectRatio: "1", marginLeft: 2 }} />
              ))}
            </InputAdornment>
          }}
          />
      </FormGrid>
    </Dialog>
  )
}
