import { useSettings } from "../../toolympus/api/useSettings"
import { useLocalizedRecord } from "../../toolympus/components/LocalizedRecords";
import { MenuItem } from "../../toolympus/components/primitives/BasicMenuEditor";
import { DefaultLanguage } from "../Localization";

interface SiteSettings {
    menu: MenuItem[];
    translations: any;
}

export const useSiteSettingsEdit = () => {
    const data = useSettings<SiteSettings>("/api/settings", { menu: [], translations: {} });
    const localized = useLocalizedRecord(data.settings, {
        defaultLocale: DefaultLanguage,
        translatedFields: ["menu"],
        translationsField: "translations",
        updateItem: (o,c) => data.update(c),
    });

    return {
        ...data,
        ...localized,
        update: localized.updateItem,
    }
}
