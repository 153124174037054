import React, { useContext } from 'react';
import { Player, Round } from '../../../toolympus/components/Contests/Grid/types';
import { RoundInfo } from '../useRoundInfoEdit';


export const useRoundParticipantsInfoData = (round: Round, roundInfo: RoundInfo) => {
  const [team1, team2] = [
    (round.players || {})[1],
    (round.players || {})[2],
  ];
  
  const teamsMembers = round.info?.members || {};
  const teamsAdvisors = round.info?.advisors || {};
  const teamsContacts = round.info?.teams_contacts || {};
  const teamsDocuments = round.info?.teams_documents || {};

  return {
    team1,
    team2,
    teams: [team1, team2],
    teamsMembers,
    teamsAdvisors,
    teamsDocuments,
    teamsContacts,
    teamsTiming: (roundInfo.teams_timing || {}),
  }
}

export type IRoundParticipantsInfoContext = ReturnType<typeof useRoundParticipantsInfoData>;

export const RoundParticipantsInfoContext = React.createContext<IRoundParticipantsInfoContext>({
  team1: {} as Player,
  team2: {} as Player,
  teams: [{}, {}] as Player[],
  teamsMembers: {},
  teamsAdvisors: {},
  teamsDocuments: {},
  teamsContacts: {},
  teamsTiming: {},
});

export const useRoundParticipantsInfo = () => useContext(RoundParticipantsInfoContext);

