import React from 'react';
import { GridConfigurationContainer, GridSettings, GridSettingsProvider } from '../../toolympus/components/Contests/Grid';
import { RankStars } from '../Arbitrators/AdminList/RankStars';
import { DateSlotsWrapper } from '../../toolympus/components/Contests/Timeslots';
import { Chip } from '@mui/material';
import { Arbiter } from '../../toolympus/components/Contests/Grid/types';
import moment from 'moment';
import { RoundExtraInfo } from './RoundExtraInfo';
import { BailiffsProvider } from './BailiffsProvider';

const SlotFormat = "D MMM HH:mm"

const TimeSlotsDisplay = (props: Arbiter) => {
  const slots = (props.info?.timeslots || []).sort().map((s: string) => moment(s).format(SlotFormat));
  return (
    <DateSlotsWrapper>
      {slots.map((slot: string) => <Chip size="small" label={slot} />)}
    </DateSlotsWrapper>
  )
}

const Settings: Partial<GridSettings> = {
  arbiter_extra_columns: [
    ["Rank", a => <RankStars value={a.info?.rank || 0} update={() => {}} />],
    ["Timeslots", TimeSlotsDisplay]
  ],
  admin_round_popup_round_extra_component: RoundExtraInfo,
}

export const GridPage = () => {
    return (
      <GridSettingsProvider
        settings={Settings}>
        <BailiffsProvider>
          <GridConfigurationContainer
            apiPath="/api/rounds"
            tagsApiPath="/api/conflict/tag"
            />
        </BailiffsProvider>
      </GridSettingsProvider>
      );
}
