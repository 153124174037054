import { useState } from "react"
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { BailiffsApiPath } from "../Bailiffs/Admin/useBailiffsList"
import { Bailiff } from "../Bailiffs/types"
import { createProvided } from "../../toolympus/components/primitives/createProvided"

const useApprovedBailiffsData = () => {
  const [isLoadRequested, setIsLoadRequested] = useState<boolean>(false);
  const data = useLoadedData<Bailiff[]>(`${BailiffsApiPath}?view=active`, [], isLoadRequested);

  return {
    ...data,
    ensureLoaded: () => setIsLoadRequested(true),
  }
}

export const { Provider: BailiffsProvider, useValue: useApprovedBailiffs } = createProvided(
  useApprovedBailiffsData, {
    data: [],
    ensureLoaded: () => {},
    isLoading: true,
    reload: () => {},
    setData: () => {},
  });
