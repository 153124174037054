import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { urlWithQuery, useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { useTabsState } from "../../../toolympus/components/primitives";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { apiFetch } from "../../../toolympus/api/core";
import { useFieldSorting } from "../../../toolympus/hooks/useFieldSorting";
import { Bailiff } from "../types";

export const BailiffsApiPath = "/api/bailiff/admin";

export const useBailiffsList = () => {
  const tabs = useTabsState([
    ["all", "All"],
    ["excluded", "Review"],
    ["active", "Approved"],
  ], "all", "tab", "__j_bailiff_list_cur_tab");

  const sorting = useFieldSorting({
    allowedFields: [
      "email",
      "first_name",
      "last_name",
      "rank",
      "created_at",
    ],
  })
  
  const data = useLoadedData<Bailiff[]>(urlWithQuery(BailiffsApiPath, { view: tabs.current, "order-by": sorting.sort ? `${sorting.sort.field}:${sorting.sort.direction}` : undefined }), []);
  const { schema } = useSingleSchema(`${BailiffsApiPath}/uiconfig`);

  const filter = useTextFilter<Bailiff>(t => `${t.last_name} ${t.first_name} ${t.email} ${t._id}`)
  const filteredData = filter.filterData(data.data);

  const review = useEditItem2<Bailiff>({
    getApiPath: item => `${BailiffsApiPath}/${item._id}`,
    dontResetOnSave: true,
  });

  const changeEmail = useEditItem2<Bailiff>({
    getApiPath: () => "",
    save: item => {
      return apiFetch<Bailiff>(`${BailiffsApiPath}/${item._id}/email`, "put", { email: item.email })
        .then(updated => {
          data.reload();
          if(review.item && review.item._id === updated._id) {
            review.startEditing(updated);
          }
          return Promise.resolve(updated);
        })
    }
  })

  const approveBailiff = (id: string, isApproved: boolean) => {
    apiFetch<Bailiff>(`${BailiffsApiPath}/${id}/${isApproved ? "approve" : "unapprove"}`, "post")
      .then(updated => {
        data.reload();
        review.cancel();
        if(review.isEditing && review.item?._id === id) {
          review.setItem(updated);
        } 
      })
  }

  const updateRank = (id: string, rank: number) => {
    apiFetch<Bailiff>(`${BailiffsApiPath}/${id}`, "put", { rank })
      .then(() => {
        data.reload();
      });
  }

  return {
    ...data,
    data: filteredData,
    schema,
    filter,
    tabs,
    sorting,
    review: {
      ...review,
      save: () => review.save().then(x => { data.reload(); return x; })
    },
    changeEmail,
    approveBailiff,
    updateRank,
  }
}

export type BailiffListData = ReturnType<typeof useBailiffsList>;
