import React, { useEffect } from 'react';
import { FrontPageFormHelpText, FrontPageFormTitle, FrontPageFormWrapperForm, FrontPagePrimaryButton } from './FrontPages';
import { FormGrid } from '../../toolympus/components/primitives';
import { TextField } from '@mui/material';
import { useRestorePassword } from '../../toolympus/hooks/auth';
import { FrontPageHarness } from './FrontPageHarness';
import { Spinner } from '../Common/Spinner';
import { useHistory } from 'react-router-dom';


export const SetPasswordPage = () => {

  const { data, update, send, isSaving, isSaved: isSuccess } = useRestorePassword("/api/restore-access/reset");

    const history = useHistory();

    useEffect(() => {
        if(isSuccess) {
            setTimeout(() => {
                history.replace("/login");
            }, 5000);
        }
    }, [history, isSuccess]);
  
  return (
    <FrontPageHarness>
    
      <FrontPageFormWrapperForm
        onSubmit={e => { if(!isSuccess) { e.preventDefault(); send(); } }}
        dim={isSaving}
        style={{ alignSelf: "center" }}>

        {(isSaving) && <Spinner size={60} />}

        <FrontPageFormTitle><span>Change password</span></FrontPageFormTitle>

        {!isSuccess && <FrontPageFormHelpText>
          Please enter a new password twice.
        </FrontPageFormHelpText>}


        <FormGrid columns="1fr" noMargin>

          <TextField
            label="Password"
            type="password"
            required
            value={data?.password || ""}
            onChange={e => update({ ...data, password: e.target.value })}
            />

          <TextField
            label="Repeat password"
            type="password"
            helperText="at least 6 characters"
            required
            value={data?.passwordRepeat || ""}
            onChange={e => update({ ...data, passwordRepeat: e.target.value })}
            />

          {isSuccess && <FrontPageFormHelpText>
            Your password has been changed.<br/>We will now redirect you to the sign in page.
          </FrontPageFormHelpText>}

          <FrontPagePrimaryButton type='submit'>
            submit
          </FrontPagePrimaryButton>

        </FormGrid>
      </FrontPageFormWrapperForm>
    </FrontPageHarness>
  );
}
