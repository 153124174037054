import { Criteria } from "../../../toolympus/components/Contests/ScoringSheets";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"

export interface RoundsFeedbackConfig {
  criteria: Criteria[];
  numeric_criteria: Criteria[]
  text_criteria: Criteria[]
  speaker_criteria: Criteria;
}

export interface TeamSpeakerScores {
  _id: string;
  judge?: { _id: string, last_name: string, first_name: string };
  judge_position: number;
  result: number;
  score_label: string;
  scores: Record<string, any>;
}

export interface TeamRoundFeedback {
  _id: number;
  team_id: string;
  year: number;
  round_id: string;
  round_title: string;
  round_subtitle?: string;
  round_time: string;
  scores: { scores: [string, TeamSpeakerScores[]][] };
}

export interface TeamRoundsFeedback {
  config: { year: number, sheet: RoundsFeedbackConfig };
  feedbacks: TeamRoundFeedback[];
}


export const useTeamRoundsFeedback = () => {
  const data = useLoadedData<TeamRoundsFeedback>("/api/rounds-info/feedback/team", {} as any as TeamRoundsFeedback);

  return {
    ...data,
    isEmpty: Object.keys(data.data).length === 0,
  }
}

export type TeamRoundsFeedbackData = ReturnType<typeof useTeamRoundsFeedback>;
