import { LegacyInfo } from "../useAdminLegacyInfoList";

export const LegacyBlockTypes = {
  title: "title",
  comment: "comment",
  members: "members",
  membersadvisors: "mna",
  advisors: "advisors",
  documents: "documents",
}

export interface ContentEditorProps {
  block: LegacyInfo;
  update: (c: Partial<LegacyInfo>) => void;
}
