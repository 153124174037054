import React, { useMemo } from 'react';
import { FrontPageFormExtraLink, FrontPageFormHelpText, FrontPageFormTitle, FrontPageFormWrapperForm, FrontPagePrimaryButton } from './FrontPages';
import { FormGrid } from '../../toolympus/components/primitives';
import { TextField } from '@mui/material';
import { useLoginForm } from '../../toolympus/hooks/auth';
import { useReturnUrl } from '../../toolympus/hooks/auth/useReturnUrl';
import { FrontPageHarness } from './FrontPageHarness';
import { Spinner } from '../Common/Spinner';
import { CMSRecord } from '../../toolympus/components/CMS';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { getCustomBlocksData, processExtraLinks, processBasicExtraLinks } from './Helpers';


const usePageConfig = () => {
  const pageInfo = useLoadedData<{ item: Pick<CMSRecord, "content"> }>("/api/public/cms/platform_page/log-in", { item: {}});
  const config = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);
    const formConfigFields = pageData["login-form"]|| {};
    const paneFields = pageData["pane-config"]|| {};    

    return {
      formConfig: {
        links: processExtraLinks(formConfigFields.extra_links),
        guide: formConfigFields.guide,
      },
      paneConfig: { links: processBasicExtraLinks(paneFields.extra_links) },
    };
  }, [pageInfo.data]);
  
  return { config, isLoading: pageInfo.isLoading };
}


export const LogInPage = () => {
  const returnUrl = useReturnUrl();
  const signupData = useLoginForm("/api/login", returnUrl.fromParam || "/");

  const pageConfig = usePageConfig();
  
  return (
    <FrontPageHarness menuLinks={pageConfig.config.paneConfig.links}>
    
      <FrontPageFormWrapperForm
        onSubmit={e => { e.preventDefault(); signupData.send(); }}
        dim={signupData.isSaving || pageConfig.isLoading}
        style={{ alignSelf: "center" }}>

        {(signupData.isSaving || pageConfig.isLoading) && <Spinner size={60} />}

        <FrontPageFormTitle><span>Sign in</span></FrontPageFormTitle>

        {pageConfig.config.formConfig.guide && <FrontPageFormHelpText>{pageConfig.config.formConfig.guide}</FrontPageFormHelpText>}

        <FormGrid columns="1fr" noMargin>
          <TextField
            label="Email"
            value={signupData.data.login}
            type="email"
            onChange={e => signupData.update({ ...signupData.data, login: e.target.value })}
            />

          <TextField
            label="Password"
            value={signupData.data.password}
            type="password"
            onChange={e => signupData.update({ ...signupData.data, password: e.target.value })}
            />


          <FrontPagePrimaryButton type='submit'>
            sign in
          </FrontPagePrimaryButton>

          {pageConfig.config.formConfig.links.map((l,idx) => <FrontPageFormExtraLink key={idx}>{l}</FrontPageFormExtraLink>)}
        </FormGrid>
      </FrontPageFormWrapperForm>
    </FrontPageHarness>
  );
}
