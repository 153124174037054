
export const RolesKeys = {
  admin: "admin",
  team: "team",
  arbitrator: "arbitrator",
  bailiff: "bailiff",
}

export const Roles = [
    { name: 'Admin', key: RolesKeys.admin },
    { name: 'Team', key: RolesKeys.team },
    { name: 'Arbitrator', key: RolesKeys.arbitrator },
    { name: 'Bailiff', key: RolesKeys.bailiff },
]
