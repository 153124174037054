import { useMemo } from "react";
import { usePlatformPage } from "../Common/data/usePlatformPage";
import { getCustomBlocksData } from "../Auth/Helpers";
import { useUser } from "../../toolympus/userContext/UserContext";
import { RolesKeys } from "../Auth";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Round } from "../../toolympus/components/Contests/Grid/types";

const usePageConfig = () => {
  const pageInfo = usePlatformPage("participant-round-list-page");
  const messages = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);

    const bailiff = (pageData["bailiff_messages"] || {});
    const arbitrator = (pageData["arbitrator_messages"] || {});
    const team = (pageData["team_messages"] || {});
    const general = (pageData["general_messages"] || {});

    return {
      bailiff,
      arbitrator,
      team,
      general,
    };
  }, [pageInfo.data]);

  return {
    messages,
    isLoading: pageInfo.isLoading,
    state: pageInfo.data.contest_state,
    pageInfo,
  };
}

export type RoundListPageConfig = ReturnType<typeof usePageConfig>;
export type RoundListMessages = RoundListPageConfig["messages"];

const getUserRole = (user: { roles?: string[] } | null) => {
  const roles = user?.roles || [];
  const rolesTest = [RolesKeys.team, RolesKeys.arbitrator, RolesKeys.bailiff];
  for (const role of rolesTest) {
    if(roles.includes(role)) {
      return role;
    }
  }

  return "unknown";
}

const ApiPathByRole = {
  [RolesKeys.team]: "/api/rounds/player/round",
  [RolesKeys.arbitrator]: "/api/rounds/arbiter/round",
  [RolesKeys.bailiff]: "/api/rounds/bailiff/round",
}

export const useParticipantRoundsList = () => {
  const config = usePageConfig();
  const { user } = useUser();
  const role = getUserRole(user);

  const apiPath = ApiPathByRole[role];

  const rounds = useLoadedData<Round[]>(apiPath, [], role !== "unknown");

  const roundsByStage = useMemo(() => {
    return rounds.data.reduce<{ stage: string, rounds: Round[]}[]>((r,round) => {
      const thisStage = r.find(s => s.stage === round.stage_code);
      if(thisStage) {
        thisStage.rounds.push(round);
      } else {
        r.push({ stage: round.stage_code, rounds: [round] });
      }
      return r;
    }, []);
  }, [rounds.data]);


  return {
    config,
    role,
    isLoading: config.isLoading || rounds.isLoading,
    rounds,
    roundsByStage,
  }
}

export type ParticipantRoundsListData = ReturnType<typeof useParticipantRoundsList>;
