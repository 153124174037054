import React from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../theme';
import { css } from '@emotion/react';

const avatarCss = (size: string = "100px") => css`
  width: ${size};
  height: ${size};
  border-radius: 24px;
  box-shadow: 0 0 5px 0 #00000020;
  flex-shrink: 0;
  object-fit: cover;
  object-position: center center;
`;

export const AvatarPlaceholder = styled.div`
  ${avatarCss()}
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  background: ${ThemeSettings.colors.backgroundAlt};
  /* filter: brightness(1.9); */
  color: ${ThemeSettings.colors.background};
  font-size: ${ThemeSettings.typography.fontSizes.h4};

  background: url(/img/pattern07a.jpg);
  background-size: cover;
  background-position: center 25%;
`;

export const AvatarImage = styled.img`
  ${avatarCss()}
`;

interface AvatarProps {
  src?: string;
  alt?: string;
}

export const Avatar = (props: AvatarProps) => {
  return props.src
    ? <AvatarImage src={props.src} alt={props.alt} />
    : <AvatarPlaceholder>{props.alt}</AvatarPlaceholder>;
}
