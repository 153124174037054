import { useMemo } from "react";
import { usePlatformPage } from "../Common/data/usePlatformPage";
import { getCustomBlocksData, processBasicExtraLinks, processExtraLinks } from "../Auth/Helpers";
import { BailiffRegistration } from "./types";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { apiFetch } from "../../toolympus/api/core";

const usePageConfig = () => {
  const pageInfo = usePlatformPage("bailiff-sign-up")
  const config = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);
    const formConfigFields = pageData["bailiff-sign-up-form"]|| {};
    const paneFields = pageData["pane-config"]|| {};

    return {
      formConfig: {
        links: processExtraLinks(formConfigFields.extra_links),
        guide: formConfigFields.guide,
        page_title: formConfigFields.page_title,
        blocked_message: formConfigFields.blocked_message,
        blocked_button: processBasicExtraLinks(formConfigFields.blocked_button).slice(0,1),
        success_message: formConfigFields.success_message,
        is_blocked: false,
      },
      paneConfig: { links: processBasicExtraLinks(paneFields.extra_links) },
    };
  }, [pageInfo.data]);

  return { config, pageInfo, isLoading: pageInfo.isLoading };
}

export const useBailiffSignup = () => {
  const pageConfig = usePageConfig();

  const data = useEditItem2<BailiffRegistration>({
    getApiPath: () => "",
    save: (bailiff) => {
      const signupArbitrator = { ...bailiff };
      delete signupArbitrator.passwordRepeat;
      return apiFetch<{}>("/api/bailiff/signup", "POST", signupArbitrator)
        .then(() => bailiff);
    },
    startWith: {
      first_name: "",
      last_name: "",
      email: "",      
      phone: "",      
      password: "",
      passwordRepeat: "",
    },
    dontResetOnSave: true,
  });

  const passwordsMatch = (!data.item?.password && !data.item?.passwordRepeat) || data.item?.password === data.item?.passwordRepeat;

  const canSubmit = !!data.item?.password && passwordsMatch;

  const blockerMessage = useMemo(() => {
    if(!data.item?.password || !data.item?.passwordRepeat || !data.item.pd_consent
      || !data.item?.email || !data.item?.first_name || !data.item?.last_name) {
        return "please fill out the form to proceed";
    } else if(data.item?.password !== data.item?.passwordRepeat) {
      return "passwords don't match";
    } else {
      return null;
    }
  }, [data.item])

  return {
    ...data,
    isLoading: data.isLoading || pageConfig.isLoading,
    passwordsMatch,
    canSubmit,
    blockerMessage,
    config: pageConfig.config,
    pageInfo: pageConfig.pageInfo,
    isSuccess: !!data.isSaved,
  }
}
