import React, { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

interface PlaceholderElementProps {
  fullWidth?: boolean;
  color?: string;
  noAnimation?: boolean;
}

interface PlaceholderProps {
  style?: React.CSSProperties;
  label?: ReactNode;
}

const PlaceholderWrapper = styled.div<PlaceholderElementProps>`
  width: ${props => props.fullWidth ? "100%" : "auto"};
  background: ${props => props.color || "#00000010"};
  color: #000000c0;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  @keyframes slideuistubshine {
    0% {transform:translateX(-100%);}
    20% {transform:translateX(100%);}
    100% {transform:translateX(100%);}
  }

  &:after {
    content:'';
    display: ${props => props.noAnimation ? "none" : "initial"};
    top:0;
    transform:translateX(100%);
    width:100%;
    height:220px;
    position: absolute;
    z-index:1;
    animation: slideuistubshine 5s infinite;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
  }
`;

export const UIStub = (props: PropsWithChildren<PlaceholderElementProps & PlaceholderProps>) => {
  const { label, children, ...wrapperProps } = props;
  return (
    <PlaceholderWrapper {...wrapperProps}>
      {props.label && <Typography>{props.label}</Typography>}
      {props.children}
    </PlaceholderWrapper>
  );
}
