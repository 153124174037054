import React from 'react';
import { PagePanel } from '../../Common/PageHarness';
import { PageHeader } from '../../Common/PageHeader';
import { useTeamsList } from './useTeamsList';
import { FieldSettingsPopupButton, TableForFields, useFields } from '../../../toolympus/components/schemed';
import { Buttons, Link, PseudoLink2, SearchField } from '../../../toolympus/components/primitives';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';
import { CallMadeOutlined, GetAppOutlined } from '@mui/icons-material';
import { TabButtons } from '../../Common/TabButtons';
import { IconButton, Typography } from '@mui/material';
import { TeamReviewDialog } from './TeamReviewDialog';

export const AdminTeamListPage = () => {
  const data = useTeamsList();
  
  const fields = useFields({
    schema: data.schema,
    defaultFields: [
      "teamnbr",
      "email",
      "contact",
      "university",
      "university_confirmed",

      "registration_complete_at",

      "created_at",
      "is_fee_paid",
      
      // "assistance",
      "members",
      "advisors",
    ],
    extraSettings: {
      registration_complete_at: { utcToLocal: true },
      created_at: { utcToLocal: true },
      tags: { label: "Conflict tags" },
    },
    storageKey: "__j_teams_list_fields",
    whitelist: [
      "_id",
      "teamnbr",
      "email",
      "university",
      "pd_consent",
      "is_fee_paid",

      "is_registration_complete",
      "registration_complete_at",

      "created_at",

      "university_support_ru",
      "university_support_intl",

      "assistance",
      "members",
      "advisors",
      "contact",
      "university_contacts",
      "university_position",
      "tags",
      "memorial_1_id",
      "memorial_2_id",
      "memorials_score",
    ],
    outOfSchemaFields: [
      "tags",
    ],
  });

  return (
    <>
      <PageHeader
        title="Teams"
        isLoading={data.isLoading}
        actions={<>
          <Typography variant="caption">{data.data.length} team{data.data.length === 1 ? "" : "s"}</Typography>
          <SearchField
            autoFocus
            small
            noButton
            queryParam="filter"
            syncQuery
            {...data.filter}
            />
          <ActionTriggerEditButton
            apiPath="/api/actions/trigger"
            triggers={[
              "jessup.core.teams.teams-entitites/team-signed-up",
              "jessup.core.teams.teams-entitites/team-resend-email-confirmation-request",
              "jessup.core.teams.teams-entitites/team-registration-complete",
            ]}
            />
          <FieldSettingsPopupButton fieldsSettings={fields} />
        </>}
      >
        <TabButtons
          currentTab={data.tabs.current}
          selectTab={data.tabs.setCurrent}
          tabs={data.tabs.tabs}
          />
      </PageHeader>

      <PagePanel fillPage noTableStyle noPad fixMaxHeight>
        <TableForFields
          fields={fields.activeFields}
          sorting={data.sorting}
          data={data.data}
          schema={data.schema}
          fieldElement={f => {
            switch(f) {
              case "teamnbr":
                return (row,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing(row)}>{orig}</PseudoLink2>
              case "email":
                return (row,s,orig) => (
                  <Buttons>
                    <PseudoLink2 border onClick={() => data.review.startEditing(row)}>{orig}</PseudoLink2>
                    <Link style={{ border: "none" }} to={`/system/users?filter=${row.email}`}><CallMadeOutlined fontSize="small" /></Link>
                  </Buttons>);

              case "members":
              case "advisors":
              case "university_contacts":
                return (row,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing(row)}>{(row[f] || []).length}</PseudoLink2>
              case "contact":
                return (row,s,orig) => `${row.contact.first_name} ${row.contact.last_name}`
              case "assistance":
                return (row,s,orig) => row.assistance && Object.values(row.assistance).find(a => !!(a as any).is_required) ? "Requested" : "";
              case "tags":
                return (row,s,orig) => <>{data.conflicts.displayForRecord(row._id)}</>;
              case "memorial_1_id":
              case "memorial_2_id":
                return row => row[f] ? <IconButton size="small" color="primary" onClick={() => data.downloadMemorial(row[f] as string)}><GetAppOutlined /></IconButton> : <></>;
            }
          }}
          />
      </PagePanel>

      <TeamReviewDialog data={data} />

      {data.conflicts.popup}
    </>
  );
}
