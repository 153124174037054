import React from 'react';
import { ActionRow, Form, OccupyFreeSpace, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { ContestStateForm } from './ContestStateForm';
import { ConflictTagManagement } from './ConflictTagManagement';
import { useContestStateEdit } from './useContestStateEdit';
import { IconButton } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { TimeslotsConfigurationEditor } from '../../toolympus/components/Contests/Timeslots';
import { ProfileSectionTitle } from '../Common/styles';
import { CourtRoomSettingsForm } from './CourtRoomSettingsForm';

export const ContestControlPage = () => {
    const tabs = useTabsState([
        ["contest-state", "Contest State"],
        ["timeslots", "Timeslots"],
        ["conflict-tags", "Conflict Tags"],
        ["court-room", "Court Room Settings"],
    ], "contest-state", "tab");

    const contestData = useContestStateEdit();
    useSaveable(contestData);

    return (
        <Form title="" noTitle>
            <TabsHeader state={tabs} noMargin />

            <TabPanel state={tabs.forTab("contest-state")}>
              <ContestStateForm data={contestData} />
            </TabPanel>

            <TabPanel state={tabs.forTab("timeslots")}>
              <ActionRow firstItemNoMargin>
                <OccupyFreeSpace />
                <IconButton size="small" color="primary" onClick={() => contestData.save()} style={{ visibility: contestData.hasChanges ? "visible" : "hidden" }}><SaveOutlined /></IconButton>
              </ActionRow>

              <ProfileSectionTitle>Timeslots (preliminary rounds)</ProfileSectionTitle>

              <TimeslotsConfigurationEditor
                value={contestData.settings.timeslots || []}
                update={v => contestData.update({ timeslots: v })}
                />

              <ProfileSectionTitle>Timeslots (advanced rounds)</ProfileSectionTitle>

              <TimeslotsConfigurationEditor
                value={contestData.settings.timeslots_advanced || []}
                update={v => contestData.update({ timeslots_advanced: v })}
                />
            </TabPanel>

            <TabPanel state={tabs.forTab("conflict-tags")}>
              <ConflictTagManagement />
            </TabPanel>

            <TabPanel state={tabs.forTab("court-room")}>
              <CourtRoomSettingsForm />
            </TabPanel>
        </Form>
    );
}
