import React from 'react';
import { AssistanceEditor } from './AssistanceEditor';
import { FormGrid } from '../../../toolympus/components/primitives';
import { TeamProfileData } from './useTeamProfile';

interface Props {
  canEdit?: boolean;
  assistance: TeamProfileData["assistance"];
}

export const TeamAssitanceForm = (props: Props) => {
  const profile = props;

  return (
    <FormGrid columns="1fr">
      <AssistanceEditor
        noDetail
        data={{ is_required: profile.assistance.assistance.none_required }}
        title="Our team"
        yesLabel="Does not require any assistance"
        noLabel="Requires assistance"
        update={c => profile.assistance.updateNoneRequired(!!c.is_required)}
        canEdit={profile.canEdit}
        />

      <AssistanceEditor
        data={profile.assistance.assistance.getting_to_participate}
        update={c => profile.assistance.updateItem("getting_to_participate", c)}
        canEdit={profile.canEdit}
        title="Assistance with getting your Team to participate"
        options={[
          ["technical", "Technical (e.g. you do not know how to do the Jessup; you do not know if your potential members are eligible, etc.)"],
          ["policy", "Policy (e.g. your University needs convincing to allow you to participate or is unaware of what the Jessup is and needs more information)"],
          ["other", "Other"],
        ]}
        commentHint="Please provide details of any concerns about your Team participating in the Jessup and any ideas you have of what may help:"
        />

      <AssistanceEditor
        data={profile.assistance.assistance.fee}
        update={c => profile.assistance.updateItem("fee", c)}
        optionsLabel="Reason(s):"
        options={[
          ["technical", "Technical (the University will be ready to pay, but cannot wire US Dollars to ILSA / requires an invoice from ILSA / can pay in Russian Roubles to the National Administration but requires an invoice from a legal entity; etc.)"],
          ["policy", "Policy (the University may not be ready to pay the registration fee for the Jessup for policy reasons)"],
          ["other", "Other"],
        ]}
        title="Assistance with paying the $350 registration fee to ILSA"
        commentHint="Please substantiate your request by providing reasons why you expect to be unable to solve the issue yourselves:"
        />

      <AssistanceEditor
        data={profile.assistance.assistance.preparation}
        update={c => profile.assistance.updateItem("preparation", c)}
        canEdit={profile.canEdit}
        options={[
          ["coach_memorials", "Need a coach for the memorials stage"],
          ["coach_oral", "Need a coach for the oral rounds"],
        ]}
        title="Assistance with preparing for the Jessup"
        commentHint="Please describe who you are looking for, if you have an understanding:"
        />

      <AssistanceEditor
        data={profile.assistance.assistance.participation_ru}
        update={c => profile.assistance.updateItem("participation_ru", c)}
        canEdit={profile.canEdit}
        title="Assistance with participating in the Jessup Russia Rounds"
        commentHint="Please describe any assistance you may require to participate in the oral rounds on 5-11 February 2024, and substantiate your request by providing reasons why you expect to be unable to solve the issue yourselves:"
        />
      

    </FormGrid>
  );
}
