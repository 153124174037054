import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { LegacyContentProps } from './types';
import { Typography } from '@mui/material';
import { LegacyPerson } from '../../../LegacyInfo';
import { PseudoLink2, useCopyText } from '../../../../toolympus/components/primitives';

const PeopleList = styled.ul`
  padding-left: 1rem;
`;

export const PeopleDisplay = (props: LegacyContentProps & { title: ReactNode }) => {
  const people: LegacyPerson[] = (props.block.content.items || []).filter((p: LegacyPerson) => !!p.last_name);
  const copyText = useCopyText();

  if(!people.length) {
    return null;
  }

  return (<>
    <Typography variant="h4">{props.title}</Typography>
    <PeopleList>
      {people.map(person => (
        <li key={person._id}>{person.first_name} {person.last_name}{(person.email || person.phone) ? " – " : ""}{person.email && <PseudoLink2 onClick={() => copyText(person.email || "")}>{person.email}</PseudoLink2>}{person.email && person.phone ? "," : ""} {person.phone && <PseudoLink2 onClick={() => copyText(person.phone || "")}>{person.phone}</PseudoLink2>}</li>
      ))}
    </PeopleList>
  </>);
}
