import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';

import { theme } from './theme';
import { GlobalStyles } from './components/Common/GlobalStyles';
import { ApiConfgurationProvider } from './toolympus/components/ApiConfguration';
import { ActionsConfigProvider } from './toolympus/components/Actions/ActionsConfigProvider';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';
import { ConfirmationDialogProvider } from './toolympus/components/primitives';
import { JessupMessages } from './jessup.messages';

interface Props {
    children?: ReactNode;
}

export const Providers = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="en" defaultLocale="en" messages={JessupMessages}>
                    <FormatsProvider>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                          <ApiConfgurationProvider
                            actionsApiPath="/api/actions"
                            emailTemplatesApiPath="/api/emails">
                              <ConfirmationDialogProvider>
                                <ActionsConfigProvider>
                                  <DictionariesProvider apiPath="/api/settings/dictionaries">
                                    <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                                      <GlobalStyles />
                                      {props.children}
                                    </DatesLocalizationProvider>
                                  </DictionariesProvider>
                                </ActionsConfigProvider>
                              </ConfirmationDialogProvider>
                          </ApiConfgurationProvider>
                        </SnackbarProvider>
                    </FormatsProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
