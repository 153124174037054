import React from 'react';
import styled from '@emotion/styled';
import { Buttons } from '../../../toolympus/components/primitives';
import { BiStar, BiSolidStar } from "react-icons/bi";
import { BaseBiIconProps } from "../../Common/Icons";
import { ThemeSettings } from '../../../theme';

interface Props {
  value: number | null | undefined;
  update: (v: number) => void;
  maxValue?: number;
}

interface DisplayProps {
  justify?: React.CSSProperties["justifyContent"];
}

const Wrapper = styled(Buttons)<DisplayProps>`
  gap: 0.1rem;
  justify-content: ${props => props.justify || "initial"};
  & > * {
    cursor: pointer;
  }
`;

const IconProps = { ...BaseBiIconProps, style: { ...BaseBiIconProps.style, width: 16, height: 16 }};

export const RankStars = (props: Props & DisplayProps) => {
  const { value, update, maxValue, justify } = props;
  const items = new Array(maxValue || 3).fill(0).map((_,i) => i+1);
  return (
    <Wrapper justify={justify}>
      {items.map(i => (
        value && value >= i
          ? <BiSolidStar {...IconProps} onClick={() => update(i)} color={ThemeSettings.colors.primary} />
          : <BiStar {...IconProps} onClick={() => update(i)} color={ThemeSettings.colors.primary} />
      ))}
    </Wrapper>
  );
}
