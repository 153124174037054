import { useMemo } from "react";
import { usePlatformPage } from "../Common/data/usePlatformPage";
import { getCustomBlocksData, processBasicExtraLinks, processExtraLinks } from "../Auth/Helpers";
import { login, useUser } from "../../toolympus/userContext/UserContext";
import { useHistory } from "react-router-dom";
import { ArbitratorRegistration } from "./types";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { apiFetch } from "../../toolympus/api/core";

const usePageConfig = () => {
  const pageInfo = usePlatformPage("arbitrator-sign-up")
  const config = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);
    const formConfigFields = pageData["arbitrator-sign-up-form"]|| {};
    const paneFields = pageData["pane-config"]|| {};

    return {
      formConfig: {
        links: processExtraLinks(formConfigFields.extra_links),
        guide: formConfigFields.guide,
        page_title: formConfigFields.page_title,
        blocked_message: formConfigFields.blocked_message,
        blocked_button: processBasicExtraLinks(formConfigFields.blocked_button).slice(0,1),
        is_blocked: !(pageInfo.data as any).contest_state?.arbitrator_registration_allowed,
      },
      paneConfig: { links: processBasicExtraLinks(paneFields.extra_links) },
    };
  }, [pageInfo.data]);

  return { config, pageInfo, isLoading: pageInfo.isLoading };
}

export const useArbitratorSignup = () => {
  const user = useUser();
  const history = useHistory();

  const pageConfig = usePageConfig();
  

  const data = useEditItem2<ArbitratorRegistration>({
    getApiPath: () => "",
    save: (arbitrator) => {
      const signupArbitrator = { ...arbitrator };
      delete signupArbitrator.passwordRepeat;
      return apiFetch<{ token: string }>("/api/arbitrator/signup", "POST", signupArbitrator)
        .then(withToken => {
          login(user, withToken.token);
          history.push("/arbitrator/profile");
          return arbitrator;
        })
    },
    startWith: {
      title: "",
      first_name: "",
      last_name: "",
      email: "",      
      password: "",
      passwordRepeat: "",
    },
    dontResetOnSave: true,
  });

  const passwordsMatch = (!data.item?.password && !data.item?.passwordRepeat) || data.item?.password === data.item?.passwordRepeat;

  const canSubmit = !!data.item?.password && passwordsMatch;

  const blockerMessage = useMemo(() => {
    if(!data.item?.password || !data.item?.passwordRepeat || !data.item.pd_consent
      || !data.item?.email || !data.item?.first_name || !data.item?.last_name) {
        return "please fill out the form to proceed";
    } else if(data.item?.password !== data.item?.passwordRepeat) {
      return "passwords don't match";
    } else {
      return null;
    }
  }, [data.item])

  return {
    ...data,
    isLoading: data.isLoading || pageConfig.isLoading,
    passwordsMatch,
    canSubmit,
    blockerMessage,
    config: pageConfig.config,
    pageInfo: pageConfig.pageInfo,
  }
}
