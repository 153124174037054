import React from 'react';
import { useAdminRoundData } from './useParticipantRoundData';
import { ParticipantRoundPage } from './ParticipantRoundPage';

interface Props {
  stage: string;
  roundId: string;
}

export const AdminRoundDetails = (props: Props) => {
  const data = useAdminRoundData(props.stage, props.roundId);
  return (
    <ParticipantRoundPage
      data={data}
      isBailif
      />
  );
}
