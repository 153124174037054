import { useMemo } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { getCustomBlocksData } from "../../Auth/Helpers";
import { usePlatformPage } from "../../Common/data/usePlatformPage";
import { LegacyBlockTypes, LegacyInfo } from "../../LegacyInfo"
import { PanelBlockElementType } from "../../../toolympus/components/PowerDoc/plugins/Panel/PanelBlockElement";

const isNoDataPanel = (b: any) => b.type === PanelBlockElementType && b.display_type === "no-data";

const usePageConfig = () => {
  const pageInfo = usePlatformPage("team-legacy-page");
  const config = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);
    const noDataPanel = ((pageInfo.data.item.content as any)?.blocks || []).find(isNoDataPanel);

    const filteredContent = { blocks: ((pageInfo.data.item.content as any)?.blocks || []).filter((b: any) => !isNoDataPanel(b))}

    return {
      messages: pageData["page-messages"] || {},
      noDataPanel,
      filteredContent,
    };
  }, [pageInfo.data]);

  return {
    config,
    isLoading: pageInfo.isLoading,
    state: pageInfo.data.contest_state,
    pageInfo,
  };
}

interface YearLegacy {
  year: string;
  blocks: LegacyInfo[];
}

const BlocksOrder = {
  [LegacyBlockTypes.comment]: 10,
  [LegacyBlockTypes.membersadvisors]: 19,
  [LegacyBlockTypes.members]: 20,
  [LegacyBlockTypes.advisors]: 30,
  [LegacyBlockTypes.documents]: 40,
}

export const useTeamLegacy = () => {
  const data = useLoadedData<LegacyInfo[]>("/api/legacy-info/team", []);
  const pageConfig = usePageConfig();

  const legacy = useMemo(() => {
    const byYear = data.data.reduce<YearLegacy[]>((r,block) => {
      const year = r.find(y => y.year === block.year);
      if(!year) {
        r.push({ year: block.year, blocks: [block] })
      } else {
        year.blocks.push(block);
      }
      return r;
    }, []);
    
    byYear.forEach(y => {
      const members = y.blocks.find(b => b.block_type === LegacyBlockTypes.members);
      const advisors = y.blocks.find(b => b.block_type === LegacyBlockTypes.advisors);
      if(members && advisors) {
        y.blocks = [
          ...y.blocks.filter(b => b !== members && b !== advisors),
          { _id: 9000, block_type: LegacyBlockTypes.membersadvisors, university: members.university, year: members.year, content: { members, advisors }},
        ]
      }
    })
    byYear.forEach(y => { y.blocks.sort((a,b) => (BlocksOrder[a.block_type] || 100) < (BlocksOrder[b.block_type] || 100) ? -1 : 1); });
    byYear.sort((a,b) => a.year > b.year ? -1 : 1);
    return byYear;
  }, [data.data]);

  return {
    ...data,
    data: legacy,
    pageConfig,
  }
}
