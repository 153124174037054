import React from 'react';
import { LegacyContentProps } from './types';
import { PowerEditorBase } from '../../../../toolympus/components/PowerDoc';

export const CommentDisplay = (props: LegacyContentProps) => {
  return (
    <PowerEditorBase
      content={props.block.content.comment}
      update={() => {}}
      viewMode
      placeholder=" "
      />
  );
}
