import React, { useMemo } from 'react';
import { ContentMessagePanel } from '../Common';
import { PagePanel, PagePanelMessage } from '../Common/PageHarness';
import { PageHeader } from '../Common/PageHeader';
import { PageViewer } from '../Common/PageViewer';
import { usePlatformPageWithPanels } from '../Common/data/usePlatformPage';
import { useCrudItem } from '../../toolympus/api/useSimpleCrud';
import { Arbitrator } from './types';
import { Button } from '@mui/material';
import { getCustomBlocksData } from '../../toolympus/components/CMS/Usage';
import { downloadFile } from '../../toolympus/api/core';
import { BiDownload } from 'react-icons/bi';
import { BaseBiIconSmallProps } from '../Common/Icons';
import { RoundPanelMessage } from '../Rounds/RoundPages/RoundPagesComponents';

interface Props {
  
}

const usePage = () => {
  const data = usePlatformPageWithPanels("arbitrator-home-page");

  const messages = useMemo(() => {
    const pageData = getCustomBlocksData((data.data.item.content as any)?.blocks);

    return (pageData["page-messages"] || {});

  }, [data.data]);

  const profile = useCrudItem<Arbitrator>("/api/arbitrator/profile/extra", {
    defaultValue: {} as Arbitrator,
  });

  const benchMemo = {
    isAvailable: data.state.arbitrator_bench_memo_available && data.state.arbitrator_bench_memo_file_id && profile.data.is_excluded === false,
    response: profile.data.is_bench_memo_requested,
    isResponded: !!profile.data.is_bench_memo_requested,
    isRequested: profile.data.is_bench_memo_requested === "yes",
    isLoading: profile.isLoading || data.isLoading,
    respond: (answer: "yes" | "no") => {
      profile.save({ is_bench_memo_requested: answer });
    },
    download: () => {
      if(profile.data.is_bench_memo_requested === "yes"
        && profile.data.is_excluded === false
        && data.state.arbitrator_bench_memo_available
        && data.state.arbitrator_bench_memo_file_id) {
        downloadFile(`/api/arbitrator/documents/${data.state.arbitrator_bench_memo_file_id}`);
      }
    }
  }

  return {
    ...data,
    messages,
    panels: data.messages,
    profile,
    benchMemo,
  }
}

export const ArbitratorHomePage = (props: Props) => {
  const data = usePage();
  
  return (
    <>
      <PageHeader title={data.page.title} isLoading={data.isLoading || data.profile.isLoading} />
      
      {data.panels.map((m,idx) => (
        <ContentMessagePanel key={`${data.page._id}__${idx}`} block={m} />
      ))}

      {data.benchMemo.isAvailable && !data.benchMemo.isResponded &&
        <PagePanelMessage
          invert
          actions={<>
            <Button style={{ color: "inherit"}} onClick={() => data.benchMemo.respond("yes")}>{data.messages?.bench_memo_question_yes || "yes"}</Button>
            <Button style={{ color: "inherit"}} onClick={() => data.benchMemo.respond("no")}>{data.messages?.bench_memo_question_no || "no"}</Button>
          </>}>
            <RoundPanelMessage
              title={data.messages?.bench_memo_question_title}
              message={data.messages?.bench_memo_question_message}
              />
        </PagePanelMessage>}

      {data.benchMemo.isAvailable && data.benchMemo.response === "yes" &&
        <PagePanelMessage
          invert
          onClick={() => data.benchMemo.download()}
          actions={<>
            <Button style={{ color: "inherit"}} startIcon={<BiDownload {...BaseBiIconSmallProps} />}>download</Button>
          </>}>
            <RoundPanelMessage
              title={data.messages?.bench_memo_requested_title}
              message={data.messages?.bench_memo_requested_message}
              />
        </PagePanelMessage>}

      {data.benchMemo.isAvailable && data.benchMemo.response === "no" &&
        <PagePanelMessage invert>
          <RoundPanelMessage
            title={data.messages?.bench_memo_rejected_title}
            message={data.messages?.bench_memo_rejected_message}
            />
        </PagePanelMessage>}

      <PagePanel fillPage={data.page._id}>
        <PageViewer content={data.page.content} key={data.page._id} />
      </PagePanel>
    </>
  );
}
