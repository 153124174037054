import React from 'react';
import { Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { EditOutlined, Telegram, WhatsApp } from '@mui/icons-material';
import { FormGrid, Tooltip } from '../../../toolympus/components/primitives';
import { Person, Team } from '../types';
import { ValidationErrors } from '../../../toolympus/components/schemed';

interface Props {
  data: Team;
  canEditProfile?: boolean;
  errors?: ValidationErrors;
  updatePrimaryContact: (c: Partial<Person>) => void;
  startChangeEmail?: (team: Team) => void;
}

export const TeamPrimaryContactForm = (props: Props) => {
  const profile = props;
  return (
    <FormGrid suppressContentColumnsOnSm>
      <Typography variant="h4" style={{ gridColumn: "1 / span 3"}}>
        Primary contact
      </Typography>

      <Typography variant="caption" style={{ gridColumn: "1 / span 3"}}>
        If you need to change your primary (login) email, please contact the organizer.
      </Typography>

      <TextField
        label="Email"
        type="email"
        required
        value={profile.data.contact.email || ""}
        InputProps={{
          readOnly: true,
          endAdornment: props.startChangeEmail
            ? <InputAdornment position="end"><IconButton size="small" onClick={() => props.startChangeEmail && props.startChangeEmail(profile.data)}><EditOutlined /></IconButton></InputAdornment>
            : null,
        }}
        error={profile.errors?.fieldHasErrors("email")}
        />

      <TextField
        label="Phone"
        required
        value={profile.data.contact.phone || ""}
        InputProps={{
          readOnly: !profile.canEditProfile,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip text={`Telegram ${profile.data.contact?.telegram_available ? "" : "not "}available`}><IconButton size="small" color={profile.data.contact?.telegram_available ? "primary" : undefined} onClick={() => profile.canEditProfile && profile.updatePrimaryContact({ telegram_available: !profile.data.contact?.telegram_available })}><Telegram /></IconButton></Tooltip>
              <Tooltip text={`WhatsApp ${profile.data.contact?.whatsapp_available ? "" : "not "}available`}><IconButton size="small" color={profile.data.contact?.whatsapp_available ? "primary" : undefined} onClick={() => profile.canEditProfile && profile.updatePrimaryContact({ whatsapp_available: !profile.data.contact?.whatsapp_available })}><WhatsApp /></IconButton></Tooltip>
            </InputAdornment>
          )
        }}
        error={profile.errors?.fieldHasErrors("phone")}
        onChange={(e) => profile.updatePrimaryContact({ phone: e.target.value })}
        />

      <div />

      
      
      <TextField
        label="First name"
        required
        value={profile.data.contact.first_name || ""}
        InputProps={{ readOnly: !profile.canEditProfile }}
        onChange={e => profile.updatePrimaryContact({ first_name: e.target.value})}
        error={profile.errors?.fieldHasErrors("first_name")}
        />

      <TextField
        label="Last name"
        required
        value={profile.data.contact.last_name || ""}
        InputProps={{ readOnly: !profile.canEditProfile }}
        onChange={e => profile.updatePrimaryContact({ last_name: e.target.value})}
        error={profile.errors?.fieldHasErrors("last_name")}
        />
    </FormGrid>
  );
}
