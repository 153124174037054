import React, { ReactNode } from 'react';
import { DeleteButton, Dialog, FormGrid } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { EditItemProps, NewItemProps } from '../../toolympus/api/useNewItem';
import { Schema } from '../../toolympus/hooks/useSchema';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LegacyInfo } from './useAdminLegacyInfoList';
import { LegacyInfoContentEditor } from './LegacyInfoContentEditor';
import { VisibilityOff, VisibilityOutlined } from '@mui/icons-material';

interface EditDialogProps {
  title: ReactNode;
  mode: "create" | "edit";
  data: EditItemProps<LegacyInfo> | NewItemProps<Partial<LegacyInfo>, LegacyInfo>;
  schema: Schema;
  remove?: (id: number) => Promise<any>;
}

export const EditLegacyInfoDialog = (props: EditDialogProps) => {
  const { data, title, schema, remove } = props;
  return (
    <Dialog
      dialogTitle={title}
      isOpen={data.isEditing}
      close={() => data.cancel()}
      titleActions={<>
        <Button
          size="small"
          startIcon={data.item?.is_hidden_from_public ? <VisibilityOff /> : <VisibilityOutlined />}
          onClick={() => data.update({ is_hidden_from_public: !data.item?.is_hidden_from_public })}
          >
          {data.item?.is_hidden_from_public ? "visible to team only" : "visible to public"}
        </Button>
        {remove &&
          <DeleteButton
            remove={() => { data.cancel(); return remove(data.item?._id || -1)}}
            title="Delete item?"
            preventGoBack
            size="small"
          />}
      </>}
      actions={<>
        <Button onClick={() => data.cancel()}><FormattedMessage id="common.cancel" /></Button>
        <Button color="primary" variant="contained" onClick={() => data.save()}><FormattedMessage id="common.save" /></Button>
      </>}
      noSubmitOnEnter
      submitOnModEnter
      maxWidth="md"
      fullWidth
      noFullscreen>
      {data.item && <>
        <FormGrid columns="100px 1fr 150px" forceEvenColumns noMargin>
          <FormControlsForFields
            data={data.item}
            schema={schema}
            fields={[
              ["year", { controlProps: { autoFocus: true }}],
              ["university", { autoComplete: true }],
              ["block_type", { autoComplete: true }],
            ]}
            onChange={(o,c) => data.update(c)}
            />
        </FormGrid>

        <LegacyInfoContentEditor block={data.item as LegacyInfo} update={data.update} />
        
      </>}
    </Dialog>
  )
}
