import React from 'react';
import styled from '@emotion/styled';

const LogoTag = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 20px;
    opacity: 1;
`;

export const Logo = (props: {isMobile?: boolean}) => (
    <LogoTag src={props.isMobile ? "/img/jessup_logo_gr.png" : "/img/jessup_logo_vert_gr.png"} alt="Jessup Russia" />
);