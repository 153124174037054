import React from 'react';
import styled from '@emotion/styled';
import { Player } from '../../../toolympus/components/Contests/Grid/types';
import { Person, Team } from '../../Teams/types';
import { Dialog, FormGrid, useDialogState } from '../../../toolympus/components/primitives';
import { ContactsLine, StatusChip, StatusChipWaitingIcon } from './ArbitratorDisplay';
import { Button, InputAdornment, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Buttons } from '../../../toolympus/components/PowerDoc/elements/Common';
import { BiCheck, BiFile, BiPencil } from 'react-icons/bi';
import { BaseBiIconProps, BaseBiIconSmallProps } from '../../Common/Icons';
import { ThemeSettings } from '../../../theme';
import { TeamTiming } from '../useRoundInfoEdit';
import { useUser } from '../../../toolympus/userContext/UserContext';
import { RoundMessages } from './useParticipantRoundData';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { RoundRole } from './RoundPagesComponents';
import { ScoreRoundData } from '../../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode';
import { BailiffTeamTimingData, TeamsTimingData } from './useTeamsTiming';


export const DocumentsWrapper = styled(Buttons)`
  justify-content: flex-start;
  gap: 1rem;

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    flex-flow: column;
    align-items: flex-start;
    gap: 0.25rem;
  }
`;

const Document = styled(Buttons)`
  & .${BaseBiIconProps.className} {
    color: ${ThemeSettings.colors.primary};
  }
  cursor: pointer;
  user-select: none;
  gap: 0.25rem;
`;
Document.defaultProps = { role: "button" };


interface Props {
  team: Player;
  position: number;
  contact?: Person;
  members?: Person[];
  advisors?: Person[];
  timing?: TeamTiming;
  isTimingHidden?: boolean;
  startEditTiming?: () => void;
  startEditTimingBailiff?: (teamId: string) => void;
  documents?: Pick<Team, "memorial_1_id" | "memorial_2_id">;
  isContactAvailable?: boolean;
  isScoringAvailable?: boolean;
  messages: RoundMessages;
  downloadMemorial: (fileId: string) => void;
  iAm: RoundRole;
  scoreRound?: ScoreRoundData;
}

export const TimingButtons = styled(Buttons)`
  justify-content: flex-start;
`;

export const TimingDisplay = styled(FormGrid)`
  align-items: baseline;

  & strong {
    text-align: right;
  }

  & .MuiButton-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    grid-template-columns: 1fr max-content;
  }
`;

TimingDisplay.defaultProps = { columns: "repeat(4,max-content)", gap: "dense" };

const ScoringButton = (props: { speaker: 1 | 2 } & Pick<Props, "position" | "isScoringAvailable" | "scoreRound">) => {
  if(!props.isScoringAvailable || !props.scoreRound) {
    return null;
  }

  const scoreGroup = props.scoreRound?.scoresGrouped[props.speaker - 1];
  const score = props.position === 1 ? scoreGroup?.left : scoreGroup?.right;

  if(!score) {
    return null;
  }

  return (
    <Button
      size="small"
      color="primary"
      variant={score.is_scored ? "text" : "contained"}
      endIcon={score.is_scored ? <BiCheck {...BaseBiIconSmallProps} /> : undefined}
      onClick={() => props.scoreRound?.startScoring(score)}>
      <FormattedMessage id={score.is_scored ? "contests.rounds.scores.scored" : "contests.rounds.scores.score"} />
    </Button>);
}

const TeamTimingControl = (props: Pick<Props, "team" | "timing" | "startEditTiming" | "startEditTimingBailiff" | "messages" | "position" | "isTimingHidden" | "isScoringAvailable" | "scoreRound">) => {
  const { user } = useUser();
  const isSelf = user?._id === props.team?._id;
  const isProvided = !!props.timing;

  if(isProvided && props.isTimingHidden) {
    return <TimingButtons><StatusChip label={props.messages.team.timing_waiting_hidden} icon={<StatusChipWaitingIcon />} /></TimingButtons>
  }
  else if(isProvided) {
    return <>
      <TimingDisplay>
        <span>{props.messages.team[`timing_team${props.position}_speaker1_label`]}:</span>
        <strong>{props.timing?.speaker_1.time || "-"}m</strong>
        <span>({props.timing?.speaker_1?.speaker_name || ""})</span>
        <div><ScoringButton speaker={1} position={props.position} isScoringAvailable={props.isScoringAvailable} scoreRound={props.scoreRound} /></div>
        
        <span>{props.messages.team[`timing_team${props.position}_speaker2_label`]}:</span>
        <strong>{props.timing?.speaker_2.time || "-"}m</strong>
        <span>({props.timing?.speaker_2?.speaker_name || ""})</span>
        <div><ScoringButton speaker={2} position={props.position} isScoringAvailable={props.isScoringAvailable} scoreRound={props.scoreRound} /></div>
        
        <span>{props.messages.team[`timing_team${props.position}_rebuttal_label`]}:</span>
        <strong>{props.timing?.rebuttal.time || "-"}m</strong>
        <span></span>
        <span></span>
      </TimingDisplay>
      {!!props.startEditTimingBailiff &&
        <TimingButtons>
          <Button size="small" color="primary" startIcon={<BiPencil {...BaseBiIconSmallProps} />} onClick={() => props.startEditTimingBailiff && props.startEditTimingBailiff(props.team._id)}>
            edit timing
          </Button>
        </TimingButtons>}
    </>
  } else if(isSelf) {
    return (
      <TimingButtons>
        <Button size="small" color="primary" startIcon={<BiPencil {...BaseBiIconSmallProps} />} onClick={() => props.startEditTiming && props.startEditTiming()}>
          edit timing
        </Button>
      </TimingButtons>)
  } else {
    return (
      <TimingButtons>
        <StatusChip label={props.messages.team.timing_waiting} icon={<StatusChipWaitingIcon />} />
        {!!props.startEditTimingBailiff &&
            <Button size="small" color="primary" startIcon={<BiPencil {...BaseBiIconSmallProps} />} onClick={() => props.startEditTimingBailiff && props.startEditTimingBailiff(props.team._id)}>
              {props.messages.team.timing_provide}
            </Button>}
      </TimingButtons>)
  }

}

export const TeamInfoDisplay = (props: Props) => {
  const rosterDialogState = useDialogState();
  return (
    <>
      <TeamTimingControl {...props} />
      
      {props.documents && <DocumentsWrapper>
        {props.documents.memorial_1_id && !(props.position === 2 && props.iAm === "team1" ) &&
          <Document onClick={() => props.downloadMemorial(props.documents?.memorial_1_id as string)}>
            <BiFile {...BaseBiIconProps} /> <span>{props.messages.team.memorial_1_label}</span>
          </Document>}
        {props.documents.memorial_2_id && !(props.position === 1 && props.iAm === "team2" ) &&
          <Document onClick={() => props.downloadMemorial(props.documents?.memorial_2_id as string)}>
            <BiFile {...BaseBiIconProps} /> <span>{props.messages.team.memorial_2_label}</span>
          </Document>}
      </DocumentsWrapper>}
      
      {props.isContactAvailable && props.contact &&
        <ContactsLine
          before={`Contact: ${props.contact.first_name} ${props.contact.last_name}`}
          email={props.contact.email}
          phone={props.contact.phone}
          after={props.members && <Button size="small" onClick={() => rosterDialogState.open()}>see roster</Button>}
          />}
      
      {props.isContactAvailable && props.members &&
        <Dialog
          {...rosterDialogState}
          noFullscreen
          dialogTitle={`Team ${props.team?.display_name || "..."} Roster`}
          actions={<Button onClick={rosterDialogState.close}><FormattedMessage id="common.close" /></Button>}>

            <Typography variant="h6">Members</Typography>
            <ol style={{ paddingLeft: "1.2rem" }}>
              {(props.members || []).map(m => <li key={m._id}><ContactsLine before={`${m.first_name} ${m.last_name}`} email={m.email} phone={m.phone} /></li>)}
            </ol>
            <Typography variant="h6">Advisors</Typography>
            <ol style={{ paddingLeft: "1.2rem" }}>
              {(props.advisors || []).map(m => <li key={m._id}><ContactsLine before={`${m.first_name} ${m.last_name}`} email={m.email} phone={m.phone} /></li>)}
            </ol>
            {!props.advisors?.length && <Typography>-</Typography>}
        </Dialog>}
    </>
  );
}

const TimingEditGrid = styled(FormGrid)`
  align-items: baseline;
`;
TimingEditGrid.defaultProps = { columns: "max-content 3fr 1fr" };

export const EditTimingDialog = (props: { data: TeamsTimingData | BailiffTeamTimingData, messages: RoundMessages }) => {
  const { data, messages } = props;

  const timingTotal = (data.item?.speaker_1?.time || 0) + (data.item?.speaker_2?.time || 0) + (data.item?.rebuttal?.time || 0);
  const speakersSelected = data.item?.speaker_1?.speaker_id && data.item?.speaker_2?.speaker_id;

  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      noFullscreen
      dialogTitle={messages.team.timing_edit_dialog_title}
      actions={<>
        <Button onClick={() => data.cancel()}><FormattedMessage id={data.hasChanges ? "common.cancel" : "common.close"} /></Button>
        {data.hasChanges && <Button color="primary" variant="contained" disabled={timingTotal !== 45 || !speakersSelected} onClick={() => data.save()}><FormattedMessage id="common.submit" /></Button>}
      </>}>

      <Typography style={{ whiteSpace: "pre-line" }}>
        {messages.team.timing_edit_dialog_guide}
      </Typography>

      {data.item && <TimingEditGrid>
        <Typography>{messages.team[`timing_team${data.position}_speaker1_label`]}</Typography>
        <FormControlsForFields
          data={data.item.speaker_1}
          errors={data.errors.getInnerErrors(["speaker_1"])}
          onChange={(o,c) => data.update({ speaker_1: { ...o, ...c } })}
          schema={data.schema}
          fields={[
            ["speaker_id"],
            ["time", { controlProps: {
              InputProps: {
                endAdornment: <InputAdornment position="end"><Typography>m</Typography></InputAdornment>
              }
            }}],
          ]}
          />

        <Typography>{messages.team[`timing_team${data.position}_speaker2_label`]}</Typography>
        <FormControlsForFields
          data={data.item.speaker_2}
          errors={data.errors.getInnerErrors(["speaker_2"])}
          onChange={(o,c) => data.update({ speaker_2: { ...o, ...c } })}
          schema={data.schema}
          fields={[
            ["speaker_id"],
            ["time", { controlProps: {
              InputProps: {
                endAdornment: <InputAdornment position="end"><Typography>m</Typography></InputAdornment>
              }
            }}],
          ]}
          />

        <Typography>{messages.team[`timing_team${data.position}_rebuttal_label`]}</Typography>
        <div></div>
        <FormControlsForFields
          data={data.item.rebuttal}
          errors={data.errors.getInnerErrors(["rebuttal"])}
          onChange={(o,c) => data.update({ rebuttal: { ...o, ...c } })}
          schema={data.schema}
          fields={[
            ["time", { controlProps: {
              InputProps: {
                endAdornment: <InputAdornment position="end"><Typography>m</Typography></InputAdornment>
              }
            }}],
          ]}
          />

        <Typography style={{ fontStyle: "italic" }}>{messages.team[`timing_total_label`]}</Typography>
        <div></div>
        <FormControlsForFields
          data={{ time: timingTotal }}
          onChange={(o,c) => data.update({ rebuttal: { ...o, ...c } })}
          schema={data.schema}
          fields={[
            ["time", { controlProps: {
              readOnly: true,
              InputProps: {
                endAdornment: <InputAdornment position="end"><Typography>m</Typography></InputAdornment>
              }
            }}],
          ]}
          />

        </TimingEditGrid>}
    </Dialog>
  )
}