import React from 'react';
import { ItemEditorProps, ListEditor } from '../../../toolympus/components/primitives/ListEditor';
import { ContentEditorProps } from './types';
import { generateCode } from '../../../toolympus/components/PowerDoc/plugins/common';
import { FormGrid } from '../../../toolympus/components/primitives';
import { FormControlsForFields, PlaceholderField } from '../../../toolympus/components/schemed';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../../theme';
import { LegacyPerson } from '../useAdminLegacyInfoList';

const PersonSchema: Schema = {
  first_name: { label: "First name" },
  last_name: { label: "Last name" },
  email: { label: "E-mail" },
  phone: { label: "Phone" },
  is_contact_disclosed: { label: "Contact disclosed", type: FieldType.bool },
}

const PersonWrapper = styled(FormGrid)`
  align-items: end;
  border-left: 2px solid ${ThemeSettings.colors.tableBorder};
  padding-left: 20px;
`;
PersonWrapper.defaultProps = { columns: "1fr 1fr 1fr", gap: "dense", noMargin: true }

const ListWrapper = styled.div`
`;

const PersonEditor = (props: ItemEditorProps<LegacyPerson>) => {
  return <PersonWrapper>
    <FormControlsForFields
      data={props.item}
      onChange={(o,c) => props.update(c)}
      schema={PersonSchema}
      fields={[
        ["first_name"],
        ["last_name"],
        [PlaceholderField],
        ["email"],
        ["phone"],
        ["is_contact_disclosed"],
      ]}
      />
  </PersonWrapper>
}

export const PeopleEditor = (props: ContentEditorProps ) => {
  return (
    <ListEditor
      idField="_id"
      items={props.block.content?.items}
      createDflt={() => ({ _id: generateCode() })}
      update={items => props.update({ content: { ...props.block?.content, items }})}
      itemEditor={PersonEditor}
      wrapperComponent={ListWrapper}        
      />
  );
}
