import React from 'react';
import { ContentEditorProps } from './types';
import { FormGrid } from '../../../toolympus/components/primitives';
import { PowerEditorBase } from '../../../toolympus/components/PowerDoc';


export const CommentEditor = (props: ContentEditorProps) => {
  return (
    <FormGrid columns="1fr">
      <PowerEditorBase
        content={props.block.content?.comment}
        update={v => props.update({ content: { ...props.block.content, comment: v }})}
        autoFocus
        />
    </FormGrid>
  );
}
