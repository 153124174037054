import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../theme';

const spinner = keyframes`
  from {
    transform: rotate(45deg);
  }

  to {
    transform: rotate(405deg);
  }
`;

export const Spinner = styled.div<{ color?: string, size?: number }>`
  margin: auto;
  height: ${props => props.size || 30}px;
  width: ${props => props.size || 30}px;
  border-left: ${props => (props.size || 30) >= 50 ? 5 : 3}px solid ${props => props.color || ThemeSettings.colors.primary};
  border-bottom: ${props => (props.size || 30) >= 50 ? 5 : 3}px solid ${props => props.color || ThemeSettings.colors.primary};
  border-right: ${props => (props.size || 30) >= 50 ? 5 : 3}px solid ${props => props.color || ThemeSettings.colors.primary};
  border-top: ${props => (props.size || 30) >= 50 ? 5 : 3}px solid transparent;
  border-radius: 50%;
  animation: ${spinner} 1s ease infinite;
`;
