import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import AppRouter from './AppRouter';

import { Providers } from './Providers';
import { TeamSignupPage } from './components/Auth/TeamSignupPage';
import { LogInPage } from './components/Auth/LoginPage';
import { ResetPasswordPage } from './components/Auth/ResetPasswordPage';
import { SetPasswordPage } from './components/Auth/SetPasswordPage';
import { EmailConfirmationPage } from './components/Auth/EmailConfirmationPage';
import { ArbitratorSignupPage } from './components/Arbitrators';
import moment from 'moment';
import { BailiffSignupPage } from './components/Bailiffs';

const App: React.FC = () => {
    moment.locale('en')
    return (
        <Providers>
            <Router>
                <ErrorBoundary>
                    <Switch>
                        <Route path='/signup' component={TeamSignupPage} />
                        <Route path='/signup-judge' component={ArbitratorSignupPage} />
                        <Route path='/signup-bailiff' component={BailiffSignupPage} />
                        <Route path='/login' component={LogInPage} />
                        <Route path='/confirm-email' component={EmailConfirmationPage} />
                        <Route path='/reset-password' component={ResetPasswordPage} />
                        <Route path='/restore-password' component={SetPasswordPage} />
                        <Route path='/' component={AppRouter} />
                    </Switch>
                </ErrorBoundary>
            </Router>
        </Providers>
    );
}

export default App;