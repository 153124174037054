import React, { useState } from 'react';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
// import { SchemaProvider } from './toolympus/hooks/useSchema';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import styled from '@emotion/styled';
import { ThemeSettings } from './theme';
import { RolesKeys } from './components/Auth';
import { BiExit } from 'react-icons/bi';
import { BaseBiIconProps } from './components/Common/Icons';
import { useMediaBreakpoint } from './toolympus/components/primitives';

const MenuPaper = styled(DrawerPaperView)`
  & .MuiDrawer-paper {
    background-color: ${ThemeSettings.colors.background};
    color: ${ThemeSettings.colors.shade};
  }
  & > .MuiPaper-root {
    box-shadow: ${props => props.shadow ? `-8px 0px 25px 3px #33333330` : 'none'};
  }

  & .MuiButtonBase-root {
    width: 60px;
    
    & .MuiListItemIcon-root {
      min-width: 0;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      width: 100%;
      & .MuiListItemIcon-root {
        margin-right: 1rem;
      }
    }
  }

  & .MuiListItem-root {
    &:hover {
      & .MuiListItemIcon-root {
        color: ${ThemeSettings.colors.primary};
      }
    }
  }

  & .MuiListItemIcon-root {
    color: ${ThemeSettings.colors.shadeLight};
    
    & .MuiSvgIcon-root, & .gr-icon {
      opacity: 0.75;
      color: inherit;

      & path {
        stroke: currentColor;
      }
    }

    &:hover {
      color: ${ThemeSettings.colors.primary};
      & .MuiSvgIcon-root, & .gr-icon:hover {
        color: inherit;
      }
    }
  }

`;

const AppContentWrapper = styled(AppContentSimple)`
  background: url(/img/bg_squares_even_x.png), linear-gradient(90deg, ${ThemeSettings.colors.backgroundAlt} 0%, ${ThemeSettings.colors.backgroundAltLighter} 30%);
  padding: 0 ${ThemeSettings.sizes.spacing.contentSides.l} ${ThemeSettings.sizes.spacing.contentBottom.l};

  ${props => props.theme.breakpoints.down("sm")} {
    padding: 0 ${ThemeSettings.sizes.spacing.contentSides.s} ${ThemeSettings.sizes.spacing.contentBottom.s};
  }
  
  &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: ${ThemeSettings.colors.backgroundAlt};
  }
  &::-webkit-scrollbar-thumb {
      background-color: ${ThemeSettings.colors.primary};
  }
`;

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const isMobile = useMediaBreakpoint("down", "sm");
    const [isMobileMenuVisible,setIsMobileMenuVisible] = useState<boolean>(false);

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><BiExit {...BaseBiIconProps} /></ListItemIcon>
                {/* <ListItemText primary='Выход' /> */}
            </ListItem>
        </List>
    )

    const notFoundRedirect = (user?.roles || []).includes(RolesKeys.admin)
      ? "/contest"
      : (user?.roles || []).includes(RolesKeys.team)
        ? "/team/home"
        : (user?.roles || []).includes(RolesKeys.arbitrator)
          ? "/judge/home"
          : (user?.roles || []).includes(RolesKeys.bailiff)
            ? "/bailiff/home"
            : "/contest";


    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
            {/* <SchemaProvider> */}
                        <AppWrapper>
                            <Sidebar
                                top={<Logo isMobile={isMobile} />}
                                menu={<Menu menuItems={menuItems} iconsOnly={!isMobile} />}
                                bottom={bottom}
                                open={!isMobile || isMobileMenuVisible}
                                onClose={() => setIsMobileMenuVisible(x => !x)}
                                drawerPaperProps={{ shadow: true }}
                                drawerPaperComponent={MenuPaper}
                                width={isMobile ? undefined : 60}
                            />
                            <AppContentWrapper fit100Height id="appcontentwrapper">
                                <Routes routes={routes} notFoundRedirect={notFoundRedirect} />
                            </AppContentWrapper>
                        </AppWrapper>
            {/* </SchemaProvider> */}
        </UserContext.Provider>
    );
}

export default AppRouter;

