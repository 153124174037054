import React, { useEffect, useState } from 'react';
import { FrontPageFormHelpText, FrontPageFormTitle, FrontPageFormWrapperForm } from './FrontPages';
import { FrontPageHarness } from './FrontPageHarness';
import { Spinner } from '../Common/Spinner';
import { useItemActionWithConfirmation } from '../../toolympus/api/useAction';
import { apiFetch } from '../../toolympus/api/core';
import { useHistory } from 'react-router-dom';

interface EmailConfirmation {
  email: string;
  code: string;
  type: string;
}

const useEmailConfirmation = () => {
  const [success,setSuccess] = useState<boolean>(false);
  const history = useHistory();
  const confirmEmail = useItemActionWithConfirmation(
    (item: EmailConfirmation) => apiFetch<{}>(
      `/api/${item.type}/confirm-email`,
      "post",
      { email: item.email, code: item.code },
      { noAuth: true })
      .then(x => { setSuccess(true); return x; }).catch(() => ({})),
    {
      skipConfirmation: true,
    }
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    const code = params.get("code");
    const type = params.get("type") || "team";
    if(email && code) {
      confirmEmail.run({ email, code, type });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(success) {
      setTimeout(() => history.push("/"), 3000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return {
    success,
    isRunning: confirmEmail.isRunning,
  }
}

export const EmailConfirmationPage = () => {
  const data = useEmailConfirmation();
  
  return (
    <FrontPageHarness>
    
      <FrontPageFormWrapperForm
        dim={data.isRunning}
        style={{ alignSelf: "center" }}>

        {data.isRunning && <Spinner size={60} />}

        <FrontPageFormTitle><span>Email confirmation</span></FrontPageFormTitle>

        {data.success && <FrontPageFormHelpText>Your email has been confirmed, thank you!</FrontPageFormHelpText>}
        {!data.success && !data.isRunning && <FrontPageFormHelpText>Something went wrong. Please check that you use the correct link or request a new one on the profile page.</FrontPageFormHelpText>}

      </FrontPageFormWrapperForm>
    </FrontPageHarness>
  );
}
