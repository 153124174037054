import { apiFetch } from "../../toolympus/api/core"
import { useActionWithConfirmation } from "../../toolympus/api/useAction"

export const usePrepareRoundsFeedback = () => {
  return useActionWithConfirmation(
    () => apiFetch("/api/rounds-info/feedback/prepare", "post"),
    {
      title: "Prepare rounds feedback sheets for teams",
      confirmationHint: "If you already ran this procedure this year, the existing feedback sheets will be overwritten.",
    }
  );
}
