import React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { Refresh, Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator } from '../../toolympus/components/primitives';
import { FloatingButton, PageHeaderMessage, PagePanel, PagePanelMessage } from '../Common/PageHarness';
import { PageHeader } from '../Common/PageHeader';
import { useBailiffProfile } from './useBailiffProfile';
import { FormGrid } from '../../toolympus/components/primitives';



interface Props {
  
}

export const BailiffProfilePage = (props: Props) => {
  const profile = useBailiffProfile();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      {profile.hasChanges &&
        <FloatingButton
          color="primary"
          variant="contained"
          startIcon={profile.isLoading ? <LoadingIndicator color="inherit" sizeVariant="s" /> : <Save />}
          onClick={() => profile.save()}
          >
          <FormattedMessage id="common.save" />
        </FloatingButton>
        }

      <PageHeader title={"Bailiff profile"} isLoading={profile.isLoading || profile.pageConfig.isLoading}>
        {profile.pageConfig.config.messages?.header_message &&
          <PageHeaderMessage>{profile.pageConfig.config.messages?.header_message}</PageHeaderMessage>}
      </PageHeader>
      
      {profile.isProfileLoaded && !profile.data.is_email_confirmed &&
        <PagePanelMessage actions={(
          <Button
            startIcon={<Refresh />}
            color="primary"
            endIcon={profile.resendConfirmationEmail.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
            onClick={() => profile.resendConfirmationEmail.run().then(() => enqueueSnackbar("Email sent", { variant: "success", autoHideDuration: 5000 }))}>
            Re-send confirmation email
          </Button>)}>

          <Typography variant="h4">{profile.pageConfig.config.messages?.confirm_email_title}</Typography>
          <Typography>{profile.pageConfig.config.messages?.confirm_email_message}</Typography>
        </PagePanelMessage>}


      <PagePanel fillPage={`${profile.isProfileLoaded}`}>
        <FormGrid gap="dense" columns="1fr 1fr 1fr" noMargin style={{ alignItems: "end" }} suppressContentColumnsOnSm>
          <TextField
            label="First name"
            value={profile.data?.first_name || ""}
            onChange={e => profile.update({ first_name: e.target.value })}
            error={profile.errors?.fieldHasErrors("first_name")}
            required
            />
          
          <TextField
            label="Last name"
            value={profile.data?.last_name || ""}
            onChange={e => profile.update({ last_name: e.target.value })}
            error={profile.errors?.fieldHasErrors("last_name")}
            required
            />

          <div />

          <TextField
            label="Email"
            required
            value={profile.data?.email || ""}
            InputProps={{ readOnly: true }}
            onChange={e => {}}
            error={profile.errors?.fieldHasErrors("email")}
            />
          
          <TextField
            label="Phone"
            required
            value={profile.data?.phone || ""}
            onChange={e => profile.update({ phone: e.target.value })}
            error={profile.errors?.fieldHasErrors("phone")}
            />

          <div />
          
          <Typography variant="caption" style={{ gridColumn: "1 / span 3"}}>
            If you need to change your primary (login) email, please contact the organizer.
          </Typography>
        </FormGrid>

      </PagePanel>
    </>
  );
}
