import React from 'react';
import { useArbitratorRoundData } from './useParticipantRoundData';
import { ParticipantRoundPage } from './ParticipantRoundPage';
import { useParams } from 'react-router-dom';

interface Props {
  
}

export const ArbitratorRoundPage = (props: Props) => {
  const { roundid } = useParams<{ roundid: string }>();
  const data = useArbitratorRoundData(roundid);
  return (
    <ParticipantRoundPage data={data} isArbitrator />
  );
}
