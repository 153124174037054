import { useMemo, useState } from "react";
import { useCrudItem } from "../../../toolympus/api/useSimpleCrud"
import { Person, Team, UniversityContact } from "../types"
import { TabStatus } from "../../Common/TabButtons";
import { apiFetch, apiUploadFile } from "../../../toolympus/api/core";
import { useAction } from "../../../toolympus/api/useAction";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { getCustomBlocksData } from "../../Auth/Helpers";
import { PanelBlockElementType } from "../../../toolympus/components/PowerDoc/plugins/Panel/PanelBlockElement";
import { useEditArray } from "../../../toolympus/hooks/useEditArray";
import { generateCode } from "../../../toolympus/components/PowerDoc/plugins/common";
import { ValidationErrors } from "../../../toolympus/components/schemed";
import { FileInfo } from "../../../toolympus/components/files";
import { EmptyPlatformPageData, PlatformPageData } from "../../Common/data/usePlatformPage";

const getProfileTabsStatus = (profile: Team, errors?: ValidationErrors): Record<string, TabStatus> => {
  const result: Record<string, TabStatus> = {};
  const errorsStatus: Record<string, TabStatus> = {};
  
  if(errors?.hasErrors) {
    if(errors?.getInnerErrors(["contact"])?.hasErrors
      || errors?.fieldHasErrors("teamnbr")) {
      errorsStatus["contact"] = "error";
    }
    if(errors?.getInnerErrors(["members"])?.hasErrors
      || errors?.getInnerErrors(["advisors"])?.hasErrors) {
      errorsStatus["roster"] = "error";
    }
    if(errors?.getInnerErrors(["university_contacts"])?.hasErrors
      || errors?.fieldHasErrors("university")
      || errors?.fieldHasErrors("university_address")
      || errors?.fieldHasErrors("university_position")) {
      errorsStatus["university"] = "error";
    }
  }
  
  if(profile.is_registration_complete) {
    return {
      contact: "complete",
      university: "complete",
      roster: "complete",
      assistance: "complete",
      ...errorsStatus,
    };
  }

  const status = profile.status_info;

  if(!status) {
    return errorsStatus;
  }

  result["contact"] = (status.contact || status.teamnbr || status.is_fee_paid) ? "outstanding" : "complete";
  result["university"] = (status.university || status.university_address || status.university_position || status.university_contacts)
    ? "outstanding" : "complete";
  result["roster"] = (status.members || status.advisors) ? "outstanding" : "complete";
  result["assistance"] = (status.assistance) ? "outstanding" : "complete";
  
  return {
    ...result,
    ...errorsStatus,
  };
}

const usePageConfig = () => {
  const pageInfo = useLoadedData<PlatformPageData>("/api/public/cms/platform_page/team-profile-page", EmptyPlatformPageData);
  const config = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);

    const guidePanel = ((pageInfo.data.item.content as any)?.blocks || []).find((b: any) => b.type === PanelBlockElementType && b.display_type === "guide");
    const guideContent = guidePanel && guidePanel.content;

    const messages = (pageData["page-messages"] || {});

    const tabsOrder = (messages.tabs_order || "").split(",").map(s => s.trim());
    const tabs = (tabsOrder.length ? tabsOrder : ["contact", "university", "roster", "assistance", "guide"])
      .map(t => [t, messages[`tab_${t}`]] as [string,string])
      .filter(([t,label]) => t !== "guide" || !!guideContent);

    return {
      messages: pageData["page-messages"],
      guideContent,
      tabs,
    };
  }, [pageInfo.data]);

  return {
    config,
    isLoading: pageInfo.isLoading,
    state: pageInfo.data.contest_state,
    pageInfo,
  };
}


export const TeamProfileApiPath = "/api/team/profile";

export const TeamDefault: Team = {
  _id: "",
  email: "",
  teamnbr: "",
  contact: { email: "", first_name: "", last_name: "", phone: "" },
}

export const useTeamProfile = () => {
  const pageConfig = usePageConfig();
  const data = useCrudItem<Team>(TeamProfileApiPath, {
    defaultValue: TeamDefault,
  });

  const universityContacts = useEditArray<UniversityContact>({
    dflt: {},
    createDflt: () => ({ _id: generateCode() }),
    items: data.data.university_contacts || [{ _id: generateCode() }],
    update: v => data.update({ university_contacts: v }),
  });

  const members = useEditArray<Person>({
    dflt: { email: "", first_name: "", last_name: "", phone: "" },
    createDflt: () => ({ _id: generateCode(), email: "", first_name: "", last_name: "", phone: "" }),
    items: data.data.members || [{ _id: generateCode(), email: "", first_name: "", last_name: "", phone: "" }],
    update: v => data.update({ members: v }),
  });

  const advisors = useEditArray<Person>({
    dflt: { email: "", first_name: "", last_name: "", phone: "" },
    createDflt: () => ({ _id: generateCode(), email: "", first_name: "", last_name: "", phone: "" }),
    items: data.data.advisors || [{ _id: generateCode(), email: "", first_name: "", last_name: "", phone: "" }],
    update: v => data.update({ advisors: v }),
  });

  const assistanceInfo = data.data.assistance || {};

  const assistance = {
    assistance: assistanceInfo,
    updateItem: (k: string, c: Partial<{ is_required: boolean, comment: string }>) => data.update({ assistance: { ...assistanceInfo, ...(c.is_required ? { none_required: false } : {}),  [k]: { ...(assistanceInfo[k] || {}), ...c }}}),
    updateNoneRequired: (v: boolean) => data.update({ assistance: v ? { none_required: v } : { ...assistanceInfo, none_required: v }}),
  }

  const profileStatus = useMemo(
    () => getProfileTabsStatus(data.data, data.errors),
    [data.data, data.errors]);

  const doResendConfirmationEmail = () => apiFetch<{}>("/api/team/resend-email-confirmation", "POST");
  const resendConfirmationEmail = useAction(doResendConfirmationEmail);

  const updatePrimaryContact = (changes: Partial<Person>) => data.update({ contact: { ...data.data.contact, ...changes }});


  const isProfileLoaded = !!data.data._id;

  const canEditProfile = !!pageConfig.state.team_profile_edit_allowed;

  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false);
  const uploadAvatar = (file: File): Promise<FileInfo> => {
    setIsAvatarUploading(true);
    return apiUploadFile("/api/team/avatar", "post", "file", file)
      .then(data => {
        setIsAvatarUploading(false);
        return data.data;
      })
      .catch(e => {
        setIsAvatarUploading(false);
        throw e;
      })
  }
  const avatars = {
    isAvatarUploading,
    uploadAvatar,
  }

  return {
    pageConfig,

    ...data,
    updatePrimaryContact,
    universityContacts,
    members,
    advisors,
    assistance,

    isProfileLoaded,
    canEditProfile,
    profileStatus,
    resendConfirmationEmail,

    avatars,
  }
}

export type TeamProfileData = ReturnType<typeof useTeamProfile>;
