import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PagePanel } from '../../Common/PageHarness';
import { PageHeader } from '../../Common/PageHeader';
import { RoundExt, useAdminRoundsInfoList } from './useAdminRoundsInfoList';
import { TabButtons } from '../../Common/TabButtons';
import { TableForFields, useFormats } from '../../../toolympus/components/schemed';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { Buttons, Dialog, FormGrid, LoadingIndicator, PseudoLink2, Tooltip } from '../../../toolympus/components/primitives';
import { ThemeSettings } from '../../../theme';
import { StatusChipCheckIcon, StatusChipWaitingIcon } from './ArbitratorDisplay';
import { BailiffsProvider, useApprovedBailiffs } from '../BailiffsProvider';
import { BiErrorAlt, BiNetworkChart } from 'react-icons/bi';
import { BaseBiIconProps } from '../../Common/Icons';
import { Button, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { CallMadeOutlined, Refresh } from '@mui/icons-material';
import { AdminRoundDetails } from './AdminRoundDetails';
import { RoundInfo } from '../useRoundInfoEdit';
import { StageScoringStatusData } from './useScoringStatus';
import { RoundStatusChip, getAdminRoundStatus } from './RoundStatus';


const ArbStatusBlip = styled.span<{ status?: "good" | "warning" | "bad" | "unknown" }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 4px;
  background-color: ${props => ThemeSettings.colors.status[props.status || "warning"]};
  color: ${ThemeSettings.colors.textLight};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  user-select: none;  
`;

const ArbitratorStatus = (props: { pos: number, round: RoundExt }) => {
  const arb = props.round.arbiters[props.pos];
  const checkin = (props.round.roundInfo?.checkins || {})[arb?._id || ""];
  const status = arb && checkin ? "good" : "warning";
  const blip = <Tooltip text={arb ? arb.display_name : "not assigned"}>
    <ArbStatusBlip status={status}>
      {checkin
        ? <StatusChipCheckIcon />
        : arb
          ? <StatusChipWaitingIcon />
          : "..."}
    </ArbStatusBlip>
  </Tooltip>

  return arb
    ? <Link target="_blank" style={{ border: "none" }} to={`/admin/judges?filter=${arb._id}`}>{blip}</Link>
    : blip;
}

const ArbitratorsStatus = (props: { round: RoundExt }) => {
  return <Buttons>
    {[1,2,3].map(apos => <ArbitratorStatus key={apos} pos={apos} round={props.round} />)}
  </Buttons>
}

const BailiffStatus = (props: { round: RoundExt }) => {
  const bailiffs = useApprovedBailiffs();
  useEffect(() => {
    bailiffs.ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bailiffId = props.round.roundInfo?.bailiff_id;
  const bailiff = bailiffs.data.find(b => b._id === bailiffId);
  const checkin = (props.round.roundInfo?.checkins || {})[bailiffId || ""];
  const status = bailiff && checkin ? "good" : "warning";

  return <Buttons>
    <Tooltip text={bailiff ? `${bailiff.first_name} ${bailiff.last_name}` : "not assigned"}>
      <ArbStatusBlip status={status}>
        {checkin
          ? <StatusChipCheckIcon />
          : bailiff
            ? <StatusChipWaitingIcon />
            : "..."}
      </ArbStatusBlip>
    </Tooltip>
    {bailiffId ? <Link target="_blank" style={{ border: "none" }} to={`/admin/bailiffs?filter=${bailiffId}`}><CallMadeOutlined fontSize="small" /></Link> : null}
  </Buttons>;
}

const TeamDisplay = (props: { pos: number, round: RoundExt }) => {
  const team = (props.round.players || {})[props.pos];
  const timing = (props.round.roundInfo?.teams_timing || {})[team?._id];

  return (
    <Buttons>
      <span>{team?.display_name || "..."}</span>
      <Tooltip text={timing ? "timing provided" : "timing not provided"}>
        <ArbStatusBlip status={timing ? "good" : "warning"}>
          {timing ? <StatusChipCheckIcon /> : <StatusChipWaitingIcon />}
        </ArbStatusBlip>
      </Tooltip>
      {team ? <Link target="_blank" style={{ border: "none" }} to={`/admin/teams?filter=${team._id}`}><CallMadeOutlined fontSize="small" /></Link> : null}
    </Buttons>
  )
}

const RoundStatusWrapper = styled(Buttons)<{ status: "good" | "bad" | "alert"}>`
  color: ${props => props.status === "alert" ? ThemeSettings.colors.primary : "inherit"};
  cursor: pointer;
  user-select: none;
`;
RoundStatusWrapper.defaultProps = { role: "button" };

const RoundStatusAdm = (props: { round: RoundExt, showHelpRequests: (round: RoundExt) => void, scoringStatus: StageScoringStatusData, }) => {
  const isHelpRequested = !!props.round.roundInfo?.help_requests?.length;

  if(isHelpRequested) {
    return (
      <RoundStatusWrapper status="alert" onClick={() => props.showHelpRequests(props.round)}>
        <IconButton size="small" color="primary"><BiErrorAlt {...BaseBiIconProps} /></IconButton> <span>help requested</span>
      </RoundStatusWrapper>
    )
  }

  const status = getAdminRoundStatus({
    round: props.round,
    roundInfo: props.round.roundInfo,
    scoringStatus: props.scoringStatus,
  });

  return <RoundStatusChip status={status} />
}


const ScoringStatus = (props: { round: RoundExt, scoringStatus: StageScoringStatusData }) => {
  return <Buttons>
    {[1,2,3].map(apos => {
      const arb = props.round.arbiters[apos];
      const scoringStatus = props.scoringStatus.getStatus(props.round._id, arb?._id || "");
      
      return (
        <Tooltip text={arb ? `${arb.display_name}: ${scoringStatus?.scored_cnt || 0} of ${props.scoringStatus.scoresPerArbPerRound} scores` : "not assigned"}>
          <ArbStatusBlip
            status={!arb || !scoringStatus
              ? "unknown"
              : scoringStatus.scored_cnt === 0
                ? "bad"
                : scoringStatus.is_fully_scored
                  ? "good"
                  : "warning"}>
            {!arb || !scoringStatus ? "?" : scoringStatus.scored_cnt}
          </ArbStatusBlip>
        </Tooltip>)
    })}
  </Buttons>
}



const HelpRequestsWrapper = styled(FormGrid)`
  & > * {
    padding: 0.5rem 0;

    &:not(:last-child) {
      border-bottom: 3px solid ${ThemeSettings.colors.tableBorderLight};
    }
  }
`;
HelpRequestsWrapper.defaultProps = { columns: "1fr", noMargin: true };



export const AdminRoundsInfoListPageUnwrapped = () => {
  const data = useAdminRoundsInfoList();
  const { formatDatetimeShort } = useFormats();

  const [detailsRoundId, setDetailsRoundId] = useState<string | null>(null);
  const [helpRequestRound, setHelpRequestRound] = useState<RoundExt | null>(null);

  return (<>
    <PageHeader
        title="Rounds status"
        isLoading={data.isLoading}
        actions={<>
          <Button
            startIcon={data.scoringStatus.isLoading ? <LoadingIndicator sizeVariant="s" color="primary" /> : <Refresh />}
            onClick={() => data.scoringStatus.reload()}>
              reload scoring status
          </Button>
          <Link to="/admin/rounds"><Button color="primary" startIcon={<BiNetworkChart {...BaseBiIconProps} />}>grid & assignments</Button></Link>
        </>}
      >
        <TabButtons
          currentTab={data.tabs.current}
          selectTab={data.tabs.setCurrent}
          tabs={data.tabs.tabs}
          />
      </PageHeader>

      <PagePanel fillPage noTableStyle noPad fixMaxHeight>
        <TableForFields
          data={data.rounds}
          schema={{
            time: { type: FieldType.datetime },
          }}
          fields={[
            ["number", { label: "#" }],
            ["title", { label: "Round" }],
            ["status", { label: "Status" }],
            ["time", { label: "Time" }],
            ["bailiff", { label: "Bailiff" }],
            ["team1", { label: "Team 1"}],
            ["team2", { label: "Team 2"}],
            ["arbitrators", { label: "Judges" }],
            ["scoring", { label: "Scoring" }],
          ]}
          fieldElement={f => {
            switch(f) {
              case "title":
                return (row,s,orig) => <PseudoLink2 onClick={() => setDetailsRoundId(row._id)}>{orig}</PseudoLink2>;
              case "time":
                return (row) => <>{formatDatetimeShort(row.time)}</>;
              case "team1":
                return row => <TeamDisplay round={row} pos={1} />;
              case "team2":
                return row => <TeamDisplay round={row} pos={2} />;
              case "arbitrators":
                return row => <ArbitratorsStatus round={row} />;
              case "bailiff":
                return row => <BailiffStatus round={row} />;
              case "scoring":
                return row => <ScoringStatus round={row} scoringStatus={data.scoringStatus} />
              case "status":
                return row => <RoundStatusAdm round={row} showHelpRequests={r => setHelpRequestRound(r)} scoringStatus={data.scoringStatus} />;
            }
          }}
          />
      </PagePanel>


      <Dialog
        isOpen={!!detailsRoundId}
        close={() => setDetailsRoundId(null)}
        maxWidth="lg"
        fullWidth
        dialogTitle=" ">
          <AdminRoundDetails stage={data.tabs.current} roundId={detailsRoundId || ""} />
      </Dialog>


      <Dialog
        isOpen={!!helpRequestRound}
        close={() => setHelpRequestRound(null)}
        maxWidth="sm"
        fullWidth
        noFullscreen
        dialogTitle={`Help requested: ${helpRequestRound?.title || "..."}`}>
          {helpRequestRound && <HelpRequestsWrapper>
            {(helpRequestRound.roundInfo?.help_requests || []).map((issue,idx) => (
              <Typography key={idx} style={{ whiteSpace: "pre-line" }}>
                {issue}
                <Button
                  size="small"
                  color="primary"
                  disabled={data.clearHelpRequest.isRunning}
                  endIcon={data.clearHelpRequest.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
                  onClick={() => data.clearHelpRequest.run({ roundInfo: helpRequestRound.roundInfo as RoundInfo, idx: idx})
                    .then(ri => {
                      if(ri.help_requests?.length) {
                        setHelpRequestRound(x => x ? { ...x, roundInfo: ri } : x);
                      } else {
                        setHelpRequestRound(null);
                      }
                      return ri;
                    })}>
                  clear
                </Button>
              </Typography>
            ))}
            </HelpRequestsWrapper>}
      </Dialog>
  </>);
}

export const AdminRoundsInfoListPage = () => {
  return <BailiffsProvider>
    <AdminRoundsInfoListPageUnwrapped />
  </BailiffsProvider>
}
