import React from 'react';
import styled from '@emotion/styled';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { ThemeSettings } from '../../../theme';

const Wrapper = styled.div`
  padding-left: 20px;
  border-left: 2px solid ${ThemeSettings.colors.tableBorder};
  margin-bottom: 2rem;

  display: flex;
  flex-flow: column;
  gap: 0.5rem;  

  & .options {
    margin-left: 20px;
  }

  & .MuiFormLabel-root {
    color: inherit;
  }
`;

export interface Assistance {
  is_required?: boolean;
  option?: string;
  options?: Record<string, boolean>;
  comment?: string;
}

interface Props {
  noDetail?: boolean;
  data: Assistance;
  update: (changes: Partial<Assistance>) => void;
  title: string;
  options?: [string,string][];
  optionsLabel?: string;
  commentHint?: string;
  canEdit?: boolean;

  yesLabel?: string;
  noLabel?: string;
}

export const AssistanceEditor = (props: Props) => {
  return (
    <Wrapper>
      <FormControl>
        <FormLabel>{props.title}</FormLabel>
        <RadioGroup row value={props.data?.is_required ? "t" : "f"} onChange={(e,v) => props.update({ is_required: v === "t" })}>
          <FormControlLabel value="t" control={<Radio color="primary" readOnly={!props.canEdit} />} label={props.yesLabel || "Required (expected to be required)"} />
          <FormControlLabel value="f" control={<Radio color="primary" readOnly={!props.canEdit} />} label={props.noLabel || "Not required (not expected to be required)"} />
        </RadioGroup>
      </FormControl>

      {!props.noDetail && props.data?.is_required && props.options && props.options.length > 0 &&
        <FormControl className="options">
          <FormLabel>{props.optionsLabel}</FormLabel>
          <FormGroup>
            {props.options.map(([o,label]) => (
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={props.data.options && props.data.options[o] ? true : false}
                    onChange={(e,v) => props.canEdit ? props.update({ options: { ...(props.data.options || {}), [o]: v }}) : null}
                    />)}
                label={label}
                />
            ))}
          </FormGroup>
        </FormControl>}

      {!props.noDetail && props.data?.is_required && props.commentHint && <Typography>
        {props.commentHint}
        </Typography>}

      {!props.noDetail && props.data?.is_required &&
        <TextField
          label="Comments"
          className="comment"
          multiline
          fullWidth
          required
          value={props.data?.comment || ""}
          InputProps={{ readOnly: !props.canEdit }}
          onChange={e => props.update({ comment: e.target.value })}
          />}
    </Wrapper>
  );
}
