import { useEffect } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { socketByToken, useWebSocket } from "../../../toolympus/hooks/useWebsocket";
import { RoundInfo } from "../useRoundInfoEdit";

export const useRoundInfo = (apiPath: string, doLoad: boolean) => {
  const roundInfo = useLoadedData<RoundInfo>(apiPath, {} as RoundInfo, doLoad);

  const { socket, open } = useWebSocket(
    socketByToken(
      `/rounds-info/participant/${roundInfo.data.stage}/${roundInfo.data._id}/live-updates/token-participant`,
      token => 'rounds-info/live-updates?token=' + token),
    () => {},
    !(doLoad && roundInfo.data._id && roundInfo.data.stage));

  const onMessage = (env: MessageEvent) => {
    const data = JSON.parse(env.data);
    if (data.round_id === roundInfo.data._id && data.changes) {
        if(data._type === "round_updated") {
          (roundInfo.setData as any)((x: any) => ({ ...x, ...data.changes }));
        }
    }
  }

  useEffect(() => {
    if(socket) {
        socket.addEventListener("message", onMessage);
        return () => socket.removeEventListener("message", onMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return {
    roundInfo,
  }
}