import React from 'react';
import { FrontPageFormHelpText, FrontPageFormTitle, FrontPageFormWrapperForm, FrontPagePrimaryButton } from './FrontPages';
import { FormGrid } from '../../toolympus/components/primitives';
import { TextField } from '@mui/material';
import { useResetPassword } from '../../toolympus/hooks/auth';
import { FrontPageHarness } from './FrontPageHarness';
import { Spinner } from '../Common/Spinner';


export const ResetPasswordPage = () => {
  const { email, updateEmail, restore, isSaving, isSaved } = useResetPassword("/api/restore-access/request");
  
  return (
    <FrontPageHarness>
    
      <FrontPageFormWrapperForm
        onSubmit={e => { if(!isSaved) { e.preventDefault(); restore(); } }}
        dim={isSaving}
        style={{ alignSelf: "center" }}>

        {(isSaving) && <Spinner size={60} />}

        <FrontPageFormTitle><span>Reset password</span></FrontPageFormTitle>

        {!isSaved && <FrontPageFormHelpText>
          Please enter your email.<br/>We will send you a link to reset your password.
        </FrontPageFormHelpText>}


        <FormGrid columns="1fr" noMargin>
          <TextField
            label="Email"
            value={email}
            type="email"
            onChange={e => updateEmail(e.target.value)}
            disabled={isSaved}
            required
            />

          {isSaved && <FrontPageFormHelpText>
            The email is on its way.<br/>Please check your inbox and follow the instructions to set a new password.
          </FrontPageFormHelpText>}

          <FrontPagePrimaryButton type='submit'>
            submit
          </FrontPagePrimaryButton>

        </FormGrid>
      </FrontPageFormWrapperForm>
    </FrontPageHarness>
  );
}
