import React, { ReactNode } from 'react';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { RadioWrapper } from './RadioOptions';


interface Props {
  label?: ReactNode;
  value?: boolean;
  update: (v: boolean) => void;
  readOnly?: boolean;
  error?: boolean;

  labelTrue?: ReactNode;
  labelFalse?: ReactNode;
  trueOnly?: boolean;
  wrapperStyle?: React.CSSProperties;
}

export const RadioCheckbox = (props: Props) => {
  return (
    <RadioWrapper error={props.error} style={props.wrapperStyle}>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <RadioGroup row value={props.value ? "t" : "f"} onChange={(e,v) => props.update(v === "t")}>
        <FormControlLabel value="t" control={<Radio color="primary" readOnly={!props.readOnly} />} label={props.labelTrue || "Yes"} />
        {!props.trueOnly && <FormControlLabel value="f" control={<Radio color="primary" readOnly={!props.readOnly} />} label={props.labelFalse || "No"} />}
      </RadioGroup>
    </RadioWrapper>
  );
}
