import React from 'react';
import { Button, Typography } from '@mui/material';
import { Check, ErrorOutline, Refresh, Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { useArbitratorProfile } from '../useArbitratorProfile';
import { LoadingIndicator, TabPanel, useTabsState } from '../../../toolympus/components/primitives';
import { FloatingButton, PageHeaderMessage, PagePanel, PagePanelMessage } from '../../Common/PageHarness';
import { PageHeader } from '../../Common/PageHeader';
import { TabButtons } from '../../Common/TabButtons';
import { ArbitratorPersonalInfoForm } from './ArbitratorPersonalInfoForm';
import { ArbitratorExperienceForm } from './ArbitratorExperienceForm';
import { ArbitratorAvailabilityForm } from './ArbitratorAvailabilityForm';
import { ArbitratorConflictsForm } from './ArbitratorConflictsForm';



interface Props {
  
}

export const ArbitratorProfilePage = (props: Props) => {
  const profile = useArbitratorProfile();

  const tabs = useTabsState(profile.pageConfig.config.tabs, "personal_info", "tab", "_j_arbitrator_profile_tab");

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      {profile.hasChanges &&
        <FloatingButton
          color="primary"
          variant="contained"
          startIcon={profile.isLoading ? <LoadingIndicator color="inherit" sizeVariant="s" /> : <Save />}
          onClick={() => profile.save()}
          >
          <FormattedMessage id="common.save" />
        </FloatingButton>
        }

      <PageHeader title={"Judge profile"} isLoading={profile.isLoading || profile.pageConfig.isLoading}>
        {profile.pageConfig.config.messages?.header_message &&
          <PageHeaderMessage>{profile.pageConfig.config.messages?.header_message}</PageHeaderMessage>}

        <TabButtons
          tabs={tabs.tabs}
          currentTab={tabs.current}
          selectTab={tabs.setCurrent}
          tabStatuses={profile.profileStatus}
          />
      </PageHeader>
      
      {profile.isProfileLoaded && !profile.data.is_email_confirmed &&
        <PagePanelMessage actions={(
          <Button
            startIcon={<Refresh />}
            color="primary"
            endIcon={profile.resendConfirmationEmail.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
            onClick={() => profile.resendConfirmationEmail.run().then(() => enqueueSnackbar("Email sent", { variant: "success", autoHideDuration: 5000 }))}>
            Re-send confirmation email
          </Button>)}>

          <Typography variant="h4">{profile.pageConfig.config.messages?.confirm_email_title}</Typography>
          <Typography>{profile.pageConfig.config.messages?.confirm_email_message}</Typography>
        </PagePanelMessage>}


      {profile.isProfileLoaded && !profile.data.is_registration_complete &&
        <PagePanelMessage animateAppearence invert actions={<ErrorOutline fontSize="large" />}>
          <Typography variant="h4">{profile.pageConfig.config.messages?.registration_incomplete_title}</Typography>
          <Typography>{profile.pageConfig.config.messages?.registration_incomplete_message}</Typography>
        </PagePanelMessage>}

      {profile.isProfileLoaded && profile.pageConfig.state.team_profile_edit_allowed && profile.data.is_registration_complete &&
        <PagePanelMessage animateAppearence actions={<Check color="primary" fontSize="large" />}>
          <Typography variant="h4">{profile.pageConfig.config.messages?.registration_complete_title}</Typography>
          <Typography>{profile.pageConfig.config.messages?.registration_complete_message}</Typography>
        </PagePanelMessage>}

      <PagePanel fillPage={`${profile.isProfileLoaded}_${profile.data.is_registration_complete}`}>
        <TabPanel state={tabs.forTab("personal_info")}>
          <ArbitratorPersonalInfoForm
            data={profile}
            canEditProfile={profile.pageConfig.pageInfo.data?.contest_state?.arbitrator_profile_edit_allowed}
            />
        </TabPanel>


        <TabPanel state={tabs.forTab("experience")}>
          <ArbitratorExperienceForm
            data={profile}
            canEditProfile={profile.pageConfig.pageInfo.data?.contest_state?.arbitrator_profile_edit_allowed}
            />
        </TabPanel>


        <TabPanel state={tabs.forTab("conflicts")}>
          <ArbitratorConflictsForm
            data={profile}
            canEditProfile={profile.pageConfig.pageInfo.data?.contest_state?.arbitrator_profile_edit_allowed}
            />
        </TabPanel>


        <TabPanel state={tabs.forTab("availability")}>
          <ArbitratorAvailabilityForm
            data={profile}
            canEditProfile={profile.pageConfig.pageInfo.data?.contest_state?.arbitrator_profile_edit_allowed}
            />
        </TabPanel>

      </PagePanel>
    </>
  );
}
