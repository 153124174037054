import styled from '@emotion/styled';
import { Typography } from "@mui/material";
import { FormGrid } from '../../toolympus/components/primitives';
import { ThemeSettings } from '../../theme';

export const ProfileSectionTitle = styled(Typography)`
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
`;
ProfileSectionTitle.defaultProps = { variant: "h4" };

export const ProfileComments = styled(Typography)`
  white-space: pre-line;
`;

export const ListFormItem = styled(FormGrid)`
  padding-left: 20px;
  border-left: 2px solid ${ThemeSettings.colors.tableBorder};

  ${ProfileSectionTitle} + & {
    margin-top: 0.5rem;
  }
`;
