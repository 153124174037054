import React from 'react';
import styled from '@emotion/styled';
import { ArbitratorProfileData } from '../useArbitratorProfile';
import { ListFormItem, ProfileComments } from '../../Common/styles';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { Buttons, InputIconButton, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Button } from '@mui/material';
import { FieldType } from '../../../toolympus/hooks/useSchema';

const ConflictItem = styled(ListFormItem)`
`;
ConflictItem.defaultProps = { columns: "1fr", gap: "dense" };

interface Props {
  data: ArbitratorProfileData;
  canEditProfile?: boolean;
}

export const ArbitratorConflictsForm = (props: Props) => {
  const { conflicts, errors } = props.data;
  
  return (
    <>
      <ProfileComments>{props.data.pageConfig.config.messages?.conflict_comment}</ProfileComments>

      {(conflicts || []).items.map((item,idx) => (
        <ConflictItem key={item._id}>
          <FormControlsForFields
            data={item}
            onChange={(o,c) => conflicts.update(idx, c)}
            schema={{ comment: { type: FieldType.textlong }}}
            readOnly={!props.canEditProfile}
            errors={errors?.getInnerErrors(["conflict_comment", idx])}
            fields={[
              ["university", {
                label: "University",
                controlProps: {
                  required: true,
                  endAdornment: <InputIconButton icon={<DeleteOutlined />} action={props.canEditProfile ? () => conflicts.remove(idx) : undefined} />,
                }, }],
              ["comment", {
                label: "Comment",
                autoRows: true,
                controlProps: {
                  required: true,
                },
              }],
            ]}
            />          
        </ConflictItem>
      ))}

      {props.canEditProfile && 
        <Buttons>
          {props.data.conflicts.items.length > 0 && <OccupyFreeSpace />}
          <Button color="primary" onClick={() => props.data.conflicts.add()} startIcon={<Add />}>add</Button>
        </Buttons>}
    </>
  );
}
