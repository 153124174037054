import React from 'react';
import { FormGrid } from '../../toolympus/components/primitives';
import { TextField } from '@mui/material';
import { Spinner } from '../Common/Spinner';
import { RadioCheckbox } from '../Common/RadioCheckbox';
import { FrontPageHarness } from '../Auth/FrontPageHarness';
import { useArbitratorSignup } from './useArbitratorSignup';
import { FrontPageFormWrapperForm, FormGradientDecoration, FrontPageFormTitle, FrontPageFormHelpText, FrontPagePrimaryButton, FrontPageFormExtraLink } from '../Auth/FrontPages';


export const ArbitratorSignupPage = () => {
  const { item: data, save: submit, canSubmit, errors, update, isLoading, config, blockerMessage, pageInfo }
    = useArbitratorSignup();
  const { formConfig, paneConfig } = config;

  return (
    <FrontPageHarness menuLinks={paneConfig.links} wideContent>

      <FrontPageFormWrapperForm
        dim={isLoading}
        onSubmit={e => {
          e.preventDefault();
          if(canSubmit) {
            submit();
          }
        }}>

        <FormGradientDecoration top={-25} left={-37} />
        <FormGradientDecoration top={82} left={67} />

        {isLoading && <Spinner size={60} />}

        <FrontPageFormTitle><span>{formConfig.page_title || "Judge Registration"}</span></FrontPageFormTitle>

        {formConfig.is_blocked && !isLoading && <>
          <FrontPageFormHelpText>{formConfig.blocked_message}</FrontPageFormHelpText>
          {formConfig.blocked_button.map(([text,link]) => (
            <FrontPagePrimaryButton key={link} href={link}>
              {text}
            </FrontPagePrimaryButton>
          ))}
        </>}

        {(!formConfig.is_blocked || isLoading) && <>

          {formConfig.guide && <FrontPageFormHelpText>{formConfig.guide}</FrontPageFormHelpText>}

          <FormGrid gap="dense" columns="1fr 1fr" noMargin style={{ alignItems: "end" }} suppressContentColumnsOnSm>
            <TextField
              label="First name"
              value={data?.first_name || ""}
              onChange={e => update({ first_name: e.target.value })}
              error={errors.fieldHasErrors("first_name")}
              required
              />
            
            <TextField
              label="Last name"
              value={data?.last_name || ""}
              onChange={e => update({ last_name: e.target.value })}
              error={errors.fieldHasErrors("last_name")}
              required
              />
          </FormGrid>


          <FormGrid columns="1fr 1fr 1fr" forceEvenColumns gap="dense">
            <TextField
              label="Email"
              type="email"
              required
              value={data?.email || ""}
              onChange={e => update({ email: e.target.value })}
              error={errors.fieldHasErrors("email")}
              />

            <TextField
              label="Password"
              type="password"
              required
              value={data?.password || ""}
              onChange={e => update({ password: e.target.value })}
              error={errors.fieldHasErrors("password")}
              />

            <TextField
              label="Repeat password"
              type="password"
              helperText="at least 6 characters"
              required
              value={data?.passwordRepeat || ""}
              onChange={e => update({ passwordRepeat: e.target.value })}
              error={errors.fieldHasErrors("password")}
              />

          </FormGrid>

          <FormGrid columns="1fr" noMargin>
            <RadioCheckbox
              label={pageInfo.data?.messages?.arbitrators?.registration?.pdc}
              value={data?.pd_consent}
              update={v => update({ pd_consent: v })}
              trueOnly
              error={errors.fieldHasErrors("pd_consent")}
              />


            <FrontPagePrimaryButton type="submit" disabled={!!blockerMessage}>
              {blockerMessage ? blockerMessage : "sign up"}
            </FrontPagePrimaryButton>

            {formConfig.links.map((l,idx) => <FrontPageFormExtraLink key={idx}>{l}</FrontPageFormExtraLink>)}
          </FormGrid>
        </>}
      </FrontPageFormWrapperForm>
      
    </FrontPageHarness>
  );
}
